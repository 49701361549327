import React from 'react';

export default class SubHeader extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            query: this.props.query || ''
        };
        this.updateState = this.updateState.bind(this);
        this.searchIdeas = this.searchIdeas.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    updateState(event){
        let query = event.target.value;
        this.searchIdeas(query);
        return this.setState({query: query})
    }

    searchIdeas(query){
        this.props.searchIdeas(query);
    }

    handleKeyPress() {
        this.searchIdeas(this.state.query);
    }

    render() {
        let content;
        if(this.props.search) {
                content =
                (<div className="search-filter search-filter-full">
                    <div className="search-bar">
                        <div className="search">
                            <input
                                className="input"
                                type="text"
                                value={this.state.query}
                                onChange={this.updateState}
                                onBlur={this.handleKeyPress}
                                onKeyPress={this.handleKeyPress}
                                placeholder="Search Ideas"
                            />
                        </div>
                    </div>
                </div>);
        } else {
            content = <h2>{this.props.title}</h2>;
        }

        return (
            <div className="sub-header">
                <div className="sidebar-left"></div>
                <div className="content-main">
                    {content}
                </div>
                <div className="sidebar-content">
                </div>
            </div>
        );
    };
}