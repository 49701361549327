import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import thunk from "redux-thunk";
// import createSocketIoMiddleware from "redux-socket.io";
// import io from "socket.io-client";
import { analyticsMiddleware } from "./analyticsMiddleware";
// let socket = io("/");
// let socketIoMiddleware = createSocketIoMiddleware(socket, "socket/");

export default function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk),
      // applyMiddleware(socketIoMiddleware),
      applyMiddleware(analyticsMiddleware),
      window.devToolsExtension && process.env.NODE_ENV !== "production"
        ? window.devToolsExtension()
        : (f) => f
    )
  );
}
