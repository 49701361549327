import React from 'react';
import { cutString } from '../../common/utils';
import PrivateImage from '../../common/privateImage';


export default class Card extends React.Component {

    resumeInfo = (resume) => (
        <div className="idea-card">
            <PrivateImage
                fileName={resume.images.length ? resume.images[0].fileLocation : ''}
                id={resume.id}
                section='resumeSmall'
                className='idea-card-image'
            />
            <div className="idea-card-info">
                <h5 className="idea-card-title">{cutString(`${resume.firstName} ${resume.lastName}`, 20)}</h5>
                <p className="idea-card-details">
                    <span>{resume.age}</span>
                    <span>{resume.height}</span>
                    <span>{resume.maritalStatus}</span>
                </p>
            </div>
        </div>
    );

    emptyInfo = (name) => (
        <div className="idea-card">
            <img src='/images/small-placeholder.png' alt="" className="idea-card-image"/>
            <div className="idea-card-info">
                <h5 className="idea-card-title">{cutString(name, 20)}</h5>
            </div>
        </div>
    );

    render() {
        let item = this.props.item;
        let hisClass = "ideas-card-row";
        let herClass = "ideas-card-row";
        if(item.ideaStateId < 4){
            if(item.hisStatus && item.hisStatus.color){
                hisClass += ` ${item.hisStatus.color}`
            }
            if(item.herStatus && item.herStatus.color){
                herClass += ` ${item.herStatus.color}`
            }
        }
        return(
            <div style={this.props.style} className="idea-items-group" id={this.props.style ? item.id : null}>
                <div className={hisClass}>
                    {item.hisResume && item.hisResume.firstName ? this.resumeInfo(item.hisResume)
                        : this.emptyInfo(item.hisName)}
                </div>
                <div className={herClass}>
                    {item.herResume && item.herResume.firstName ? this.resumeInfo(item.herResume)
                        : this.emptyInfo(item.herName)}
                </div>
            </div>
        );
    }
}
