import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { findDOMNode } from 'react-dom';
import ReactTooltip from 'react-tooltip'
import Converter from '../../common/propsConverter';
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import resizableTextArea from '../../common/resizableTextArea';
import Form from 'react-formal';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/lib/less/react-widgets.less';
import SelectList from 'react-widgets/lib/SelectList';
import MultiSelect from 'react-widgets/lib/Multiselect';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Popup from '../../common/Popup';
import { apiAuthRequest } from '../../../actions/helpers';
import VisibilitySensor from 'react-visibility-sensor';
import PrivateImage from '../../common/privateImage';
import fullResumeSchema from './fullResumeSchema';
import basicResumeSchema from './basicResumeSchema';
import * as resumeTips from './resumeTips';
import { Helmet } from "react-helmet";

moment.locale('en');
momentLocalizer();


export default class ResumeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: '',
            visibleArea: 1
        };
        this.getTypeValue = this.getTypeValue.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.areaVisibilityChanged = this.areaVisibilityChanged.bind(this);
        this.sectionFocused = this.sectionFocused.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.visibleAreas = [1];
    }

    areaVisibilityChanged(isVisible, visibleArea){
        if(isVisible){
            if(!this.visibleAreas.includes(visibleArea)){
                this.visibleAreas.push(visibleArea);
            }
        } else {
            this.visibleAreas = this.visibleAreas.filter(i=> i !== visibleArea);
        }
        let greatestVisibleArea = Math.max.apply(Math, this.visibleAreas);
        if(greatestVisibleArea !== this.state.visibleArea){
            this.setState({ visibleArea: greatestVisibleArea })
        }
    }

    scrollToSection(area){
        if(this.refs[area]){
            this.refs[area].scrollIntoView({ block: 'start' });
            if(area === "general") window.scrollBy(0, -150);
            else if(area !== "photos") window.scrollBy(0, -100);
        }
    }

    onDrop(acceptedFiles) {
        if(!acceptedFiles.length){
            return;
        }
        let image = acceptedFiles[0];
        if (image.size > 2500000) {
            return this.setState({imageTooLarge: true})
        }

        let inside = this;
        let fd = new FormData();
        fd.append('image', image, image.name);
        apiAuthRequest('post', '/uploads/uploadResumeImage', fd).then((imageUpload)=> {
            if (imageUpload.success && imageUpload.data.fileLocation) {
                this.props.addImage(imageUpload.data);
                inside.setState({imageRejected: false, imageTooLarge: false});
            } else {
                inside.setState({imageRejected: true});
            }
        }).catch((e)=>{
            inside.setState({imageRejected: true});
            console.error(e);
        });
    }

    handleClickOutside(event){
        if(this.state.editMode){
            let area = findDOMNode(this.refs[this.state.editMode]);
            if (!area.contains(event.target)) {
                return this.setState({editMode: ''})
            }
        }
    }

    sectionFocused(sectionName){
        if(this.state.editMode !== sectionName ){
            return this.setState({editMode: sectionName})
        }
    }

    getTypeValue(typeId){
        let myType = this.props.resume.types.find(t=>t.typeId===typeId);
        return myType ? myType.power : 0;
    }

    deleteNestedObject(section, index){
        let component = this;
        this.props.deleteNestedObject(section, index).then(()=>{
            component.refs.form.validate(section)
        });
    }

    render() {
        let { resume, constants, isMine } = this.props;
        if(this.props.isLoading && (this.props.resume && !this.props.resume.id)){
            return (
                <div className="loader-saving">
                    Loading Resume<span>.</span><span>.</span><span>.</span>
                </div>
            )
        }
        return (
            <div>
                <Helmet>
                    <title>Create Resume | ZivugTech</title>
                </Helmet>
                <Form.Context className={ this.state.editMode ? "createResumePage editModePage" : "createResumePage"} onClick={this.handleClickOutside}>
                    <div className="sub-header sub-header-resume">
                        <div className="mui-container">
                            <div className="mui-col-md-12">
                                <div className="resume-sidebar">
                                    <div className="steps-container">
                                        <div className="steps">
                                            <div className="content">
                                                <ul className="clearfix">
                                                    <li className={this.state.visibleArea === 1 ? 'step-active' : ''} onClick={this.scrollToSection.bind(this, "general")}><b>1</b><span>General</span></li>
                                                    <li className={this.state.visibleArea === 2 ? 'step-active' : ''} onClick={this.scrollToSection.bind(this, "experience")}><b>2</b><span>Experience</span></li>
                                                    <li className={this.state.visibleArea === 3 ? 'step-active' : ''} onClick={this.scrollToSection.bind(this, "description")}><b>3</b><span>Description</span></li>
                                                    <li className={this.state.visibleArea === 4 ? 'step-active' : ''} onClick={this.scrollToSection.bind(this, "contact")}><b>4</b><span>Contact</span></li>
                                                    <li className={this.state.visibleArea === 5 ? 'step-active' : ''} onClick={this.scrollToSection.bind(this, "photos")}><b>5</b><span>Photos</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                {this.props.alert}
                            </div>
                        </div>
                    </div>
                    <div className="mui-container">
                        <div className="content header-padding header-padding-subheader">
                            <div className="create-resume-page" >
                                <Form
                                    id="form"
                                    ref="form"
                                    schema={this.props.isMine ? fullResumeSchema : basicResumeSchema}
                                    value={resume}
                                    onChange={this.props.handleChange}
                                    delay={10}
                                    onSubmit={this.props.saveResume}
                                    onError={this.props.handleError}
                                    errors={this.props.errors}
                                >
                                    <div className="panel panel-withPadding clearfix">
                                        <div className="resume create-resume">
                                            <div className="resume-content">
                                                <div>
                                                    <VisibilitySensor onChange={isVisible=>this.areaVisibilityChanged(isVisible, 1)} intervalCheck={false} scrollCheck={true} scrollDelay={10} partialVisibility={true}>
                                                        <div ref="general">
                                                            <div className="resume-header-info">
                                                                <div className="resume-title-header">
                                                                    <h2>
                                                                        General Info *
                                                                        <Form.Message for={['firstName', 'lastName', 'birthday', 'height', 'maritalStatus']}>
                                                                            {messages => <span><FontAwesome name='exclamation-circle' /> Please fix Errors</span>}
                                                                        </Form.Message>
                                                                    </h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <div className="mui-row">
                                                                        <div  className="form-inline-half">
                                                                            <legend>First Name *<Form.Message className="has-error" for="firstName"/></legend>
                                                                            <Form.Field
                                                                                name='firstName'
                                                                                placeholder='First name'
                                                                                id="firstNameField"
                                                                            />
                                                                        </div>
                                                                        <div className="form-inline-half">
                                                                            <legend>Last Name *<Form.Message className="has-error" for="lastName"/></legend>
                                                                            <Form.Field
                                                                                name='lastName'
                                                                                placeholder='Last name'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mui-row">
                                                                        <div>
                                                                            <legend>Full Hebrew Name <Form.Message className="has-error" for="fullName"/> <span><FontAwesome name='info-circle' data-multiline={true} data-tip={resumeTips.fullName} /></span></legend>
                                                                            <Form.Field
                                                                                type="text"
                                                                                name='fullName'
                                                                                className="small-name"
                                                                                placeholder='Full Hebrew Name'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    ref="personalDetails"
                                                                    className={ this.state.editMode === 'personalDetails' ? "resume-header-details editMode" : "resume-header-details"}
                                                                    onFocus={this.sectionFocused.bind(this, 'personalDetails')}
                                                                    onClick={this.sectionFocused.bind(this, 'personalDetails')}
                                                                >
                                                                    <div className="resume-fields">
                                                                        <div className="mui-row">
                                                                            <div className="form-inline-half mui-col-md-6">
                                                                                <legend>Birthday {isMine && "*"}<Form.Message className="has-error" for="birthday"/> <span><FontAwesome name="info-circle" data-multiline={true} data-tip={resumeTips.dateOfBirth} /></span></legend>
                                                                                <Form.Field
                                                                                    type={DateTimePicker}
                                                                                    name='birthday'
                                                                                    mapFromValue={{
                                                                                        'birthday': date => date,
                                                                                        'age': date =>
                                                                                        date ? (new Date()).getFullYear() - date.getFullYear() : null
                                                                                    }}
                                                                                    time={false}
                                                                                    format={"MM/DD/YYYY"}
                                                                                    placeholder="03/01/1987"
                                                                                />
                                                                            </div>
                                                                            <div className="form-inline-half mui-col-md-6">
                                                                                <legend>Height {isMine && "*"}<Form.Message className="has-error" for="height"/></legend>
                                                                                <Form.Field name='height' className="" type="select">
                                                                                    <option value=''>Please choose a height</option>
                                                                                    {_.range(56, 85).map((x, i) => <option key={`height${i}`} value={x}>{Converter.heightToString(x)}</option>)}
                                                                                </Form.Field>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mui-row">
                                                                            <div className="form-inline-half mui-col-md-6">
                                                                                <legend>Marital Status {isMine && "*"}<Form.Message className="has-error" for="maritalStatus"/></legend>
                                                                                <Form.Field
                                                                                    type={SelectList}
                                                                                    data={constants.maritalStatus}
                                                                                    mapFromValue={ item => item.id }
                                                                                    valueField='id'
                                                                                    textField='alternateText'
                                                                                    name='maritalStatus'
                                                                                    className="form-input-margin-bottom form-inline inline-radio"/>
                                                                            </div>
                                                                            {resume.maritalStatus > 1 ?
                                                                                <div className="form-inline-half mui-col-md-6">
                                                                                    <legend>Number of children *<Form.Message className="has-error" for="numberChildren"/></legend>
                                                                                    <Form.Field name='numberChildren' className="" type="select">
                                                                                        <option disabled value=''> </option>
                                                                                        {_.range(0, 10).map((x, i) => <option key={`noc${i}`} value={x.toString()}>{x}</option>)}
                                                                                        <option value={10}>10+</option>
                                                                                    </Form.Field>
                                                                                </div>
                                                                                : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                ref="personalInfo"
                                                                className={ this.state.editMode === 'personalInfo' ? "resume-section editMode" : "resume-section"}
                                                                onFocus={this.sectionFocused.bind(this, 'personalInfo')}
                                                                onClick={this.sectionFocused.bind(this, 'personalInfo')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>
                                                                        Personal Info *
                                                                        <Form.Message for={['gender', 'ethnicity', 'languages', 'religious', 'address', 'city', 'state', 'zip', 'relocate']}>
                                                                            {messages => <span><FontAwesome name="exclamation-circle" /> Please fix Errors</span>}
                                                                        </Form.Message>
                                                                    </h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <div className="mui-row">
                                                                        <div className="mui-col-md-12">
                                                                            <legend>Gender *<Form.Message className="has-error" for="gender"/></legend>
                                                                            <Form.Field
                                                                                type={SelectList}
                                                                                data={constants ? constants.gender: []}
                                                                                mapFromValue={ gender => gender.id }
                                                                                valueField='id'
                                                                                textField='text'
                                                                                name='gender'
                                                                                className="form-input-margin-bottom form-inline inline-radio radioBtn"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mui-row">
                                                                        <div className="mui-col-md-12">
                                                                            <legend>Ethnicity {isMine && "*"}<Form.Message className="has-error" for="ethnicity"/></legend>
                                                                            <Form.Field
                                                                                type={SelectList}
                                                                                data={constants.ethnicity}
                                                                                mapFromValue={ item => item.id }
                                                                                valueField='id'
                                                                                textField='text'
                                                                                name='ethnicity'
                                                                                className="form-input-margin-bottom form-inline inline-radio radioBtn"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mui-row">
                                                                        <div className="mui-col-md-12">
                                                                            <legend>Languages <Form.Message className="has-error" for="languages"/></legend>
                                                                            <Form.Field
                                                                                type={MultiSelect}
                                                                                className="react-tags"
                                                                                data={constants.languages}
                                                                                valueField='id'
                                                                                textField='text'
                                                                                name="languages"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mui-row">
                                                                        <div className="form-inline-half">
                                                                            <legend>Religious Background {isMine && "*"}<Form.Message className="has-error" for="religious"/></legend>
                                                                            <Form.Field
                                                                                type={SelectList}
                                                                                data={constants.religious}
                                                                                mapFromValue={ item => item.id }
                                                                                valueField='id'
                                                                                textField='text'
                                                                                name='religious'
                                                                                className="form-input-margin-bottom form-inline inline-radio"/>
                                                                        </div>
                                                                        {resume.gender === 1 ?
                                                                            <div className="form-inline-half">
                                                                                <legend>Are you a Cohen? <Form.Message className="has-error" for="isCohen"/></legend>
                                                                                <Form.Field
                                                                                    type="boolean"
                                                                                    className="mui-radio inline-radio"
                                                                                    name="isCohen"/>
                                                                            </div>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                    <div className="mui-row">
                                                                        <div className="mui-col-md-12">
                                                                            <legend>Street Address {isMine && "*"}<Form.Message className="has-error" for="address"/></legend>
                                                                            <Form.Field
                                                                                name='address'
                                                                                placeholder='Street Address'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mui-row">
                                                                        <div className="form-inline-third">
                                                                            <legend>City {isMine && "*"}<Form.Message className="has-error" for="city"/></legend>
                                                                            <Form.Field
                                                                                name='city'
                                                                                placeholder='City'
                                                                            />
                                                                        </div>
                                                                        <div className="form-inline-third">
                                                                            <legend>State {isMine && "*"}<Form.Message className="has-error" for="state"/></legend>
                                                                            <Form.Field
                                                                                name='state'
                                                                                placeholder='State'
                                                                            />
                                                                        </div>
                                                                        <div className="form-inline-third">
                                                                            <legend>Zip {isMine && "*"}<Form.Message className="has-error" for="zip"/></legend>
                                                                            <Form.Field
                                                                                name='zip'
                                                                                placeholder='Zip'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mui-row">
                                                                        <div className="form-inline-half mui-col-md-6">
                                                                            <legend>Hometown <Form.Message className="has-error" for="hometown"/></legend>
                                                                            <Form.Field
                                                                                type="text"
                                                                                name='hometown'
                                                                                placeholder='Where did you grow up?'
                                                                            />
                                                                        </div>

                                                                        <div className="form-inline-half mui-col-md-6">
                                                                            <legend>Are you willing to relocate {isMine && "*"}<Form.Message className="has-error" for="relocate"/></legend>
                                                                            <Form.Field
                                                                                type={SelectList}
                                                                                data={constants.relocate}
                                                                                mapFromValue={ item => item.id }
                                                                                valueField='id'
                                                                                textField='text'
                                                                                name='relocate'
                                                                                className="form-inline inline-radio radioBtn"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </VisibilitySensor>
                                                    <VisibilitySensor onChange={isVisible=>this.areaVisibilityChanged(isVisible, 2)} intervalCheck={false} scrollCheck={true} scrollDelay={10} partialVisibility={true}>
                                                        <div ref="experience">
                                                            <div
                                                                ref="family"
                                                                className={ this.state.editMode === 'family' ? "resume-section editMode" : "resume-section"}
                                                                onFocus={this.sectionFocused.bind(this, 'family')}
                                                                onClick={this.sectionFocused.bind(this, 'family')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>
                                                                        Family
                                                                    </h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <div className="resume-fields-additional mui-row">
                                                                        <div className="mui-col-md-12">
                                                                            <legend>Family Information <span><FontAwesome name="info-circle" data-multiline={true} data-tip={resumeTips.family} /></span> </legend>
                                                                            <Form.Field
                                                                                type={resizableTextArea}
                                                                                name='familyInfo.info'
                                                                                placeholder="Father's Name and Occupation, Mother's Name and Occupation, Siblings' Names and Ages (School/Occupation Names and Occupations) etc..."
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                ref="shuls"
                                                                className={ this.state.editMode === 'shuls' ? "resume-section editMode" : "resume-section"}
                                                                onFocus={this.sectionFocused.bind(this, 'shuls')}
                                                                onClick={this.sectionFocused.bind(this, 'shuls')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>
                                                                        Shul / Synagogue
                                                                    </h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <ul>
                                                                        {resume.shuls.length ? resume.shuls.map((shul, i) => {
                                                                            return (
                                                                                <li key={`shul${i}`} className={resume.shuls.length > 1 ? 'additional-item' : '' }>
                                                                                    {resume.shuls.length > 1 ?
                                                                                        <FontAwesome name='times'
                                                                                                     className="resume-fields-close"
                                                                                                     onClick={()=> this.deleteNestedObject('shuls', i)}/>
                                                                                        : null }
                                                                                    <div className="mui-row">
                                                                                        <div className="form-inline-third">
                                                                                            <legend>Shul / Synagogue Name <Form.Message className="has-error" for={`shuls[${i}].name`}/></legend>
                                                                                            <Form.Field
                                                                                                name={`shuls[${i}].name`}
                                                                                                placeholder="Shul / Synagogue Name"
                                                                                                type="text"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-inline-third">
                                                                                            <legend>Rabbi Name <Form.Message className="has-error" for={`shuls[${i}].rabbi`}/></legend>
                                                                                            <Form.Field
                                                                                                name={`shuls[${i}].rabbi`}
                                                                                                placeholder="Rabbi's Name"
                                                                                                type="text"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-inline-third">
                                                                                            <legend>Rabbi Phone <Form.Message className="has-error" for={`shuls[${i}].rabbiPhone`}/></legend>
                                                                                            <Form.Field
                                                                                                name={`shuls[${i}].rabbiPhone`}
                                                                                                placeholder="Rabbi's number"
                                                                                                type="text"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mui-row">
                                                                                        <div className="mui-col-md-12">
                                                                                            <legend>Additional Information</legend>
                                                                                            <Form.Field
                                                                                                name={`shuls[${i}].details`}
                                                                                                placeholder="Additional Information"
                                                                                                type="text"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }) : null}
                                                                    </ul>
                                                                    <div className="mui-row clearfix" onClick={this.props.addNestedObject.bind(this, 'shuls')}>
                                                                        <div className="resume-addItem">
                                                                            <FontAwesome name='plus'/>
                                                                            <span> Add Shul / Synagogue</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                ref="education"
                                                                className={ this.state.editMode === 'education' ? "resume-section editMode" : "resume-section"}
                                                                onFocus={this.sectionFocused.bind(this, 'education')}
                                                                onClick={this.sectionFocused.bind(this, 'education')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>
                                                                        Education Info {isMine && "*"} &nbsp;
                                                                        <span><FontAwesome name='info-circle' data-multiline={true} data-tip={resumeTips.education} /></span>
                                                                        <Form.Message className="has-error" for='education'/>
                                                                        <Form.Trigger group='education'>
                                                                            {({ messages }) => _.isEmpty(messages) ? null
                                                                                : <span className="validation-error" ><FontAwesome name='exclamation-circle'/> Please fix Errors</span> }
                                                                        </Form.Trigger>
                                                                    </h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <ul>
                                                                        {resume.education.length ? resume.education.map((ed, i) => {
                                                                            return (
                                                                                <li key={`education${i}`} className={resume.education.length > 1 ? 'additional-item' : '' }>
                                                                                    {resume.education.length > 1 ?
                                                                                        <FontAwesome name='times'
                                                                                                     className="resume-fields-close"
                                                                                                     onClick={() => this.deleteNestedObject('education', i)}/>
                                                                                        : null }
                                                                                    <div className="mui-row">
                                                                                        <div className="form-inline-half mui-col-md-6">
                                                                                            <legend>Place of Education <Form.Message className="has-error" for={[`education[${i}].name`]}/></legend>
                                                                                            <Form.Field
                                                                                                group="education"
                                                                                                name={`education[${i}].name`}
                                                                                                alsoValidates={[`education`]}
                                                                                                placeholder="Name of School or Place of Education"
                                                                                                type="text"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-inline-half mui-col-md-6">
                                                                                            <legend>Details</legend>
                                                                                            <Form.Field
                                                                                                group="education"
                                                                                                name={`education[${i}].details`}
                                                                                                alsoValidates={[`education`, `education[${i}].name`]}
                                                                                                placeholder="Years and/or any additional details"
                                                                                                type="text"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }) : null}
                                                                    </ul>
                                                                    <div className="mui-row clearfix" onClick={this.props.addNestedObject.bind(this, 'education')}>
                                                                        <div className="resume-addItem">
                                                                            <FontAwesome name='plus'/>
                                                                            <span> Add Education</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                ref="occupation"
                                                                className={ this.state.editMode === 'occupation' ? "resume-section editMode" : "resume-section"}
                                                                onClick={this.sectionFocused.bind(this, 'occupation')}
                                                                onFocus={this.sectionFocused.bind(this, 'occupation')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>
                                                                        Occupation {isMine && "*"}
                                                                        <Form.Message for={['occupation.name']}>
                                                                            {messages => <span><FontAwesome name="exclamation-circle" /> Please fix Errors</span>}
                                                                        </Form.Message>
                                                                    </h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <ul>
                                                                        <li key='occupation'>
                                                                            <div className="mui-row">
                                                                                <div className="mui-col-md-12">
                                                                                    <legend>Position {isMine && "*"}<Form.Message className="has-error" for='occupation.name'/></legend>
                                                                                    <Form.Field
                                                                                        name={`occupation.name`}
                                                                                        placeholder="Title / Position at Company"
                                                                                        type="text"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="mui-row">
                                                                                <div className="mui-col-md-12">
                                                                                    <legend>Details</legend>
                                                                                    <Form.Field
                                                                                        name={`occupation.details`}
                                                                                        placeholder="Years and/or any additional details"
                                                                                        type="text"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div
                                                                ref="camps"
                                                                className={ this.state.editMode === 'camps' ? "resume-section editMode" : "resume-section"}
                                                                onFocus={this.sectionFocused.bind(this, 'camps')}
                                                                onClick={this.sectionFocused.bind(this, 'camps')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>
                                                                        Camps
                                                                        <Form.Trigger group='camps'>
                                                                            {({ messages }) => _.isEmpty(messages) ? null
                                                                                : <span className="validation-error" ><FontAwesome name='exclamation-circle'/> Please fix Errors</span> }
                                                                        </Form.Trigger>
                                                                    </h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <ul>
                                                                        {resume.camps.length ? resume.camps.map((camp, i) => {
                                                                            return (
                                                                                <li key={`camp${i}`} className={resume.camps.length > 1 ? 'additional-item' : '' }>
                                                                                    {resume.camps.length > 1 ?
                                                                                        <FontAwesome name='times'
                                                                                                     className="resume-fields-close"
                                                                                                     onClick={() => this.deleteNestedObject('camps', i)}/>
                                                                                        : null }
                                                                                    <div className="mui-row">
                                                                                        <div className="form-inline-half mui-col-md-6">
                                                                                            <legend>Camp Name <Form.Message className="has-error" for={[`camps[${i}].name`]}/></legend>
                                                                                            <Form.Field
                                                                                                group="camps"
                                                                                                alsoValidates={['camps']}
                                                                                                name={`camps[${i}].name`}
                                                                                                placeholder="Camp Name"
                                                                                                type="text"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-inline-half mui-col-md-6">
                                                                                            <legend>Details </legend>
                                                                                            <Form.Field
                                                                                                group="camps"
                                                                                                alsoValidates={['camps']}
                                                                                                name={`camps[${i}].details`}
                                                                                                placeholder="Details"
                                                                                                type="text"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }) : null}
                                                                    </ul>
                                                                    <div className="mui-row clearfix">
                                                                        <div className="resume-addItem" onClick={() => this.props.addNestedObject('camps')}>
                                                                            <FontAwesome name='plus'/>
                                                                            <span> Add Camp</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                ref="volunteer"
                                                                className={ this.state.editMode === 'volunteer' ? "resume-section editMode" : "resume-section"}
                                                                onFocus={() => this.sectionFocused('volunteer')}
                                                                onClick={() => this.sectionFocused('volunteer')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>Other / Organization / Volunteer Work
                                                                        <Form.Trigger group='volunteer'>
                                                                            {({ messages }) => _.isEmpty(messages) ? null
                                                                                : <span className="validation-error" ><FontAwesome name='exclamation-circle'/> Please fix Errors</span> }
                                                                        </Form.Trigger>
                                                                        <Form.Message for={['volunteer']}>
                                                                            {messages => 'Please fix errors'}
                                                                        </Form.Message>
                                                                    </h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <ul>
                                                                        {resume.volunteer.length ? resume.volunteer.map((volunteer, i) => {
                                                                            return (
                                                                                <li key={`volunteer${i}`} className={resume.volunteer.length > 1 ? 'additional-item' : '' }>
                                                                                    {resume.volunteer.length > 1 ?
                                                                                        <FontAwesome name='times'
                                                                                                     className="resume-fields-close"
                                                                                                     onClick={() => this.deleteNestedObject('volunteer', i)}/>
                                                                                        : null }

                                                                                    <Form.Message for={[`volunteer[${i}]`]}/>
                                                                                    <div className="mui-row">
                                                                                        <div className="form-inline-half mui-col-md-6">
                                                                                            <legend>Organization Name <Form.Message className="has-error" for={[`volunteer[${i}].name`]}/></legend>
                                                                                            <Form.Field
                                                                                                alsoValidates={['volunteer']}
                                                                                                group="volunteer"
                                                                                                name={`volunteer[${i}].name`}
                                                                                                placeholder="Organization Name"
                                                                                                type="text"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-inline-half mui-col-md-6">
                                                                                            <legend>Details</legend>
                                                                                            <Form.Field
                                                                                                alsoValidates={['volunteer']}
                                                                                                group="volunteer"
                                                                                                type="text"
                                                                                                name={`volunteer[${i}].details`}
                                                                                                placeholder="Details"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }) : null}
                                                                    </ul>
                                                                    <div className="mui-row clearfix">
                                                                        <div className="resume-addItem"  onClick={() => this.props.addNestedObject('volunteer')}>
                                                                            <FontAwesome name='plus'/>
                                                                            <span> Add</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </VisibilitySensor>
                                                    <VisibilitySensor onChange={isVisible=>this.areaVisibilityChanged(isVisible, 3)} intervalCheck={false} scrollCheck={true} scrollDelay={10} partialVisibility={true}>
                                                        <div ref="description">
                                                            <div ref="types"
                                                                 className={ this.state.editMode === 'types' ? "resume-section editMode" : "resume-section"}
                                                                 onFocus={() => this.sectionFocused('types')}
                                                                 onClick={() => this.sectionFocused('types')}>
                                                                <div className="resume-title-header">
                                                                    <h2>Types {isMine && "*"}
                                                                        <span><FontAwesome name='info-circle' data-multiline={true} data-tip={resumeTips.types} /></span>
                                                                        <Form.Message for={'types'}/>
                                                                    </h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <div className="mui-row">
                                                                        <div className="mui-col-md-12">
                                                                            <legend>Types</legend>
                                                                            <table>
                                                                                <tbody>
                                                                                {constants.types && constants.types.map(type => {
                                                                                    return (
                                                                                        <tr key={`type${type.id}`}>
                                                                                            <td className="resume-table-title">{type.text}:</td>
                                                                                            <td className="type-amount">
                                                                                                {[...Array(10)].map((x, i) =>
                                                                                                    <span
                                                                                                        key={`type${type.id}-${i}`}
                                                                                                        className={i < this.getTypeValue(type.id) ? "number number-filled" : 'number'}
                                                                                                        onClick={() => this.props.changeTypePower(type.id, i)}>
                                                                                                        <FontAwesome
                                                                                                            name='circle'
                                                                                                            className={i < this.getTypeValue(type.id) ? "type-amount-filled" : ''}/>
                                                                                                    </span>
                                                                                                )}
                                                                                                {this.state.editMode === 'types' && this.getTypeValue(type.id) ?
                                                                                                    <span onClick={() => this.props.changeTypePower(type.id, -1)} className="type-x">
                                                                                                        <FontAwesome name="times" />
                                                                                                    </span>
                                                                                                    : null
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                ref="personalDescription"
                                                                className={ this.state.editMode === 'personalDescription' ? "resume-section editMode" : "resume-section"}
                                                                onFocus={() => this.sectionFocused('personalDescription')}
                                                                onClick={() => this.sectionFocused('personalDescription')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>Personal Description</h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <div className="mui-row">
                                                                        <div className="mui-col-md-12">
                                                                            <legend>Personal Description <span><FontAwesome name='info-circle' data-multiline={true} data-tip={resumeTips.description} /></span> </legend>
                                                                            <Form.Field
                                                                                name='personalDescription'
                                                                                placeholder="Im a friendly, easygoing and warm person. I try to be a good listener and to be considerate of other people. Some of my favorite activities are playing sports, reading, watching interesting movies and spending time outdoors. I appreciate my quiet time at home too. Kindness is a very important quality to me and I believe in being flexible and having an open mind."
                                                                                type={resizableTextArea}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                ref="lookingFor"
                                                                className={ this.state.editMode === 'lookingFor' ? "resume-section editMode" : "resume-section"}
                                                                onFocus={() => this.sectionFocused('lookingFor')}
                                                                onClick={() => this.sectionFocused('lookingFor')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>Looking For</h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <div className="mui-row">
                                                                        <div className="mui-col-md-12">
                                                                            <legend>Looking For <span><FontAwesome name='info-circle' data-multiline={true} data-tip={resumeTips.lookingFor} /></span> </legend>
                                                                            <Form.Field
                                                                                name='lookingFor'
                                                                                placeholder="I'm looking for a life partner and see a healthy relationship as being best friends who look up to each other, respect each other, grow together, and make each other happy."
                                                                                type={resizableTextArea}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </VisibilitySensor>
                                                </div>

                                                <div>
                                                    <VisibilitySensor onChange={isVisible=>this.areaVisibilityChanged(isVisible, 4)} intervalCheck={false} scrollCheck={true} scrollDelay={10} partialVisibility={true}>
                                                        <div ref="contact">
                                                            <div
                                                                ref="personalContactInfo"
                                                                className={ this.state.editMode === 'personalContactInfo' ? "resume-section editMode" : "resume-section"}
                                                                onFocus={this.sectionFocused.bind(this, 'personalContactInfo')}
                                                                onClick={this.sectionFocused.bind(this, 'personalContactInfo')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>
                                                                        {resume.firstName ? `${resume.firstName}'s ` : ''} Personal Contact Info {isMine && "*"}
                                                                        <span className="contact-checkbox">
                                                                            <label className="label-title">
                                                                                <span>Display this info on resume</span>
                                                                                <Form.Field
                                                                                    alsoValidates={['contactName', 'contactRelationship', 'contactPhone', 'contactEmail']}
                                                                                    type="boolean"
                                                                                    name="displayPersonalContactInfo"/>
                                                                            </label>
                                                                        </span>
                                                                        <Form.Message for={['mobilePhone', 'homePhone', 'email', 'shidduchContactInfo']}>
                                                                            {messages => <span><FontAwesome name='exclamation-circle' /> Please fix Errors</span>}
                                                                        </Form.Message>
                                                                    </h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <div className="mui-row">
                                                                        <div className="form-inline-third mui-col-md-3">
                                                                            <legend>Cell phone {isMine && "*"}<Form.Message className="has-error" for="mobilePhone"/></legend>
                                                                            <Form.Field
                                                                                placeholder="Cell phone"
                                                                                name="mobilePhone"
                                                                                type="text"
                                                                            />
                                                                        </div>
                                                                        <div className="form-inline-third mui-col-md-3">
                                                                            <legend>Home phone <Form.Message className="has-error" for="homePhone"/></legend>
                                                                            <Form.Field placeholder="Home phone" name="homePhone" type="text" />
                                                                        </div>
                                                                        <div className="form-inline-third mui-col-md-3">
                                                                            <legend>Email {isMine && "*"}<Form.Message className="has-error" for="email"/></legend>
                                                                            <Form.Field placeholder="Email" name="email" type="text" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mui-row">
                                                                        <div className="mui-col-md-12">
                                                                            <legend>Advice for contacting me <Form.Message className="has-error" for="shidduchContactInfo"/></legend>
                                                                            <Form.Field placeholder="Advice for contacting me" name="shidduchContactInfo" type={resizableTextArea} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                ref="contact"
                                                                className={ this.state.editMode === 'contact' ? "resume-section editMode" : "resume-section"}
                                                                onFocus={this.sectionFocused.bind(this, 'contact')}
                                                                onClick={this.sectionFocused.bind(this, 'contact')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>
                                                                        Resume Contact Info
                                                                        <Form.Message for={['contactName', 'contactRelationship', 'contactPhone', 'contactEmail']}>
                                                                            {messages => <span><FontAwesome name='exclamation-circle' /> Please fix Errors</span>}
                                                                        </Form.Message>
                                                                    </h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <div className="mui-row">
                                                                        <div className="form-inline-half mui-col-md-6">
                                                                            <legend>Contact Name <Form.Message className="has-error" for="contactName"/></legend>
                                                                            <Form.Field placeholder="Name" name="contactName" type="text" />
                                                                        </div>
                                                                        <div className="form-inline-half mui-col-md-6">
                                                                            <legend>Relationship <Form.Message className="has-error" for="contactRelationship"/></legend>
                                                                            <Form.Field placeholder="Parent, Shadchan etc.." name="contactRelationship" type="text" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mui-row">
                                                                        <div className="form-inline-half mui-col-md-6">
                                                                            <legend>Contact Phone <Form.Message className="has-error" for="contactPhone"/></legend>
                                                                            <Form.Field placeholder="Phone" name="contactPhone" type="text" />
                                                                        </div>
                                                                        <div className="form-inline-half mui-col-md-6">
                                                                            <legend>Contact Email <Form.Message className="has-error" for="contactEmail"/></legend>
                                                                            <Form.Field placeholder="Email" name="contactEmail" type="text" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                ref="shadchan"
                                                                className={ this.state.editMode === 'shadchan' ? "resume-section editMode" : "resume-section"}
                                                                onFocus={this.sectionFocused.bind(this, 'shadchan')}
                                                                onClick={this.sectionFocused.bind(this, 'shadchan')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>
                                                                        Shadchan Contact Info <FontAwesome name='info-circle' data-multiline={true} data-tip={resumeTips.shadchanContactInfo}/>
                                                                        <Form.Message for={['shadchanName', 'shadchanPhone', 'shadchanEmail']}>
                                                                            {messages => 'Please fix errors'}
                                                                        </Form.Message>
                                                                    </h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <div className="mui-row">
                                                                        <div className="form-inline-third">
                                                                            <legend>Shadchan Name <Form.Message className="has-error" for="shadchanName"/></legend>
                                                                            <Form.Field placeholder="Shadchan Name" name="shadchanName" type="text" />
                                                                        </div>
                                                                        <div className="form-inline-third">
                                                                            <legend>Shadchan Phone <Form.Message className="has-error" for="shadchanPhone"/></legend>
                                                                            <Form.Field placeholder="Shadchan Phone" name="shadchanPhone" type="text" />
                                                                        </div>
                                                                        <div className="form-inline-third">
                                                                            <legend>Shadchan Email <Form.Message className="has-error" for="shadchanEmail"/></legend>
                                                                            <Form.Field placeholder="Shadchan Email" name="shadchanEmail" type="text" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mui-row">
                                                                        <div className="mui-col-md-12">
                                                                            <legend>Shadchan Additional Information<Form.Message className="has-error" for="shadchanDetails"/></legend>
                                                                            <Form.Field placeholder="Shadchan Additional Information" name="shadchanDetails" type="text" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                ref="references"
                                                                className={ this.state.editMode === 'references' ? "resume-section editMode" : "resume-section"}
                                                                onFocus={this.sectionFocused.bind(this, 'references')}
                                                                onClick={this.sectionFocused.bind(this, 'references')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>
                                                                        References {isMine && "*"}
                                                                        <span><FontAwesome name='info-circle' data-multiline={true} data-tip={resumeTips.references} /></span>
                                                                        <Form.Message className="has-validation-error" for='references'/>
                                                                        <Form.Trigger group='references'>
                                                                            {({ messages }) => _.isEmpty(messages) ? null
                                                                                : <span className="validation-error" ><FontAwesome name='exclamation-circle'/> Please fix Errors</span> }
                                                                        </Form.Trigger>
                                                                    </h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    <ul>
                                                                        {resume.references.length ? resume.references.map((reference, i) => {
                                                                            if(!reference){
                                                                                return null;
                                                                            }
                                                                            return (
                                                                                <li key={`references${i}`} className={resume.references.length > 1 ? 'additional-item' : '' }>
                                                                                    {resume.references.length > 1 ?
                                                                                        <FontAwesome name='times'
                                                                                                     className="resume-fields-close"
                                                                                                     onClick={() => this.deleteNestedObject('references', i)}/>
                                                                                        : null }
                                                                                    <div className="mui-row">
                                                                                        <div className="form-inline-half mui-col-md-6">
                                                                                            <legend>Reference Name {isMine && "*"}<Form.Message className="has-error" for={`references[${i}].name`}/></legend>
                                                                                            <Form.Field
                                                                                                group='references'
                                                                                                name={`references[${i}].name`}
                                                                                                alsoValidates={[`references`]}
                                                                                                placeholder="Reference Name"
                                                                                                type="text"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-inline-half mui-col-md-6">
                                                                                            <legend>Reference Type {isMine && "*"}<Form.Message className="has-error" for={`references[${i}].referenceType`}/></legend>
                                                                                            <Form.Field
                                                                                                group='references'
                                                                                                name={`references[${i}].referenceType`}
                                                                                                className="reference-select details-text"
                                                                                                type="select">
                                                                                                <option disabled value="">Choose a reference type</option>
                                                                                                <option value="Personal">Personal</option>
                                                                                                <option value="Family">Family</option>
                                                                                                <option value="Professional">Professional</option>
                                                                                            </Form.Field>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mui-row">
                                                                                        <div className="form-inline-half mui-col-md-12">
                                                                                            <legend>Reference Number or Email {isMine && "*"} <Form.Message className="has-error" for={[`references[${i}].contactInfo`]}/></legend>
                                                                                            {reference.contactInfo.length ? reference.contactInfo.map((info, i2)=> {
                                                                                                return (
                                                                                                    <div key={`refInfo${i2}`}>
                                                                                                        {this.state.editMode === 'references' && resume.references[i].contactInfo.length > 1 ?
                                                                                                            <FontAwesome name='times'
                                                                                                                         className="resume-fields-close"
                                                                                                                         onClick={this.props.deleteDoubleNestedObject.bind(this, 'references', i, 'contactInfo', i2)}/>
                                                                                                            : null
                                                                                                        }
                                                                                                        <div className={reference.contactInfo.length > 1 ? 'reference-item' : ''}>
                                                                                                            <Form.Field
                                                                                                                group='references'
                                                                                                                name={`references[${i}].contactInfo[${i2}].contactText`}
                                                                                                                alsoValidates={[`references[${i}].contactInfo`, `references[${i}]`, `references`]}
                                                                                                                placeholder="Reference Number or Email"
                                                                                                                type="text"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }) : null }
                                                                                            <div className="resume-addItem resume-addItem-simple" onClick={this.props.addDoubleNestedObject.bind(this, 'references', i, 'contactInfo')}>
                                                                                                <FontAwesome name='plus'/>
                                                                                                <span> Add Number or Email</span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="mui-col-md-12 form-inline-half">
                                                                                            <legend>Additional Information<Form.Message className="has-error" for={`references[${i}].details`} /></legend>
                                                                                            <Form.Field
                                                                                                group='references'
                                                                                                name={`references[${i}].details`}
                                                                                                placeholder="Additional Details"
                                                                                                type={resizableTextArea} />
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }) : null}
                                                                    </ul>
                                                                    <div className="mui-row clearfix" onClick={this.props.addNestedObject.bind(this, 'references')}>
                                                                        <div className="resume-addItem" >
                                                                            <FontAwesome name='plus'/>
                                                                            <span> Add Reference</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </VisibilitySensor>
                                                    <VisibilitySensor onChange={isVisible=>this.areaVisibilityChanged(isVisible, 5)} intervalCheck={false}  scrollCheck={true} scrollDelay={10} partialVisibility={true}>
                                                        <div ref="photos">
                                                            <div
                                                                ref="images"
                                                                className={ this.state.editMode === 'images' ? "resume-section editMode" : "resume-section"}
                                                                onFocus={this.sectionFocused.bind(this, 'images')}
                                                                onClick={this.sectionFocused.bind(this, 'images')}
                                                            >
                                                                <div className="resume-title-header">
                                                                    <h2>Photos</h2>
                                                                </div>
                                                                <div className="resume-fields">
                                                                    {resume.images.length ? resume.images.map((image, index)=> {
                                                                        return (
                                                                            <div key={`image${index}`} className="resumePicPreview mui-row">
                                                                                <div className="resume-pic-container">
                                                                                    <FontAwesome name='trash' className='group-color-light mui--text-center' onClick={this.props.removeImage.bind(this, index)} />
                                                                                    <PrivateImage fileName={image.fileLocation} id={resume.id || 0} section='resumeSmall' className='dragDropPreview'/>
                                                                                    {index !== 0 ?
                                                                                        <span className="make-primary" onClick={this.props.setImageAsMain.bind(this, index)}>Make Primary</span>
                                                                                        : null }
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    })
                                                                        : null
                                                                    }

                                                                    <Dropzone
                                                                        onDropRejected={()=>this.setState({imageRejected: true})}
                                                                        onDropAccepted={this.onDrop}
                                                                        multiple={false}
                                                                        accept='image/*'
                                                                        className={resume.images.length > 1 ? 'dragDrop dragSquare': "dragDrop"}
                                                                        activeClassName={resume.images.length > 1 ? 'dragDropActive dragSquare': "dragDropActive"}>
                                                                        <div>
                                                                            <FontAwesome name='upload' className='group-color-light mui--text-center' />
                                                                            <h4 className="mui--text-center">Drop a .jpg or .png file here, or <span className="dragDropFileBtn">select file to upload</span></h4>
                                                                        </div>
                                                                        {this.state.imageTooLarge ? <span>That image is too big. Try another one!</span>: null}
                                                                        {this.state.imageRejected ? <span>Whoops... that image won't work. Try again!</span>: null}
                                                                    </Dropzone>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </VisibilitySensor>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div className="resume-sidebar-container">
                                <div className="resume-messageBar">
                                    <div className="resume-messageBar-content">
                                        {!this.props.myResumeId &&
                                            <div className="resume-myOwn">
                                                <input type="checkbox" checked={this.props.isMine} onChange={this.props.changeResumeOwner}/>
                                                <span>Is this your own resume?</span>
                                            </div>
                                        }

                                        {this.props.isLoading && resume && resume.id && this.props.saved ?
                                            <p className="saving"><FontAwesome name='save' /> Saving...</p>
                                            : null
                                        }

                                        {this.props.edited ?
                                                <p className="unsaved"><FontAwesome name='pencil'/> You have unsaved changes</p>
                                            :
                                            !this.props.isLoading && resume.id ?
                                                <p className="saved"><FontAwesome name='check' /> All changes saved</p>
                                                :
                                                null
                                        }

                                        {_.isEmpty(this.props.errors) ? null :
                                            <p className="validation-error-general validation-error">
                                                <FontAwesome name='exclamation-circle' />
                                                Please fix errors
                                            </p>
                                        }

                                        <div className="my-resume-edit-btns">
                                            <div className="btn-resume-save">
                                                <Form.Button type="submit" className="btn-secondary btn-flat">
                                                    {resume.id ? 'Update' : 'Save'}{this.props.isMine && !resume.published ? ' & Publish Resume': ' Resume'}
                                                </Form.Button>
                                            </div>

                                            {this.props.canBeSavedAsDraft() ?
                                                <div className="btn-secondary btn-flat" onClick={this.props.saveAsDraft}>
                                                    Save as draft
                                                </div>
                                                :
                                                null
                                            }

                                            {resume.id && resume.published && !this.props.edited ?
                                                <div>
                                                    <div className="btn-secondary btn-flat" onClick={this.props.shareResumeModal}>
                                                        Share via email
                                                    </div>
                                                    <div className="btn-secondary btn-flat" onClick={this.props.shareToGroupModal}>
                                                        Share to group
                                                    </div>
                                                </div>
                                                :
                                                resume.id &&
                                                    <Popup
                                                        trigger={
                                                            <div className="btn-secondary btn-flat" disabled>Share</div>
                                                        }
                                                        content={`Please ${this.props.isMine ? 'publish' : 'save'} before you can share`}
                                                    />
                                            }

                                            {resume.id && !this.props.edited ?
                                                <Link to={`/resumes/${resume.id}#updated`} className="btn-secondary btn-flat">
                                                    View Resume
                                                </Link>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ReactTooltip effect="solid" html={true}/>
                </Form.Context>
            </div>
        )
    }
}