import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as userActions from '../../actions/userActions';
import FontAwesome from 'react-fontawesome';
import HeaderNotifications from './HeaderNotifications';
import HeaderInviteUser from './HeaderInviteUser';
import HeaderShareResume from './HeaderShareResume';
import Popup from '../common/Popup';
import {findDOMNode} from 'react-dom';
import SupportSidebar from '../common/supportSidebar.js';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropDownOpen: '',
            miniDropDownOpen: ''
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onMiniMenuClick = this.onMiniMenuClick.bind(this);
    }

    componentDidMount() {
        if (document) {
            document.addEventListener('click', this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        if (document) {
            document.removeEventListener('click', this.handleClickOutside);
        }
    }

    handleClickOutside(event) {
        if (this.state.dropDownOpen) {
            let area = findDOMNode(this.refs[this.state.dropDownOpen]);

            if (area && !area.contains(event.target)) {
                this.setState({dropDownOpen: '', miniDropDownOpen: ''})
            }
        }
    }

    onClick(activeMenu) {
        this.setState({dropDownOpen: activeMenu});
    }

    handleMenuItemClick(menu, event) {
        if (event) {//icon clicked, stop it from reopening the menu
            event.stopPropagation();
        }
        this.setState({dropDownOpen: this.state.dropDownOpen === menu ? '' : menu, miniDropDownOpen: ''})
    }

    onMiniMenuClick(activeMenu) {
        this.setState({miniDropDownOpen: activeMenu});
    }

    handleMiniMenuItemClick(menu, event) {
        if (event) {//icon clicked, stop it from reopening the menu
            event.stopPropagation();
        }
        this.setState({miniDropDownOpen: this.state.miniDropDownOpen === menu ? '' : menu})
    }

    render() {
        let { user } = this.props;
        let { dropDownOpen, miniDropDownOpen } = this.state;
        let name = user.firstName + ' ' + user.lastName;
        let initials = name ?
            name.replace(/\W*(\w)\w*/g, '$1') : '?';
        return (
            <div id="header" className="mui-row">
                <div className="sidebar-left">
                    <Link to={`/resumes`}>
                        <img src="/images/logo-beta.png" alt="" className="header-logo"/>
                    </Link>
                    <div className="menu-large-item notifications-mobile">
                        <HeaderNotifications />
                    </div>
                    <div className="menu-xs">
                        <div ref="menuDropdownMobile"
                             className={dropDownOpen === 'menuDropdownMobile' ? "menu-large-item header-add dropdown-active" : "menu-large-item menu-add"}
                             onClick={this.onClick.bind(null, 'menuDropdownMobile')}>
                            <FontAwesome name='bars'
                                         onClick={this.handleMenuItemClick.bind(this, 'menuDropdownMobile')}/>
                            <div className="mobile-menu-dropdown-box mui--z2">
                                <div className="arrow-up"></div>
                                <div className="header-dropdown-content">
                                    <ul>
                                        <li>
                                            <Link to={`/accountSettings`}
                                                  onClick={this.handleMenuItemClick.bind(this, 'menuDropdownMobile')}><i
                                                className="material-icons">account_circle</i>Account Settings</Link>
                                        </li>
                                        <li ref="invite"
                                            className={miniDropDownOpen === 'invite' ?
                                                "invite-menu submenu-menu-active" : "invite-menu"}
                                            onClick={this.onMiniMenuClick.bind(null, 'invite')}>
                                            <div onClick={this.handleMiniMenuItemClick.bind(this, 'invite')}>
                                                <FontAwesome name='envelope'/> Invite to ZivugTech
                                            </div>
                                            <HeaderInviteUser
                                                miniMode={true}
                                                closeInvite={this.handleMiniMenuItemClick.bind(this, 'invite')}
                                            />
                                        </li>
                                        <li ref="share"
                                            className={miniDropDownOpen === 'share' ?
                                                "share-menu submenu-menu-active" : "share-menu"}
                                            onClick={this.onMiniMenuClick.bind(null, 'share')}>
                                            <div onClick={this.handleMiniMenuItemClick.bind(this, 'share')}>
                                                <FontAwesome name='file-text-o'/> Share Your Resume
                                            </div>
                                            <HeaderShareResume
                                                miniMode={true}
                                                closeShare={this.handleMiniMenuItemClick.bind(this, 'share')}
                                                closeMenu={() => this.setState({dropDownOpen: ''})}
                                            />
                                        </li>
                                        <li ref="add"
                                            className={miniDropDownOpen === 'add' ?
                                                "add-menu submenu-menu-active" : "add-menu"}
                                            onClick={this.onMiniMenuClick.bind(null, 'add')}>
                                            <div onClick={this.handleMiniMenuItemClick.bind(this, 'add')}>
                                                <FontAwesome name='plus'/> Add
                                            </div>
                                            <ul className="add-menu-submenu header-submenu">
                                                <li>
                                                    <Link to={`/resumes/create`}
                                                          onClick={this.handleMenuItemClick.bind(this, 'menuDropdownMobile')}>
                                                        <FontAwesome name='file-text-o'/>
                                                        Add Resume
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`/ideas#new`}
                                                          onClick={this.handleMenuItemClick.bind(this, 'menuDropdownMobile')}>
                                                        <FontAwesome name='lightbulb-o'/>
                                                        Add Idea
                                                    </Link>
                                                </li>
                                                <li><
                                                    Link to={`/groups/createNewGroup`}
                                                          onClick={this.handleMenuItemClick.bind(this, 'menuDropdownMobile')}>
                                                        <FontAwesome name='users'/>
                                                        Create Group
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <div onClick={this.props.actions.logOut}><FontAwesome name='power-off'/>
                                                Logout
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-main">
                    <div className="header-menu-main">
                        <ul>
                            <li><NavLink to={`/resumes`} activeClassName="active">Resumes</NavLink></li>
                            <li>
                                <NavLink to={`/inbox`} activeClassName="active">
                                    Inbox{this.props.unreadConversations.length ?
                                    <span className="count"></span> : null}
                                </NavLink>
                            </li>
                            <li><NavLink to={`/ideas`} activeClassName="active">Ideas</NavLink></li>
                            <li><NavLink to={`/groups`} activeClassName="active">Groups</NavLink></li>
                            <li>
                                <div ref="menuDropdownAdd"
                                     className={
                                         dropDownOpen === 'menuDropdownAdd' ?
                                             "menu-large-item header-dropdown dropdown-active"
                                             : "menu-large-item header-dropdown"
                                     } onClick={this.onClick.bind(null, 'menuDropdownAdd')}>
                                    <span onClick={this.handleMenuItemClick.bind(this, 'menuDropdownAdd')}>Create</span>
                                    <div className="header-dropdown-box header-add-dropdown mui--z2">
                                        <div className="arrow-up"></div>
                                        <div className="header-dropdown-list">
                                            <ul className="dropdown-content add-dropdown-content">
                                                <li>
                                                    <NavLink to={`/resumes/create`}
                                                             onClick={this.handleMenuItemClick.bind(this, 'menuDropdownAdd')}>
                                                        <FontAwesome name='file-text-o'/> Add Resume
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`/ideas#new`}
                                                             onClick={this.handleMenuItemClick.bind(this, 'menuDropdownAdd')}>
                                                        <FontAwesome name='lightbulb-o'/> Add Idea
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`/groups/createNewGroup`}
                                                             onClick={this.handleMenuItemClick.bind(this, 'menuDropdownAdd')}>
                                                        <FontAwesome name='users'/> Create Group
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="sidebar-content">
                    <div className="header-right">
                        <div className="menu-large">
                            <div className="menu-large-item">
                                <HeaderNotifications/>
                            </div>
                            <div ref="menuDropdownShare"
                                 className={
                                     dropDownOpen === 'menuDropdownShare' ?
                                         "menu-large-item header-dropdown dropdown-active"
                                         : "menu-large-item header-dropdown"
                                 }
                                 onClick={this.onClick.bind(null, 'menuDropdownShare')}>
                                {/*<img src="/images/share-resume-icon-white.png" onClick={this.handleMenuItemClick.bind(this, 'menuDropdownShare')} />*/}
                                <Popup
                                    trigger={
                                        <FontAwesome
                                            name='file-text'
                                            className="header-icon header-icon-resize"
                                            onClick={this.handleMenuItemClick.bind(this, 'menuDropdownShare')}/>
                                    }
                                    content="The quickest way to share your resume"
                                />

                                <div className="header-dropdown-box mui--z2">
                                    <div className="arrow-up"></div>
                                    <div className="header-dropdown-content-header">
                                        <h4 className="header-dropdown-content-title">Share Your Resume</h4>
                                    </div>
                                    <HeaderShareResume
                                        closeShare={() => this.setState({dropDownOpen: ''})}
                                        closeMenu={this.handleMenuItemClick.bind(this, 'menuDropdownShare')}
                                    />
                                </div>
                            </div>
                            <div ref="menuDropdownInvite"
                                 className={
                                     dropDownOpen === 'menuDropdownInvite'
                                         ? "menu-large-item header-dropdown dropdown-active"
                                         : "menu-large-item header-dropdown"
                                 } onClick={this.onClick.bind(null, 'menuDropdownInvite')}>
                                <Popup
                                    trigger={
                                        <FontAwesome
                                            name='envelope'
                                            className="header-icon header-icon-resize"
                                            onClick={this.handleMenuItemClick.bind(this, 'menuDropdownInvite')}/>
                                    }
                                    content="Spread the ZivugTech love! Send a single or shadchan an invite to join ZivugTech"
                                />

                                <div className="header-dropdown-box header-invite-dropdown-box mui--z2">
                                    <div className="arrow-up"></div>
                                    <div className="header-dropdown-content-header">
                                        <h4 className="header-dropdown-content-title">Invite to ZivugTech</h4>
                                    </div>
                                    <HeaderInviteUser closeInvite={() => this.setState({dropDownOpen: ''})}/>
                                </div>
                            </div>
                            <div className="menu-large-item">
                                <SupportSidebar/>
                            </div>
                            <div ref="menuDropdownAccount"
                                 className={
                                     dropDownOpen === 'menuDropdownAccount'
                                         ? "menu-large-item header-dropdown dropdown-active"
                                         : "menu-large-item header-dropdown"}
                                 onClick={this.onClick.bind(null, 'menuDropdownAccount')}>
                                <Popup
                                    trigger={
                                        <i className="material-icons header-icon"
                                           onClick={this.handleMenuItemClick.bind(this, 'menuDropdownAccount')}>account_circle</i>
                                    }
                                    content="Click here to view your 'Account Settings', edit or create 'My Resume' or simple logout! "
                                />

                                <div ref="dropDownOpen" className="header-dropdown-box mui--z2">
                                    <div className="arrow-up"></div>
                                    <div className="my-account-header-section mui-row">
                                        <span className="initials">{initials}</span>
                                        <h3>
                                            {user.firstName} {user.lastName}
                                            {user.approved ?
                                                <Popup
                                                    trigger={
                                                        <FontAwesome name='certificate'/>
                                                    }
                                                    content={`Verified User`}
                                                />
                                                : ''
                                            }
                                        </h3>
                                        <p className="my-account-email">{user.email}</p>
                                    </div>
                                    <div className="my-account-footer-section mui-row">
                                        <ul onClick={this.handleMenuItemClick.bind(this, '')}>
                                            <li>
                                                <Link to={`/accountSettings`}>
                                                    <FontAwesome name='cog'/> Account Settings
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/myResume`}><FontAwesome name='file-text-o'/> My Resume
                                                    <span className="add-my-resume">
                                                    {user.hasVerifiedResume || user.hasDraft ? 'edit' : 'create'}
                                                    </span>
                                                </Link>
                                            </li>
                                            <li onClick={this.props.actions.logOut}>
                                                <FontAwesome name='power-off'/>
                                                Logout
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user.user,
        unreadConversations: state.conversations.unreadConversations
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);