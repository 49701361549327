import * as types from '../actionTypes/shareActionTypes';
import { apiAuthRequest } from './helpers';


function shareResumeSuccess(info, resumesShared) {
	return {
		type: types.SHARE_RESUME_SUCCESS,
		info,
		resumesShared,
		analytics: {
			event: 'share_resume',
			params: {
				share_type: info.conversation.suggestionType,
				resumes: resumesShared
			}
		}
	}
}

function shareIdeaSuccess() {
	return {
		type: types.SHARE_IDEA_SUCCESS,
        analytics: {
            event: 'share_idea',
            params: {}
        }
	}
}

function shareToGroupSuccess(groupResume) {
	return {
		type: types.SHARE_TO_GROUP_SUCCESS,
		groupResume,
		analytics: {
			event: 'share_resume_to_group',
            params: {
                resumeId: groupResume.resumeId,
                groupId: groupResume.groupId
            }
		}
	}

}

export function shareResume(share, closeModal) {
	return async dispatch => {
		dispatch({ type: types.SHARE_RESUME_START });

		let result = await apiAuthRequest('post', '/shares/shareResume', share);
		if (result.success) {
			dispatch(shareResumeSuccess(result.data, share.resumes));
			setTimeout(closeModal, 4000);
		} else {
			console.error(result.data);
			dispatch({ type: types.SHARE_RESUME_ERROR });
		}

	}
}

export function shareToGroup(share, closeModal) {
	return async dispatch => {
		dispatch({ type: types.SHARE_TO_GROUP_START });

		let result = await apiAuthRequest('post', '/shares/shareToGroup', share);
		if (result.success) {
			dispatch(shareToGroupSuccess(result.data));
			setTimeout(closeModal, 3000);
		} else {
			console.error(result.data);
			dispatch({ type: types.SHARE_TO_GROUP_ERROR });
		}
	}
}

export function shareIdea(shares, closeModal) {
	return async dispatch => {
		dispatch({ type: types.SHARE_IDEA_START });

		let sharePromises = shares.map((share) => {
			return (apiAuthRequest('post', '/shares/shareResume', share));
		});
		let response = await Promise.all(sharePromises);
		let error = response.some(result => !result.success);
		if(error){
			console.error(error);
			dispatch({ type: types.SHARE_IDEA_ERROR });
		} else {
			dispatch(shareIdeaSuccess());
			setTimeout(closeModal, 3000);
		}
	}
}