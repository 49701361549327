import React from 'react';
import Moment from 'moment';

export default class GroupSectionAbout extends React.Component {
    constructor(props) {
        super(props);
    }

    typesList = (type) => <li key={type.id}>{type.text}</li>;

    specialtiesList = (specialty) => <li key={specialty.id}>{specialty.text}</li>;

    render() {
        let { group } = this.props;
        const dateTime= group.groupCreatedOn;
        const formattedDT = Moment(dateTime).format('MMMM Do, YYYY');

        return (
            <div className="mui-row">
                <div className="mui-col-md-7">
                    <div className="content-main-section">
                        <h3 className="title">About Group</h3>
                        <p className="group-description">{group.desc}</p>
                        {group.groupPhone ? <p><span className="section-infoTitle">Phone:</span> {group.groupPhone}</p> : null}
                        {group.groupEmail ? <p><span className="section-infoTitle">Email:</span> {group.groupEmail}</p> : null}
                        {group.groupAddressStreet && group.groupAddressCity ?
                            <p><span className="section-infoTitle long-section-infoTitle">Address:</span> <span className="group-address">{group.groupAddressStreet} {group.groupAddressCity} {group.groupAddressState} {group.groupAddressZip}</span></p>
                            : null
                        }
                        {group.groupWebsite ? <a href={"http://" + group.groupWebsite} target="_blank"><span className="section-infoTitle">Website:</span> {group.groupWebsite}</a> : null}
                        {group.groupFacebook ? <p><span className="section-infoTitle">Facebook:</span> {group.groupFacebook}</p> : null}
                        {group.groupName ? <p><span className="section-infoTitle">Organization Name:</span> {group.groupName}</p> : null}
                    </div>
                    {group.types.length ?
                        <div className="content-main-section">
                            <h3 className="title">Types</h3>
                            <ul className="comma">{group.types ? group.types.map(type => this.typesList(type)) : <li>No Types</li>}</ul>
                        </div> : null
                    }
                    {group.specialties.length ?
                        <div className="content-main-section">
                            <h3 className="title">Specialties</h3>
                            <ul className="comma">{group.specialties ? group.specialties.map(specialty => this.specialtiesList(specialty)) : <li>No Specialties</li>}</ul>
                        </div> : null
                    }
                    <div className="content-main-section">
                        <h3 className="title">Group created on</h3>
                        <p>{formattedDT}</p>
                    </div>
                </div>
                <div className="mui-col-md-4">
                    {/*{group.memberAccessLevel <= 2 ?*/}
                        {/*<div className="content-main-section">*/}
                            {/*<h3 className="title">Resume Count</h3>*/}
                            {/*<p>{group.resumeCount} Resume{group.resumeCount === 1 ? '' : 's'}</p>*/}
                        {/*</div>*/}
                        {/*: ''*/}
                    {/*}*/}
                    {/*<div className="content-main-section">*/}
                        {/*<h3 className="title">Member Count</h3>*/}
                        {/*<p>{group.membersCount} Member{group.membersCount === 1 ? '' : 's'}</p>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}