import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as welcomePageActions from '../../actions/welcomeActions';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Subheader from '../common/SubHeader';

class WelcomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (
            <div className="container">
                <Helmet>
                    <title>Welcome | ZivugTech</title>
                </Helmet>
                <Subheader title="Quick Feature Overview" />
                <div className="sub-header-content mui-container">
                    <div className="mui-row welcome-screen-container">
                        <div className="welcome-box-container">
                            <div className="welcome-box">
                                <div className="welcome-feature-name">
                                    <p className="feature-title">Resumes</p>
                                </div>
                                <p className="feature-explanation text-align-center">Here you can view resumes you have created or have access to.</p>
                                <Link to={`/resumes`} name="createResume" className="btn-flat btn-secondary btn-center">View</Link>
                            </div>
                        </div>
                        <div  className="welcome-box-container">
                            <div className="welcome-box">
                                <div className="welcome-feature-name">
                                    <p className="feature-title">Inbox</p>
                                </div>
                                <p className="feature-explanation text-align-center">Here you can send and receive messages about resumes right in ZivugTech! </p>
                                <Link to={`/inbox`} name="inbox" className="btn-flat btn-secondary btn-center">View</Link>
                            </div>
                        </div>
                        <div  className="welcome-box-container">
                            <div className="welcome-box">
                                <div className="welcome-feature-name">
                                    <p className="feature-title">Ideas</p>
                                </div>
                                <p className="feature-explanation text-align-center">Here you can create ideas and keep track of their status by moving cards to the next stage. </p>
                                <Link to={`/ideas`} name="ideas" className="btn-flat btn-secondary btn-center">View</Link>
                            </div>
                        </div>
                        <div  className="welcome-box-container">
                            <div className="welcome-box">
                                <div className="welcome-feature-name">
                                    <p className="feature-title">Groups</p>
                                </div>
                                <p className="feature-explanation text-align-center">Here you can view groups, add resumes to groups or view groups you are a member of.</p>
                                <Link to={`/groups`} name="groups" className="btn-flat btn-secondary btn-center">View</Link>
                            </div>
                        </div>
                        {/*<div>*/}
                            {/*<div className="welcome-box">*/}
                                {/*<div className="welcome-feature-name">*/}
                                    {/*<p className="feature-title">Search Resumes</p>*/}
                                    {/*<p className="feature-explanation">Explanation of this feature</p>*/}
                                {/*</div>*/}
                                {/*<Link to={`/resumes`} name="resumes" className="btn-flat btn-secondary btn-center btn-link">Search</Link>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        welcome: state.welcome
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(welcomePageActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);