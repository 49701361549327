export const LOAD_RESUMES_START = 'LOAD_RESUMES_START';
export const LOAD_RESUMES_SUCCESS = 'LOAD_RESUMES_SUCCESS';
export const CANCEL_RESUME_LOADING = 'CANCEL_RESUME_LOADING';
export const LOAD_SINGLE_RESUME_SUCCESS = 'LOAD_SINGLE_RESUME_SUCCESS';
export const LOAD_SINGLE_RESUME_START = 'LOAD_SINGLE_RESUME_START';
export const ADD_TAG_TO_RESUME = 'ADD_TAG_TO_RESUME';
export const DELETE_TAG_FROM_RESUME = 'DELETE_TAG_FROM_RESUME';
export const ADD_NOTE_TO_RESUME = 'ADD_NOTE_TO_RESUME';
export const EDIT_NOTE_SUCCESS = 'EDIT_NOTE_SUCCESS';
export const PIN_RESUME = 'PIN_RESUME';
export const UNPIN_RESUME = 'UNPIN_RESUME';
export const FLAG_RESUME = 'FLAG_RESUME';
export const UNFLAG_RESUME = 'UNFLAG_RESUME';
export const ENGAGED_RESUME_REPORTED = 'ENGAGED_RESUME_REPORTED';
export const SAVING_NOTE = 'SAVING_NOTE';
export const LOAD_EDIT_RESUME_START = 'LOAD_EDIT_RESUME_START';
export const LOAD_EDIT_RESUME_SUCCESS = 'LOAD_EDIT_RESUME_SUCCESS';
export const LOAD_EDIT_RESUME_FAIL = 'LOAD_EDIT_RESUME_FAIL';
export const CLEAR_EDIT_RESUME = 'CLEAR_EDIT_RESUME';
export const SAVE_RESUME_START = 'SAVE_RESUME_START';
export const SAVE_RESUME_SUCCESS = 'SAVE_RESUME_SUCCESS';
export const CREATE_RESUME_START = 'CREATE_RESUME_START';
export const SAVE_MY_RESUME_SUCCESS = 'SAVE_MY_RESUME_SUCCESS';
export const SAVING_GROUP_RESUME_NOTE = 'SAVING_GROUP_RESUME_NOTE';
export const EDIT_GROUP_RESUME_NOTE_SUCCESS = 'EDIT_GROUP_RESUME_NOTE_SUCCESS';
