import * as types from '../actionTypes/resumeActionTypes';
import {LOAD_GROUPS_LIST} from '../actionTypes/constantsTypes';
import axios from 'axios';
import { apiAuthRequest } from './helpers';
import { toastr } from 'react-redux-toastr';

let pendingRequests = [];
const CancelToken = axios.CancelToken;

export function loadResumes(params) {
    return async dispatch => {
        if (params.pageNumber === 1) {
            let oldRequests = pendingRequests;
            pendingRequests = [];
            while (oldRequests.length){
                try {
                    oldRequests.shift().token('cancelling request');
                } catch(e){
                	console.log(e)
                }
            }
            dispatch({ type: types.CANCEL_RESUME_LOADING })
        }

        dispatch({ type: types.LOAD_RESUMES_START, search: params });

        let cancellableToken;
        let result = await apiAuthRequest('post', '/resumes/getByUser', params, new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            // save c to a list of unresolved requests;
            cancellableToken = c;
            pendingRequests.push({page: params.pageNumber, token: cancellableToken});
        }));

        pendingRequests = pendingRequests.filter(i => i && i.page !== params.pageNumber);

        if(result.success) {
            dispatch({
                type: types.LOAD_RESUMES_SUCCESS,
                resumes: result.data
            });
        } else if(!result.cancelled) {
            toastr.error('Error loading resumes');
            console.error(result.data);
        }

    }
}

export function getGroupsImPartOf() {
	return async dispatch => {
		let result = await apiAuthRequest('get', '/groups/getGroupsImPartOf');
		if(result.success) {
			dispatch({
				type: LOAD_GROUPS_LIST,
				groups: result.data
			});
		}
	}
}









