import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { Scrollbars } from 'react-custom-scrollbars';
import Popup from '../common/Popup';

export default class SupportSidebar extends React.Component {
    static contextTypes = {
        objectHeights: PropTypes.object.isRequired
    };

    constructor(props){
        super(props);
        this.state = {
            supportOpen: false
        };
    }

    toggleSupport(){
        this.setState({supportOpen: !this.state.supportOpen});
    }

    openLiveChat(){
        let inside = this;
        if(window.$crisp) {
            window.$crisp.push(["do", "chat:show"]);
            window.$crisp.do("chat:open");
            window.$crisp.on("chat:closed", () => {
                window.$crisp.push(["do", "chat:hide"]);
                inside.setState({supportOpen: true});
            });
        }
        this.setState({supportOpen: false});
    }

    triggerFeedback(){
        let el = document.getElementById('hiddenFeedback');
        el.click();
        this.setState({supportOpen: false});
    }
    
    render() {
        const sidebarHeight = this.context.objectHeights.sidebarHeight - 63;
        return (
            <div id="support" ref="supportSidebar">
                <Popup
                    trigger={
                        <div className={`support-content-arrow ${this.state.supportOpen ? 'support-open' : 'support-closed'}`}
                             onClick={this.toggleSupport.bind(this)}>
                            <FontAwesome name='question' className="icon-dark header-icon" />
                        </div>
                    }
                    content="Have a question or piece of advice? Click here to search the 'KnowledgeBase', start a 'Live Chat' with our support team, or send us some helpful 'Feedback'. "
                />

                <div className={`support-container ${this.state.supportOpen ? 'support-open' : 'support-closed'}`}>
                    <div className="support-bar">
                        <p className="support-bar-title">Support</p>
                        <p onClick={() => this.setState({supportOpen: false})}
                           className="support-button support-bar-close">
                            <FontAwesome name='times' />
                        </p>
                    </div>
                    <div className="support-content-container">
                        <Scrollbars style={{height: sidebarHeight }}>
                            <div>
                                <div>
                                    <div className='support-header'>
                                        <div className='support-header-icon support-header-icon-active'>
                                            <FontAwesome name='book' />
                                            <p>KnowledgeBase</p>
                                        </div>
                                        <div className="support-header-icon" onClick={this.openLiveChat.bind(this)}>
                                            <FontAwesome name='comments-o' />
                                            <p>Live Chat</p>
                                        </div>
                                        <div className="support-header-icon">
                                            <span onClick={this.triggerFeedback.bind(this)}>
                                                <FontAwesome name='smile-o' />
                                                <a href="#">Feedback</a>
                                            </span>
                                            <a id="hiddenFeedback" href="#" data-trigger-bugyard-feedback="true"
                                               style={{display: 'none'}}>Feedback</a>
                                        </div>
                                    </div>
                                    <iframe src="https://help.zivugtech.org" height={ sidebarHeight - 162 } width="380">

                                    </iframe>
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        );
    };
}