import React from 'react';

class PrivateImage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <img
            ref="currentImage"
            src={this.props.fileName ? `/api/images/${this.props.section}/${this.props.id}/${this.props.fileName}?${this.props.resize ? `resize=${this.props.resize}` : ''}` : '/images/xl-placeholder.png'}
            className={this.props.className}
            height={this.props.height}
            width={this.props.width}
            onClick={this.props.onClick}
        />
    }
}

export default PrivateImage;