import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as shareActions from '../../actions/shareActions';
import yup from 'yup';
import Form from 'react-formal';

const shareResumeSchema = yup.object().shape({
    recipientEmail: yup.string().email('Invalid email').required('Required'),
    text: yup.string().required('Please write a message')
});

class HeaderShareResume extends Component {
    constructor(props) {
        super(props);
        this.state = {
            share: {},
            errors: {},
            sent: false
        };
    }

    componentWillReceiveProps(nextProps){
        if(!this.props.sentToUserSuccessfully && nextProps.sentToUserSuccessfully){
            this.setState({share:{}});
            setTimeout(()=>this.setState({sent: false}), 3000)
        }
    }

    shareResume(values){
        let share = {
            recipientEmail: values.recipientEmail,
            suggestionType: 3,
            text: values.text,
            resumes: [this.props.myResume.id],
            nameShares: []
        };
        this.setState({sent: true}, ()=>{
            this.props.actions.shareResume(share, this.props.closeShare);
        });
    }


    render() {
        let content;

        if(!this.props.myResume.id) {
            content = (
                <div className="header-dropdown-content-create">
                    <p>You have not yet created your resume <Link to={`/myResume`} onClick={this.props.closeMenu} > create it now?</Link></p>
                </div>
            );
        } else if (!this.props.myResume.published) {
            content = (
                <div className="header-dropdown-content-create">
                    <p>You have not yet published your resume <Link to={`/myResume`} onClick={this.props.closeMenu} > publish it now?</Link></p>
                </div>
            )
        } else {
            content =
                this.state.sent && this.props.sentToUserSuccessfully ?
                    (
                        <div className="modal-response">
                            <div className="envelope">
                                <div className="envelope-outline-small">
                                    <div className="triangle-small"></div>
                                </div>
                                <div className="dot dot1"></div>
                                <div className="dot dot1"></div>
                                <div className="dot dot2"></div>
                                <div className="dot dot3"></div>
                                <div className="dot dot4"></div>
                                <div className="dot dot5"></div>
                                <div className="dot dot6"></div>
                            </div>
                            <p>Resume Sent</p>
                        </div>
                    )
                    :
                    <Form
                        value={this.state.share}
                        schema={shareResumeSchema}
                        onChange={share=>this.setState({share})}
                        onSubmit={this.shareResume.bind(this)}
                        id="form"
                    >
                        <legend>Email <Form.Message className='has-error' for='recipientEmail'/></legend>
                        <Form.Field
                            type="text"
                            name="recipientEmail"
                            events={['onChange']}
                            placeholder="Enter email address"
                            className="form-input-margin-bottom"
                        />

                        <legend>Message <Form.Message className='has-error' for='text'/></legend>
                        <Form.Field
                            type="textarea"
                            name="text"
                            events={['onChange']}
                            placeholder="Enter a message here"
                            className="no-resize"
                        />
                        {this.props.isSharingResume ?
                            <button className="btn-flat share-btn">
                                <div className="loader-small"></div>
                            </button>
                            :
                            <Form.Button type='submit' className="btn-flat share-btn">
                                Share
                            </Form.Button>
                        }
                    </Form>
        }
        
        return (
            <div className={this.props.miniMode ? "share-submenu header-submenu" : "header-dropdown-content"}>
                {content}
            </div>
        )
       
    }
}

function mapStateToProps(state) {
    return {
        myResume: state.user.user.resume,
        isSharingResume: state.singleResume.isSharingResume,
        sentToUserSuccessfully: state.singleResume.sentToUserSuccessfully
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(shareActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderShareResume);
