import yup from 'yup';

const experienceObject = yup.object().shape({
    name: yup.string(),
    details: yup.mixed()
});

export default yup.object().shape({
  firstName: yup.string().required('First name is required'),
  fullName: yup.mixed().nullable(),
  lastName: yup.string().required('Last name is required'),
  gender: yup.mixed().required(),
  birthday: yup.date().nullable(),
  age: yup.mixed(),
  height: yup.mixed().nullable(true),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zip: yup.string(),
    hometown: yup.mixed(),
    relocate: yup.mixed(),
    maritalStatus: yup.string(),
    numberChildren: yup.string().when(['maritalStatus'], {
        is: maritalStatus => maritalStatus > 1,
        then:      yup.string().required('Number of children is required'),
        otherwise: yup.string()
    }),
    ethnicity: yup.mixed(),
    religious: yup.mixed(),
    isCohen: yup.bool(),
    languages: yup.array().of( yup.object() ),

    personalDescription: yup.mixed(),
    lookingFor: yup.mixed(),

    email: yup.string().email('Invalid email'),
    mobilePhone: yup.mixed(),
    homePhone: yup.mixed(),
    shidduchContactInfo: yup.mixed(),
    displayPersonalContactInfo: yup.mixed(),

    contactName: yup.mixed(),
    contactEmail: yup.string().email('Invalid email'),
    contactPhone: yup.mixed(),
    contactRelationship: yup.mixed(),

    shadchanName: yup.string().when(['shadchanEmail', 'shadchanPhone', 'shadchanDetails'], {
        is: (shadchanEmail, shadchanPhone, shadchanDetails) => shadchanEmail || shadchanPhone || shadchanDetails,
        then:      yup.string().required('Shadchan Name is required'),
        otherwise: yup.string()
    }),
    shadchanEmail: yup.string().email('Invalid email'),
    shadchanPhone:  yup.mixed(),
    shadchanDetails: yup.mixed(),

    types: yup.array().of(
        yup.object().shape({
            typeId: yup.number(),
            power: yup.number()
        })
    ),
    images: yup.array().of(
        yup.object().shape({
            fileLocation: yup.mixed(),
            mainImage: yup.mixed()
        })
    ),
    shuls: yup.array().of(
        yup.object().shape({
            name: yup.mixed(),
            rabbi: yup.mixed(),
            rabbiPhone: yup.mixed(),
            details: yup.mixed()
        }),
    ),
    familyInfo: yup.object().shape({
        info: yup.mixed()
    }),
    education: yup.array().of(experienceObject),
    occupation: yup.object().shape({
        name: yup.string(),
        details: yup.mixed()
    }),
    camps: yup.array().of(experienceObject),
    volunteer: yup.array().of(experienceObject),
    references: yup.array().of(
      yup.object().shape({
          name: yup.string().when(['details', 'referenceType'], {
              is: (details, referenceType) => details || referenceType,
              then:      yup.string().required('Name is required'),
              otherwise: yup.string()
          }),
          relationship: yup.mixed(),
          referenceType: yup.mixed(),
          details: yup.mixed(),
          contactInfo: yup.array().of(
              yup.object().shape({
                  contactType: yup.mixed(),
                  contactText: yup.mixed()
              })
          )
        })
    )
});
