import React, { Component } from "react";
import PropTypes from "prop-types";
import { findDOMNode } from "react-dom";
import { DragSource } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import Card from "./Card";
import isMobile from "ismobilejs";

function getStyles(isDragging) {
  return {
    display: isDragging ? 0.5 : 1,
  };
}

const cardSource = {
  beginDrag(props, monitor, component) {
    const { item, x, y } = props;
    const { id } = item;
    const { clientWidth, clientHeight } = findDOMNode(component);

    return { id, item, x, y, clientWidth, clientHeight };
  },
  canDrag: function (props, monitor) {
    return !isMobile.any;
  },
  endDrag(props, monitor) {
    document.getElementById(monitor.getItem().id).style.display = "block";
  },
  isDragging(props, monitor) {
    return props.item && props.item.id === monitor.getItem().id;
  },
};

function collectDragSource(connectDragSource, monitor) {
  return {
    connectDragSource: connectDragSource.dragSource(),
    connectDragPreview: connectDragSource.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

// @DragSource('card', cardSource, collectDragSource)
class CardComponent extends Component {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  render() {
    const { isDragging, connectDragSource, item, openIdea } = this.props;

    return connectDragSource(
      <div onClick={openIdea}>
        <Card style={getStyles(isDragging)} item={item} />
      </div>,
    );
  }
}

CardComponent.propTypes = {
  item: PropTypes.object.isRequired,
  openIdea: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func,
  connectDragPreview: PropTypes.func,
  isDragging: PropTypes.bool,
  x: PropTypes.number.isRequired,
  y: PropTypes.number,
};

CardComponent = DragSource(
  "card",
  cardSource,
  collectDragSource,
)(CardComponent);

export default CardComponent;
