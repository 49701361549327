import React from 'react';
import * as shareActions from '../../../actions/shareActions';
import GroupDropdown from './groupDropdown';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ShareToGroupModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            share: {
                resumeId: props.currentResume ? props.currentResume.id : props.singleResume.id,
                message: ''
            },
            submitted: false,
            errorMessage:''
        };
        this.handleChange = this.handleChange.bind(this);
    }

    save(e) {
        e.preventDefault();
        if(!this.state.share.groupId){
            this.setState({errorMessage:'Please choose a group'});
            return;
        }
        if(this.state.resumeAlreadyAdded){
            return;
        }
        this.props.actions.shareToGroup(this.state.share, this.props.removeModal);
        this.setState({submitted: true});
    }

    selectGroup(group){
        let share = this.state.share;
        share.groupId = group.id || null;
        share.groupName = group.name || '';
        return this.setState({
            share : share,
            resumeAlreadyAdded: !!group.hasResume,
            errorMessage: group.hasResume ? 'Resume has already been added to this group' : ''
        })
    }

    handleChange(e) {
        const field = e.target.name;
        let share = this.state.share;
        share[field] = e.target.value;
        return this.setState({share: share});
    }

    render() {
        if (this.state.submitted && this.props.isSendingToGroup) {
            return (
                <div className='loader'></div>
            )
        } else if (this.state.submitted && this.props.resumeSentToGroup){
            return (
                <div className="medium-modal">
                    <div className="mui-row">
                        <i className="material-icons modal-close-icon"
                           onClick={this.props.removeModal.bind(this)}>close</i>
                        {this.props.sharedToGroupSuccessfully ? 
                            <div>{this.props.resumeApprovedToGroup ?
                                (<div className="modal-response">
                                    <div className="envelope">
                                        <div className="envelope-outline">
                                            <div className="triangle"></div>
                                        </div>
                                        <div className="dot dot1"></div>
                                        <div className="dot dot2"></div>
                                        <div className="dot dot3"></div>
                                        <div className="dot dot4"></div>
                                        <div className="dot dot5"></div>
                                        <div className="dot dot6"></div>
                                    </div>
                                    <p>Resume added to group</p>
                                </div>)
                                :
                                (<div className="modal-response">
                                    <div className="envelope">
                                        <div className="envelope-outline">
                                            <div className="triangle"></div>
                                        </div>
                                        <div className="dot dot1"></div>
                                        <div className="dot dot2"></div>
                                        <div className="dot dot3"></div>
                                        <div className="dot dot4"></div>
                                        <div className="dot dot5"></div>
                                        <div className="dot dot6"></div>
                                    </div>
                                    <p>Resume sent to group</p>
                                </div>)
                                }
                            </div>
                            :
                            (<div className="modal-response">
                                <div className="envelope2">
                                    <div className="envelope-outline">
                                        <div className="triangle"></div>
                                    </div>
                                    <div className="dot dot1"></div>
                                    <div className="dot dot2"></div>
                                    <div className="dot dot3"></div>
                                    <div className="dot dot4"></div>
                                    <div className="dot dot5"></div>
                                    <div className="dot dot6"></div>
                                </div>
                                <p>Error sending resume, please try again</p>
                            </div>)
                        }
                    </div>
                </div>
            )
        }
        return (
            <div className="medium-modal add-to-group-modal">
                <div className="mui-row">
                    <div className="modal-header mui-row">
                        <p>Add Resume To Group</p>
                        <i className="material-icons modal-close-icon"
                           onClick={this.props.removeModal.bind(this)}>close</i>
                    </div>
                    <div className="mui-col-md-12">
                        <div className="modal-content-container">
                            <div id="form">
                                <legend>Group Name <span className='has-error'>{this.state.errorMessage}</span></legend>
                                <GroupDropdown
                                    placeholder="Search by group name or group admin"
                                    name="groupName"
                                    onSelect={this.selectGroup.bind(this)}
                                    resumeId={this.state.share.resumeId}
                                    className="resume-suggestions-list"
                                />

                                <legend>Message</legend>
                                <textarea
                                    placeholder="Your message will be sent to the group admin"
                                    name="message"
                                    value={this.state.share.message}
                                    onChange={this.handleChange}
                                    className="form-input-margin-bottom"
                                />
                                <button onClick={this.save.bind(this)} className="btn-flat btn-accentPrimary btn-fullWidth">Share to Group</button>
                                {/*<a onClick={this.props.shareToUser}>Share to user instead</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        singleResume: state.singleResume.resume,
        isSendingToGroup: state.singleResume.isSendingToGroup,
        resumeSentToGroup: state.singleResume.resumeSentToGroup,
        resumeApprovedToGroup: state.singleResume.resumeApprovedToGroup,
        sharedToGroupSuccessfully: state.singleResume.sharedToGroupSuccessfully
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(shareActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareToGroupModal);


