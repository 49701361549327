import React from 'react';
import * as singleResumeActions from '../../actions/singleResumeActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ResumeAutosuggest from './resumeAutosuggest';
import UserAutosuggest from './userAutosuggest';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import yup from 'yup';
import Form from 'react-formal';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/lib/less/react-widgets.less';

moment.locale('en');
momentLocalizer();


const engageSchema = yup.object().shape({
    engagedToUnknown: yup.boolean(),
    engagedResumeId: yup.mixed(),
    shadchanName: yup.string(),
    engageDate: yup.date(),
    engagedToId: yup.mixed(),
    engagedToName: yup.string().when(['engagedToUnknown', 'engagedToId'], {
        is: (engagedToUnknown, engagedToId) => engagedToUnknown || engagedToId,
        then:      yup.string(),
        otherwise: yup.string().required('Required')
    }),
    engagedToEmail: yup.string().when(['engagedToName', 'engagedToId'], {
        is: (engagedToName, engagedToId) => engagedToName && !engagedToId,
        then:      yup.string().email('Invalid Email'),
        otherwise: yup.string()
    }),
    additionalInformation: yup.string()
});

class engageResumeModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            engagement: {
                shadchanName: '',
                engageDate: new Date(),
                engagedResumeId: props.resumeId,
                engagedToName: '',
                engagedToEmail: '',
                additionalInformation: '',
                engagedToUnknown: false,
                engagedToId: null,
                shadchanId: null
            },
            errors: {},
            submitting: false
        };
        this.model = this.state.engagement;
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
    }

    save(values) {
        let engagement = this.state.engagement;
        this.setState({submitting: true}, () => {
            delete engagement.engagedToUnknown;
            this.props.actions.engageResume(engagement, this.props.removeModal);
        })
    }

    handleChange(model) {
        this.model = model;
        return this.setState({engagement: model});
    }

    itemSelected(fieldName, id){
        this.setState({
            engagement: {...this.model, [fieldName] : id}
        });
    }

    unknownCheckboxClicked(){
        let engagement = this.state.engagement;
        engagement.engagedToUnknown = !engagement.engagedToUnknown;
        engagement.engagedToEmail = '';
        engagement.engagedToId = null;
        engagement.engagedToName = '';
        this.setState({ engagement, errors: {} });
    }

    render() {
        if (this.state.submitting && this.props.engagementReported) {
            return (
                <div className="medium-modal">
                    <div className="mui-row">
                        <i className="material-icons modal-close-icon"
                           onClick={this.props.removeModal.bind(this)}>close</i>
                        <div className="modal-response">
                            <div className="envelope">
                                <div className="envelope-outline">
                                    <div className="triangle"></div>
                                </div>
                                <div className="dot dot1"></div>
                                <div className="dot dot2"></div>
                                <div className="dot dot3"></div>
                                <div className="dot dot4"></div>
                                <div className="dot dot5"></div>
                                <div className="dot dot6"></div>
                            </div>
                            <p>Engagement Reported</p>
                        </div>
                    </div>
                </div>
            )
        } else if(this.state.submitting) {
            return (
                <div className="">
                    <div className='loader'></div>
                </div>
            )
        }
        return (
            <div className="medium-modal">
                <div className="mui-row">
                    <div className="mui-col-md-12">
                        <div className="modal-header mui-row">
                            <p>Mazel Tov! {this.props.resumeName} is engaged!</p>
                            <i className="material-icons modal-close-icon"
                               onClick={this.props.removeModal.bind(this)}>close</i>
                        </div>
                        <div className="modal-content-container">
                            <Form id="form"
                                  onSubmit={this.save}
                                  schema={engageSchema}
                                  errors={this.state.errors}
                                  onError={errors => this.setState({ errors })}
                                  value={this.state.engagement}
                                  onChange={this.handleChange}>
                                <div>
                                    <legend>Engagement Date<Form.Message className='has-error' for='engageDate'/></legend>
                                    <Form.Field
                                        type={DateTimePicker}
                                        name='engageDate'
                                        time={false}
                                        className="form-input-margin-bottom"
                                        format={"MMM DD YYYY"}/>
                                </div>
                                <div>
                                    <legend>Engaged To <Form.Message className='has-error' for='engagedToName'/></legend>
                                    {this.state.engagement.engagedToUnknown ? '' :
                                        <Form.Field
                                            name='engagedToName'
                                            placeholder="Enter person's name"
                                            gender={this.props.resumeGender === "Female" ? 1 : 2}
                                            itemSelected={this.itemSelected.bind(this)}
                                            type={ResumeAutosuggest}
                                            disabled={this.state.engagement.engagedToUnknown}
                                            fieldName="engagedToId"
                                        />
                                    }
                                    <div className="engaged-checkbox">
                                        <input
                                            type="checkbox"
                                            checked={this.state.engagement.engagedToUnknown}
                                            onClick={this.unknownCheckboxClicked.bind(this)}
                                        />
                                        <span>I don't know {this.props.resumeGender === "Female" ? 'his' : 'her'} name </span>
                                    </div>
                                </div>

                                {this.state.engagement.engagedToName && !this.state.engagement.engagedToId ?
                                    <div>
                                        <legend>Email Address of Engaged To Person<Form.Message className='has-error' for='engagedToEmail'/></legend>
                                        <Form.Field
                                            placeholder={`${this.state.engagement.engagedToName}'s Email`}
                                            name='engagedToEmail'
                                            className="form-input-margin-bottom"/>
                                    </div>
                                    : null
                                }

                                <div>
                                    <legend>Shadchan Name<Form.Message className='has-error' for='shadchanName'/></legend>
                                    <Form.Field
                                        name='shadchanName'
                                        placeholder="Shadchan's Name"
                                        itemSelected={this.itemSelected.bind(this)}
                                        type={UserAutosuggest}
                                        className="form-input-margin-bottom"
                                        fieldName="shadchanId"/>
                                </div>

                                <div>
                                    <legend>Additional Information<Form.Message className='has-error' for='additionalInformation'/></legend>
                                    <Form.Field
                                        placeholder="Enter any additional info"
                                        name='additionalInformation'
                                        className="form-input-margin-bottom"/>
                                </div>

                                <button className="btn-flat btn-accentPrimary btn-fullWidth">Engage</button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        engagementReported: state.singleResume.engagementReported
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(singleResumeActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(engageResumeModal);


