import React from 'react';
import UserEmailAutosuggest from '../common/userEmailAutosuggest';
import * as shareActions from '../../actions/shareActions';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import yup from 'yup';
import Form from 'react-formal';

const ideaShareSchema = yup.object().shape({
    sharedTo: yup.mixed(),
    sendHim: yup.bool(),
    sendHer: yup.bool(),
    sendBoth: yup.bool(),
    sendHimMessage: yup.string().when(['sendHim'], {
        is: sendHim=>sendHim,
        then:      yup.string().required('required'),
        otherwise: yup.string()
    }),
    sendHerMessage: yup.string().when(['sendHer'], {
        is: sendHer=>sendHer,
        then:      yup.string().required('required'),
        otherwise: yup.string()
    }),
    sendBothMessage: yup.string().when(['sendBoth'], {
        is: sendBoth=>sendBoth,
        then:      yup.string().required('required'),
        otherwise: yup.string()
    }),
    recipientEmail: yup.string().when(['sendBoth'], {
        is: sendBoth=>sendBoth,
        then:      yup.string().email('Invalid Email').required('Please choose a recipient'),
        otherwise: yup.string()
    })
});



class ideaShareModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            share: {
                sharedTo: '',
                sendHim: false,
                sendHer: false,
                sendBoth: true,
                sendHimMessage: '',
                sendHerMessage: '',
                sendBothMessage: '',
                recipientEmail: ''
            },
            errors: {},
            submitted: false
        };
    }

    sendIdea(values) {
        if(!values.sendBoth && !values.sendHim && !values.sendHer){
            return;
        }
        let idea = this.props.idea;
        let shares = [];
        if(this.state.share.sendHim && idea.herResume && idea.herResume.userId) {
            let share = {
                text: this.state.share.sendHimMessage,
                sharedTo: idea.herResume.userId,
                suggestionType: 1,
                resumes: [],
                nameShares: []
            };
            if(idea.hisResumeId){
                share.resumes.push(idea.hisResumeId);
            } else {
                share.nameShares.push({
                    name: idea.hisName,
                    gender: 1
                })
            }
            shares.push(share);
        }
        if(this.state.share.sendHer && idea.hisResume && idea.hisResume.userId) {
            let share = {
                text: this.state.share.sendHerMessage,
                sharedTo: idea.hisResume.userId,
                suggestionType: 1,
                resumes: [],
                nameShares: []
            };
            if(idea.herResumeId){
                share.resumes.push(idea.herResumeId);
            } else {
                share.nameShares.push({
                    name: idea.herName,
                    gender: 2
                })
            }
            shares.push(share);
        }
        if(this.state.share.sendBoth && this.state.share.recipientEmail) {
            let share = {
                text: this.state.share.sendBothMessage,
                suggestionType: 2,
                resumes: [idea.hisResumeId, idea.herResumeId],
                nameShares: [],
                recipientEmail: this.state.share.recipientEmail
            };

            if(!idea.hisResumeId){
                share.nameShares.push({
                    name: idea.hisName,
                    gender: 1
                })
            }
            if(!idea.herResumeId){
                share.nameShares.push({
                    name: idea.herName,
                    gender: 2
                })
            }
            shares.push(share)
        }
        this.setState({submitted: true}, ()=>{
            this.props.actions.shareIdea(shares, this.props.removeModal);
        });
    }

    messageInput(fieldName){
        return (
            <div className="clear">
                <legend>
                    Message
                    <Form.Message className='has-error' for={fieldName} />
                </legend>
                <Form.Field placeholder="Type message here"
                       name={fieldName}
                       className="subject-input"/>
            </div>
        )
    }

    render() {
        let renderThis;
        const { idea, isSending, sentSuccessfully } = this.props;
        const herName = idea.herResume ? idea.herResume.firstName : idea.herName;
        const hisName = idea.hisResume ? idea.hisResume.firstName : idea.hisName;

        if(isSending) {
            renderThis = <div className='loader'></div>
        } else if (this.state.submitted) {
            renderThis =
                <div>
                    <i className="material-icons modal-close-icon"
                       onClick={this.props.removeModal.bind(this)}>close</i>
                    <div>
                        {isSending ? 'Sending...' :
                            <div>
                            {sentSuccessfully ? 'Idea sent' : 'Something went wrong when trying to send that, please try again'}
                            </div>
                        }

                    </div>
                </div>
        } else {
            renderThis =
                <div className="large-modal">
                    <div className="modal-header mui-row">
                        <div className="modal-header-left">
                            <p>Share Idea</p>
                            <p className="btn-modal" onClick={this.props.goBack}>
                                <FontAwesome name='arrow-left' /> Go back
                            </p>
                        </div>
                        <i className="material-icons modal-close-icon"
                           onClick={this.props.removeModal.bind(this)}>close</i>
                    </div>
                    <div className="mui-col-md-12">
                        <div className="modal-content-container">
                            <Form id="form" schema={ideaShareSchema}
                                  value={this.state.share}
                                  errors={this.state.errors}
                                  onError={errors=>this.setState({errors})}
                                  onChange={share=>this.setState({share})}
                                  onSubmit={this.sendIdea.bind(this)}>
                                <div className="radio-btns">
                                    {idea.herResume && idea.herResume.userId ?
                                        <div className="clearfix">
                                            {/*<div className="share-modal-toggle">*/}
                                                {/*<Form.Field*/}
                                                    {/*className="cbx hidden"*/}
                                                    {/*name="sendHim"*/}
                                                    {/*id="option1"*/}
                                                {/*/>*/}
                                                {/*<label className="lbl" htmlFor="option1"></label><br/>*/}
                                            {/*</div>*/}
                                            <Form.Field
                                                className="cbx legend-cbx"
                                                name="sendHim"
                                                id="option1"
                                            />
                                            <legend className="share-modal-option">
                                                Suggest {hisName} to {herName}
                                            </legend>
                                            {this.state.share.sendHim ? this.messageInput("sendHimMessage") : null}
                                            <div className="share-option-divider"></div>
                                        </div>
                                        : null }

                                    {idea.hisResume && idea.hisResume.userId ?
                                        <div className="clearfix">
                                            {/*<div className="share-modal-toggle">*/}
                                                {/*<Form.Field*/}
                                                    {/*className="cbx hidden"*/}
                                                    {/*name="sendHer"*/}
                                                    {/*id="option2" />*/}
                                                {/*<label className="lbl" htmlFor="option2"></label><br/>*/}
                                            {/*</div>*/}
                                            <Form.Field
                                                className="cbx legend-cbx"
                                                name="sendHer"
                                                id="option2"
                                            />
                                            {/*<label className="lbl" htmlFor="option2"></label><br/>*/}
                                            <legend className="share-modal-option">
                                                Suggest {herName} to {hisName}
                                            </legend>
                                            {this.state.share.sendHer ? this.messageInput("sendHerMessage") : null}
                                            <div className="share-option-divider"></div>
                                        </div>
                                        : null }

                                    <div className="clearfix">
                                    {/*<div className="share-modal-toggle">*/}
                                        {/*<Form.Field*/}
                                            {/*className="cbx hidden"*/}
                                            {/*name="sendBoth"*/}
                                            {/*id="option3"/>*/}
                                        {/*<label className="lbl" htmlFor="option3"></label>*/}
                                    {/*</div>*/}
                                        <Form.Field
                                            className="cbx legend-cbx"
                                            name="sendBoth"
                                            id="option3"
                                        />
                                    <legend className="share-modal-option">
                                        Send {hisName} and {herName}
                                        <Form.Message className="has-error share-modal-error" for="recipientEmail"/>
                                    </legend>
                                    <Form.Field
                                       placeholder="Recipient Email"
                                       type={UserEmailAutosuggest}
                                       name="recipientEmail"
                                       itemSelected={stuff=>console.log(stuff)}
                                       className="inline suggest-name-input"/>
                                    {this.state.share.sendBoth ? this.messageInput("sendBothMessage") : null}
                                    <div className="share-option-divider"></div>
                                    </div>
                                </div>

                                {!this.state.share.sendHim && !this.state.share.sendHer && !this.state.share.sendBoth ?
                                    <Form.Button type="submit" disabled="true" className="btn-flat btn-accentPrimary btn-fullWidth">
                                        Send
                                    </Form.Button>
                                    :
                                    <Form.Button type="submit" className="btn-flat btn-accentPrimary btn-fullWidth">
                                        Send
                                    </Form.Button>
                                }
                            </Form>
                        </div>
                    </div>
                </div>
        }
        return (
            <div className="idea-modal">
                <div className="mui-row">
                    {renderThis}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        isSending: state.ideas.isSending,
        sentSuccessfully: state.ideas.sentSuccessfully
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(shareActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ideaShareModal);


