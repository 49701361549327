import * as types from '../actionTypes/welcomeActionTypes';
import { apiAuthRequest } from './helpers';

export function loadWelcome() {
	return async dispatch => {
		let result = await apiAuthRequest('get', '/welcome/getByUser');
		if(result.success) {
			dispatch({
                type: types.LOAD_WELCOME_SUCCESS,
                steps: result.data
            });
		}
	}
}

export function addWelcome(welcome) {
	return async dispatch => {
		let result = await apiAuthRequest('post', '/welcome/addWelcome', {type: welcome});
		if(result.success) {
			dispatch({
                type: types.ADD_WELCOME_SUCCESS,
                step: result.data
            });
		}
	}
}
