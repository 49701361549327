import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getNextUrlFromParams } from '../common/utils';

class LoadingPage extends React.Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount () {
        this.checkAuth(this.props);
    }

    componentWillReceiveProps (nextProps) {
        this.checkAuth(nextProps);
    }

    checkAuth (props) {
        let nextRoute = getNextUrlFromParams(props.location.search);
        if (props.user.isAuthenticated) {
            this.context.router.history.push(nextRoute ? '/' + nextRoute : '/resumes');
        } else if(!props.user.isLoading){
            this.context.router.history.push(`/login${nextRoute ? `?next=${nextRoute}` : ''}`);
        }
    }


    render() {
        return (
            <div className="page-account-container">
                <div className="loader"></div>
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    return {
        user: state.user
    }
}


export default connect(mapStateToProps)(LoadingPage);
