import createHistory from 'history/createBrowserHistory';
import { gAnalyticsKey } from './config';

let history = createHistory();

history.listen(function (location) {
    if(!location.pathname.includes('loading') && window.gtag && process.env.NODE_ENV === 'production') {
        window.gtag('config', gAnalyticsKey, {'page_path': location.pathname + location.search});
    }
});

export default history;
