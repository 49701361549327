import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../header/Header';
import { Helmet } from "react-helmet";

export const requireAuthentication = (Component) => {


    class AuthenticatedComponent extends React.Component {
        static contextTypes = {
            router: PropTypes.object
        };

        constructor(props) {
            super(props);
        }

        componentWillMount () {
            this.checkAuth(this.props.user.isAuthenticated);
        }

        componentWillReceiveProps (nextProps) {
            this.checkAuth(nextProps.user.isAuthenticated);
        }

        checkAuth (isAuthenticated) {
            if (!isAuthenticated) {
                let path = this.props.location.pathname;
                let redirectAfterLogin = path.substr(0,1) === "/"  ? path.substr(1) : path;
                this.context.router.history.push(`/loading${redirectAfterLogin ? `?next=${redirectAfterLogin}` : ''}`);
            }
        }

        render () {
            return (
                <div>
                    {this.props.user.isAuthenticated === true
                        ?
                        <div className="main-app">
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>ZivugTech</title>
                            </Helmet>
                            <Header {...this.props}/>
                            <Component {...this.props}/>
                        </div>
                        : null
                    }
                </div>
            )

        }
    }

    const mapStateToProps = (state) => ({
        user: state.user
    });

    return connect(mapStateToProps)(AuthenticatedComponent);

};