export const USER_LOGIN_START = 'USER_LOGIN_START';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const REGISTER_USER_START = 'REGISTER_USER_START';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const REGISTER_USER_DONE = 'REGISTER_USER_DONE';
export const USER_NOT_LOGGED_IN = 'USER_NOT_LOGGED_IN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const CONFIRM_USER_FAIL = 'CONFIRM_USER_FAIL';
export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS';
export const RESEND_CONFIRMATION_SUCCESS = 'RESEND_CONFIRMATION_SUCCESS';
export const ACCOUNT_SETTINGS_SAVED = 'ACCOUNT_SETTINGS_SAVED';
export const ACCOUNT_SETTINGS_ERROR = 'ACCOUNT_SETTINGS_ERROR';
export const SECURITY_SETTINGS_SAVED = 'SECURITY_SETTINGS_SAVED';
export const SECURITY_SETTINGS_ERROR = 'SECURITY_SETTINGS_ERROR';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_START = 'INVITE_USER_START';
export const ADD_USER_EMAIL_SUCCESS = 'ADD_USER_EMAIL_SUCCESS';
export const ADD_USER_EMAIL_FAIL = 'ADD_USER_EMAIL_FAIL';
export const REMOVE_USER_EMAIL_SUCCESS = 'REMOVE_USER_EMAIL_SUCCESS';
export const REMOVE_USER_EMAIL_FAIL = 'REMOVE_USER_EMAIL_FAIL';
export const RESEND_EMAIL_VERIFICATION_SUCCESS = 'RESEND_EMAIL_VERIFICATION_SUCCESS';
export const RESEND_EMAIL_VERIFICATION_FAIL = 'RESEND_EMAIL_VERIFICATION_FAIL';
export const UPLOAD_USER_ID_SUCCESS = 'UPLOAD_USER_ID_SUCCESS';
export const FLAG_USER = 'FLAG_USER';
export const UNFLAG_USER = 'UNFLAG_USER';