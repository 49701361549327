import React from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import isMobile from 'ismobilejs';
import { apiAuthRequest } from '../../actions/helpers';
import PrivateImage from './privateImage';


function getSuggestionValue(suggestion) {
    return `${suggestion.firstName} ${suggestion.lastName}`;
}
function renderSuggestion(resume) {
       return (
        <div key={resume.id} className="autosuggest-list-item">
            <PrivateImage
                fileName={resume.images.length ? resume.images[0].fileLocation : ''}
                id={resume.id}
                section='resumeSmall'
                className='autosuggest-list-item-image'
            />
            <div className="autosuggest-list-item-info autosuggest-column-large">
                <h5 className="autosuggest-list-item-title">{resume.firstName} {resume.lastName}</h5>
                <p className="autosuggest-list-item-info-details">
                    <span>{resume.age}</span>
                    <span>{resume.height}</span>
                    <span>{resume.maritalStatus}</span>
                </p>
                <p className="autosuggest-list-item-info-types">
                    {resume.types ? resume.types.map((t, index) => this.resumeTypeRow(t, resume.types.length, index)) : null}
                </p>
            </div>
        </div>
    )
}

function resumeTypeRow(type, length, index){
    let typeText;
    if(index < length - 1) {
        typeText = type.type.text + ", ";
    } else {
        typeText = type.type.text;
    }
    return (
        <span key={type.id}>{typeText}</span>
    )
}


export default class resumeAutosuggest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value || '',
            suggestions: [],
            isLoading: false
        };
        this.name = this.props.name;
        this.cancelLastRequest = null;
    }

    loadSuggestions(value) {
        this.setState({value});

        if(value.length < 2){
            return;
        }

        // Cancel the previous request
        if (this.cancelLastRequest) {
            this.cancelLastRequest();
        }

        this.setState({
            isLoading: true
        });

        let CancelToken = axios.CancelToken;
        let inside = this;
        let params = {query: value, gender: this.props.gender};
        let cancelToken = new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            inside.cancelLastRequest = c;
        });
        apiAuthRequest('get', '/resumes/autocomplete', params, cancelToken).then(result => {
            if (result.success) {
                inside.setState({
                    isLoading: false,
                    suggestions: result.data
                })
            } else if(!result.cancelled){
                inside.setState({
                    isLoading: false,
                    suggestions: []
                })
            }
        });
    }

    onChange = (event, { newValue}) => {
        this.props.onChange(newValue);
        if(!newValue){
            this.props.itemSelected(this.props.fieldName, null);
        }
        this.setState({ value: newValue });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.loadSuggestions(value);
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = (event, {suggestion}) => {
        if (event && event.key === 'Enter') {
            // might be in a form, so prevent this enter keystroke from submitting the form.
            event.preventDefault();
        }
        let fieldName = this.props.fieldName;
        this.props.itemSelected(fieldName, suggestion.id);
    };

    render() {
        const { value, suggestions, isLoading } = this.state;
        const inputProps = {
            placeholder: this.props.placeholder,
            value,
            onBlur: this.props.onBlur,
            onChange: this.onChange,
            name: this.props.name,
            id: this.props.gender || 3,
            disabled: this.props.disabled,
            onFocus: ()=> this.loadSuggestions(value)
        };

        return (
            <div className="container autosuggest-list">
                <Autosuggest
                    suggestions={suggestions}
                    focusInputOnSuggestionClick={!isMobile.any}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    onSuggestionSelected={this.onSuggestionSelected.bind(this)}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />
            </div>
        );
    }
}
