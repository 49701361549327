import * as types from '../actionTypes/tagsActionTypes';
import { apiAuthRequest } from './helpers';
import { toastr } from 'react-redux-toastr';


export function loadTags() {
	return async dispatch => {
		let result = await apiAuthRequest('get', '/userTags/getAll');
		if(result.success){
			dispatch({
                type: types.LOAD_TAGS_SUCCESS,
                tags: result.data
            });
		} else {
            console.error(result.data);
            toastr.error('Something went wrong, please try refreshing the page');
		}
	}
}

export function createTag(tag) {
	return async dispatch => {
		let result = await apiAuthRequest('post', '/userTags/create', {tagText: tag});
		if(result.success){
			dispatch({
                type: types.CREATE_TAG_SUCCESS,
                tag: result.data
            });
		} else {
            console.error(result.data);
            toastr.error('Error creating tag');
        }
	}
}





