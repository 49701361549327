import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userTagActions from "../../actions/userTagActions";
import * as singleResumeActions from "../../actions/singleResumeActions";
import FontAwesome from "react-fontawesome";
import SidebarConversations from "./sidebarComponents/sidebarConversations";
import SidebarIdeas from "./sidebarComponents/sidebarIdeas";
import SidebarGroups from "./sidebarComponents/sidebarGroups";
import Textarea from "react-textarea-autosize";
import Popup from "../common/Popup";
import MultiSelect from "react-widgets/lib/Multiselect";
import "react-widgets/lib/less/react-widgets.less";

import { Scrollbars } from "react-custom-scrollbars";

class ResumeRightPanel extends React.Component {
  static contextTypes = {
    objectHeights: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      note: props.resume.note || {},
      noteLastSaved: null,
      activeTab: 1,
      height: 0,
    };
    this.saveNote = this.saveNote.bind(this);
  }

  componentWillReceiveProps(props) {
    console.log("props.resume.note", props.resume.note);
    if (props.resume.id !== this.props.resume.id) {
      this.setState({ note: props.resume.note });
    } else if (
      props.resume.note &&
      props.resume.note.id !== this.state.note.id
    ) {
      let note = this.state.note;
      note.id = props.resume.note.id;
      this.setState({ note });
    }
  }

  toggleSidebar() {
    this.props.toggleSidebar();
  }

  tagsChanged(userTags) {
    let resume = this.props.resume;
    if (resume.userTags.length < userTags.length) {
      this.props.singleResumeActions.addTagToResume(
        {
          resumeId: resume.id,
          tagId: userTags[resume.userTags.length].id,
        },
        userTags[resume.userTags.length]
      );
    } else if (resume.userTags.length > userTags.length) {
      let removedTag = resume.userTags.find(
        (t) => t && !userTags.find((u) => u.id === t.id)
      );
      this.props.singleResumeActions.removeTagFromResume({
        resumeId: resume.id,
        tagId: removedTag.id,
      });
    }
  }

  addNewTag(tagText) {
    this.props.singleResumeActions.addNewTagToResume({
      resumeId: this.props.resume.id,
      tagText,
    });
  }

  noteTextChanged(e) {
    let note = this.state.note;
    note.noteText = e.target.value;
    this.setState({ note });

    let tenSecondsAgo = new Date();
    tenSecondsAgo.setSeconds(tenSecondsAgo.getSeconds() - 10);

    if (
      this.state.noteLastSaved < tenSecondsAgo &&
      this.props.resume.note.noteText !== this.state.note.noteText
    ) {
      this.setState({ noteLastSaved: new Date() }, function () {
        this.saveNote();
      });
    }
  }

  saveNote() {
    if (this.state.note.id) {
      this.props.singleResumeActions.editResumeNote(this.state.note);
    } else if (this.state.note.noteText) {
      this.state.note.resumeId = this.props.resume.id;
      this.props.singleResumeActions.addResumeNote(this.state.note);
    }
  }

  changeActiveTab(tabId) {
    if (this.refs.tabs) {
      this.setState({ activeTab: tabId });
      this.props.unSelectConversation();
    }
  }

  render() {
    let { resume } = this.props;

    let openArrow;
    if (!this.props.showSidebar) {
      openArrow = (
        <span
          className="sidebar-content-arrow"
          onClick={this.toggleSidebar.bind(this)}
        >
          <FontAwesome name="arrow-left" className="open-sidebar" />
        </span>
      );
    } else {
      openArrow = (
        <span
          className="sidebar-content-arrow"
          onClick={this.toggleSidebar.bind(this)}
        >
          <FontAwesome name="arrow-right" className="close-sidebar" />
        </span>
      );
    }

    let sidebarClass = this.props.showSidebar
      ? "header-padding active"
      : "header-padding";

    return (
      <div
        id="sidebar-content"
        className={`${sidebarClass} ${
          this.props.displayMobile ? "" : "mobile-hide"
        }`}
        ref="resumeSidebar"
      >
        {openArrow}

        <div id="sidebar-content-content">
          <div className="panel-actions panel-action-fullwidth" ref="tabs">
            <div
              className={
                this.state.activeTab === 1
                  ? "panel-action active"
                  : "panel-action"
              }
              onClick={this.changeActiveTab.bind(this, 1)}
            >
              <p>Info</p>
            </div>
            <div
              className={
                this.state.activeTab === 2
                  ? "panel-action active"
                  : "panel-action"
              }
              onClick={this.changeActiveTab.bind(this, 2)}
            >
              <p>Ideas ({resume.ideas.length})</p>
            </div>
            <div
              className={
                this.state.activeTab === 3
                  ? "panel-action active"
                  : "panel-action"
              }
              onClick={this.changeActiveTab.bind(this, 3)}
            >
              <p>Shares</p>
            </div>
          </div>
          <Scrollbars
            style={{
              width: 350,
              height: this.context.objectHeights.mainPanelCalculatedHeight,
            }}
          >
            <div className="content-padding">
              <div className="container">
                <div
                  className={
                    this.state.activeTab === 1
                      ? "sidebar-tab active"
                      : "sidebar-tab"
                  }
                >
                  <div
                    className="sidebar-content-section sidebar-resume-shares"
                    onClick={this.changeActiveTab.bind(this, 3)}
                  >
                    <p>
                      You shared this resume{" "}
                      <span className="sidebar-resume-shares-number">
                        {resume.shareCount}
                      </span>{" "}
                      times
                    </p>
                  </div>

                  <div className="sidebar-content-section">
                    <h3 className="headerText-with-icon">Note</h3>
                    <Popup
                      trigger={
                        <FontAwesome
                          name="lock"
                          className="headerText-with-icon-icon"
                        />
                      }
                      content="Notes are private to you"
                    />
                    {this.state.note && (
                      <div>
                        <Textarea
                          value={this.state.note.noteText || ""}
                          onChange={this.noteTextChanged.bind(this)}
                          onBlur={this.saveNote.bind(this)}
                          placeholder="Type a note"
                          name="noteText"
                          className="form-textarea-note"
                        />
                        {this.props.isSavingNote ? (
                          <p className="text-autosaving">autosaving...</p>
                        ) : null}
                      </div>
                    )}
                  </div>

                  <div className="sidebar-content-section">
                    <h3>Tags</h3>
                    <div className="tags">
                      <MultiSelect
                        placeholder="Add tags"
                        data={this.props.userTags}
                        value={resume.userTags}
                        onChange={this.tagsChanged.bind(this)}
                        onCreate={this.addNewTag.bind(this)}
                        minLength={0}
                        textField="tagText"
                        valueField="id"
                        filter="contains"
                      />
                    </div>
                  </div>

                  <SidebarGroups
                    resume={resume}
                    saveGroupNote={
                      this.props.singleResumeActions.editGroupResumeNote
                    }
                    setModal={this.props.setModal}
                  />
                </div>
                <div
                  className={
                    this.state.activeTab === 2
                      ? "sidebar-tab active"
                      : "sidebar-tab"
                  }
                >
                  <SidebarIdeas resume={resume} />
                </div>
                <div
                  className={
                    this.state.activeTab === 3
                      ? "sidebar-tab active"
                      : "sidebar-tab"
                  }
                >
                  <SidebarConversations
                    resume={resume}
                    selectConversation={this.props.selectConversation}
                    unSelectConversation={this.props.unSelectConversation}
                    selectedConversation={this.props.selectedConversation}
                    loadSingleResume={this.props.loadSingleResume.bind(this)}
                  />
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userTagActions, dispatch),
    singleResumeActions: bindActionCreators(singleResumeActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userTags: state.userTags,
    isSavingNote: state.singleResume.isSavingNote,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResumeRightPanel);
