import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { apiRequest } from '../../../actions/helpers';

export default class ConfirmUser extends React.Component {
    static contextTypes = {
        router: PropTypes.object
    };

	constructor(props){
		super(props);
		this.state = {
			confirmed: false
		}
	}

	componentDidMount() {
		this.verifyEmail(this.props.match.params);//token, emailId

	}

	verifyEmail(params){
		let inside = this;
		apiRequest('post', '/users/verifyEmail', params)
			.then(response => {
				if(response.success && response.data.verified){
					inside.setState({confirmed: true});
					setTimeout(()=>this.context.router.history.push('/accountSettings'), 3000)
				} else {
					inside.setState({error: response.data})
				}

			})
	}

	render() {
		if(!this.state.confirmed && !this.state.error) {
			return (
				<div className="loader"></div>
			)
		}
		return (
			<div className="page-account page-login">
				<Helmet>
					<title>Confirm Email | ZivugTech</title>
				</Helmet>
				<div className="page-account-container-large">
					<Link to={'/login'}>
						<img src="/images/logo.png" alt="" className="page-account-logo"/>
					</Link>

					<div id="form" className="page-account-content mui--z1">
						{this.state.confirmed ? <h3 className="text-align-center">Email Confirmed!</h3> : null }
						{this.state.error ?
							<div>
								<h3 className="text-align-center">{this.state.error}</h3>
								<button className="btn-flat btn-primaryAccent btn-loader text-align-center">
									<Link to={`/accountSettings`}>Back to account settings</Link>
								</button>
							</div>
						: null }
					</div>
				</div>
			</div>
		)

	}
}
