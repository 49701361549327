import React from 'react';
import { Link } from 'react-router-dom';
import yup from 'yup';
import Form from 'react-formal';
import { Helmet } from "react-helmet";
import { apiRequest } from '../../../actions/helpers';

const resetPasswordSchema = yup.object().shape({
	password: yup.string().required('Required').min(6, 'Password is too short'),
	confirmPassword: yup.mixed().test('match', 'Passwords do not match', function (confirmPassword) {
		return confirmPassword === this.parent.password
	})
});

export default class NewPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			token: props.match.params.token,
			newPassword: {
				password: '',
				confirmPassword: ''
			},
			checkingToken: true,
			tokenValid: true,
			submitting: false,
			passwordResetSuccess: false,
			errorResettingPassword: false
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount(){
		this.checkToken();
	}

	async checkToken() {
		let result = await apiRequest('post', '/users/checkPasswordResetToken', {token: this.state.token});
		this.setState({tokenValid: (result.data.tokenValid), checkingToken: false});
	}

	async handleSubmit(values) {
		this.setState({submitting: true});
		let params = {...values, token: this.state.token};
		let result = await apiRequest('post', '/users/saveNewPassword', params);
		if(result.success) {
			this.setState({passwordResetSuccess: true, submitting: false, message: "Password reset successfully!"});
		} else {
			this.setState({errorResettingPassword: true, submitting: false, message: "Something went wrong - please contact support"})
		}
	}


	render() {
		if(this.state.checkingToken){
			return <div className="loader"></div>
		}
		if(this.state.passwordResetSuccess){
			return (
				<div className="page-account page-login">
					<div className="page-account-container-large">
					<img src="/images/logo.png" alt="" className="page-account-logo"/>
						<div id="form" className="page-account-content mui--z1">
							<h3 className="text-align-center">Password reset successfully!</h3>
							<button className="btn-flat btn-primaryAccent btn-loader text-align-center">
								<Link to={`/login`}>Login</Link>
							</button>
						</div>
					</div>
				</div>
			)
		}

		if(this.state.errorResettingPassword || !this.state.tokenValid){
			return (
				<div className="page-account page-login">
					<div className="page-account-container-large">
						<img src="/images/logo.png" alt="" className="page-account-logo"/>
						<div id="form" className="page-account-content mui--z1">
							<h3 className="text-align-center">{this.state.message || 'Looks like that link is broken or expired'}</h3>
							<button className="btn-flat btn-primaryAccent btn-loader text-align-center">
								<Link to={`/forgotPassword`}>Click here to get a new link</Link>
							</button>
						</div>
					</div>
				</div>
			)
		}
		
		return (
		<div className="page-account page-login">
			<Helmet>
				<title>Reset Password | ZivugTech</title>
			</Helmet>
			<div className="page-account-container-large">
				<Link to={'/login'}>
					<img src="/images/logo.png" alt="" className="page-account-logo"/>
				</Link>

				<Form onSubmit={this.handleSubmit}
						  id="form"
						  schema={resetPasswordSchema}
						  value={this.state.newPassword}
						  onChange={model=>this.setState({newPassword: model})}
						  className="page-account-content mui--z1">

						<h3 className="login-box-title">Reset password</h3>
						<div className="container">
							<div className="form-inline-half">
								<legend>New password
									<Form.Message className='has-error' for='password'/>
								</legend>
								<Form.Field
									placeholder="Password"
									type='password'
									name='password'
									alsoValidates={this.state.newPassword.confirmPassword ? ['confirmPassword']: []}
									className="form-input-margin-bottom"/>
							</div>
							<div className="form-inline-half">
								<legend>Confirm password
									<Form.Message className='has-error' for='confirmPassword'/>
								</legend>
								<Form.Field
									placeholder="Confirm Password"
									type='password'
									name='confirmPassword'
									className="form-input-margin-bottom"/>
							</div>
						</div>

						{this.state.submitting ?
							<div className="btn-flat btn-primaryAccent text-align-center btn-loader">
								<div className="loader-small"></div>
							</div>
							:
							<Form.Button
								type="submit"
								className="btn-flat btn-primaryAccent btn-loader text-align-center">
								Save New Password
							</Form.Button>
						}
					</Form>

				</div>
			</div>
		)
	}
}