import React from 'react';
import moment from 'moment-timezone';
import Popup from '../common/Popup';
import FontAwesome from 'react-fontawesome';

export default class SingleConversation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			messageText: '',
			participantName: props.participantName,
			messageCounter: 0,
			replyFocused: props.replyFocused,
			viewAllMessages: false,
			errorMessageEmpty: false,
            firstMessageToDisplay: null,
			firstNewMessage: null
		};
		this.timezone = moment.tz.guess();
	}

	componentWillReceiveProps(nextProps){
		this.setConversationData(nextProps.conversation);

        if(nextProps.replyFocused) {
            this.setState({replyFocused:true});
            this.refs.messageReplyArea.focus();
        }
	}

	componentDidMount(){
        this.setConversationData(this.props.conversation);
    }

	setConversationData(convo){
        if(convo) {
            let lastReceivedMessage = 0;
            let firstNewMessage = null;

            if(convo.messages) {
                for (let i = convo.messages.length - 3; i >= 0; i--) {
                    //start from the second to last message and find the previous message received
                    if (!convo.messages[i].isMine) {
                        lastReceivedMessage = i;
                        break;
                    }
                }

                for (let i = 0; i < convo.messages.length; i++) {
                    //look for the first unread message
                    if (!convo.messages[i].isMine && convo.lastViewedByMe < new Date(convo.messages[i].dateSent)) {
                        firstNewMessage = convo.messages[i].id;
                        break;
                    }
                }
            }

            this.setState({
                participantName: convo.participantName,
                firstMessageToDisplay: lastReceivedMessage,
                firstNewMessage: firstNewMessage
            });
        }
	}

	viewAll(){
		this.setState({viewAllMessages: true});
	}

	createMessage(){
		if(this.refs.replyBox){
			this.setState({replyFocused:true});
		}
	}

	cancelFocus(){
		if(this.state.errorMessageEmpty) {
			this.setState({errorMessageEmpty: false})
		}

		if(this.refs.replyBox) {
			this.setState({replyFocused: false});
		}

		if(this.props.unfocusReply){
            this.props.unfocusReply();
        }
	}

	messageTextChanged(e){
		if(this.state.errorMessageEmpty) {
			this.setState({errorMessageEmpty: false})
		}
		this.setState({messageText: e.target.value});
	}

	sendMessage(){
		if(!this.state.messageText) {
			this.setState({errorMessageEmpty: true});
			return;
		}

		if(this.state.messageText){
			let message = {
				conversationId: this.props.conversation.id,
				text: this.state.messageText
			};
			this.setState({messageText: '', replyFocused: false});
			this.props.sendMessage(message);
		}
	}

	messagesRow(message) {
		let date = moment.tz(message.dateSent, this.timezone).fromNow();
		return (
			<div key={message.id}>
				{message.id === this.state.firstNewMessage ?
					<div className="inbox-new-messages-container">
						<div className="inbox-new-messages">
							<p>new messages</p>
						</div>
					</div>
					: null
				}
				<div className={message.isMine ? 'inbox-message inbox-message-mine' : 'inbox-message'}>
					<div className="mui-row">
						<div className="mui-col-md-12">
							{message.isMine ?
								'' :
								<p className="message-sender convo-participant">{this.state.participantName}</p>
							}
						</div>
						<div className="mui-col-md-12">
							<p className="inbox-message-content">{message.text}</p>
						</div>
						<div className="mui-col-md-12">
							<p className="time-received">{date}</p>
						</div>
					</div>
					<div className="mui-row">

					</div>
				</div>
			</div>
		);
	}

	render() {
		if(!this.props.conversation || !this.props.conversation.id){
			return <div className="loader"></div>
		}

		let convo = this.props.conversation;
		let attachmentCount = convo.resumes.length + convo.nameShares.length;
		return (
		    <div className="inbox-container-max">
				{convo.system ?
					<div className="inbox-header content-padding">
						<div className="demo-content mui-row">
							<div className="mui-col-md-12">
								This is a demo conversation from {convo.participantName}
							</div>
						</div>
					</div>
					: null
				}
		        <div className="inbox-header content-padding">
					<div className="inbox-header-content mui-row">
			            <div className="mui-col-md-12">
			            	<p className="inbox-header-suggestion">
								{convo.suggestionType}
							</p>
			            </div>
			            <div className="inbox-header-resumes mui-col-md-12">
							{convo.resumes.length || convo.nameShares.length?
								<div>
									<p className="inbox-header-resumes-count"><span>{attachmentCount}</span> resume{attachmentCount > 1 ? 's': ''} included</p>
									{this.props.isInbox ?
										!this.props.sidebarOpen ?
											<Popup
												trigger={
													<div onClick={this.props.showSidebar} className="btn-viewResumes">
														<p>View</p>
													</div>
												}
												content="Resumes included in a message will show on the right 'Resumes' sidebar. Click the attached resume and the resume will open up in the sidebar. "
											/>
											: null
										:
										<div>
											{convo.resumes.map(r=>{
												return (
													<div key={r.id} onClick={this.props.loadSingleResume.bind(null, r.resumeId)} className="btn-flat btn-secondary attached-resume-btn">
														<i className="material-icons attached-resume">attach_file</i>
														<p>{r.resume.firstName} {r.resume.lastName}</p>
													</div>
													)
												})
											}
											{convo.nameShares.map(r=>{
												return (
														<div key={r.id} className="btn-flat btn-secondary">
															<p>{r.name}</p>
														</div>
													)
												})
											}
										</div>
									}
								</div>
							: null }
			            </div>
					</div>
		        </div>
		        <div className="content-padding">
					<div className="inbox-messages">

						{!this.state.viewAllMessages && convo.messages.length > 2 && this.state.firstMessageToDisplay ?
							<div className="inbox-messages-older">
								<p onClick={this.viewAll.bind(this)}>
									View<span className="circle">{this.state.firstMessageToDisplay }</span> older message{this.state.firstMessageToDisplay > 1 ? <span>s</span> : null} <FontAwesome name='arrow-right' />
								</p>
							</div>
							: null
						}
		            	{convo.messages.map((m, i) => {
							if(this.state.viewAllMessages || i >= this.state.firstMessageToDisplay){
								return this.messagesRow(m);
							}
						})}
		            </div>
		        </div>
	            <div className="message-reply" ref="replyBox">
					<div className="content-padding" >
						{this.state.replyFocused ?
						<div className="message-reply-header">
							<p>Replying to <span className="message-reply-header-name">{convo.participantName}</span></p>
						</div> : null }

						<textarea
							ref="messageReplyArea"
							value={this.state.messageText}
							placeholder='Type to reply...'
							onFocus={this.createMessage.bind(this)}
							onChange={this.messageTextChanged.bind(this)}
							className="form-textarea">
						</textarea>
						{this.state.errorMessageEmpty ? <span className="form-error-message">Please enter a message...</span> : ''}

						{this.state.replyFocused ?
							<div className="message-reply-footer">
								<button onClick={this.cancelFocus.bind(this)} className="btn-flat btn-reset">Cancel</button>
								<button onClick={this.sendMessage.bind(this)} className="btn-flat btn-success">Send</button>
							</div> : null }
					</div>
	            </div>
		    </div>
		)
	}
}


