import React from 'react';
import { connect } from 'react-redux';
import * as shareActions from '../../../actions/shareActions';
import ResumeAutosuggest from '../../common/resumeAutosuggest';
import UserEmailAutosuggest from '../../common/userEmailAutosuggest';
import { bindActionCreators } from 'redux';
import FontAwesome from 'react-fontawesome';
import yup from 'yup';
import Form from 'react-formal';
import SelectList from 'react-widgets/lib/SelectList';
import resizableTextArea from '../../common/resizableTextArea';
import Popup from '../../common/Popup';

const shareSchema = yup.object().shape({
	ideaName: yup.string().when(['suggestionType'], {
		is: (suggestionType) => suggestionType === 2,
		then:      yup.string().required('required'),
		otherwise: yup.string()
	}),
	recipientEmail: yup.string().email('Invalid Email').required('Please choose a recipient'),
	picture: yup.boolean(),
	text: yup.string().required('Message is required'),
	suggestionType: yup.string().required('Please choose a suggestion type')
});

class shareToUserModal extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			share: {
				recipientEmail: '',
				sharedTo: null,
				suggestionType: '',
				text: '',
				picture: false,
				ideaName: ''
			},
			recipientUserId: null,
			ideaResumeId: null,
			submitted: false,
			currentResume: props.currentResume || props.singleResume,
			oppositeGender: props.currentResume ? props.genderConstants.find(g=>g.id !== props.currentResume.gender) :
				props.genderConstants.find(g=>g.text !== props.singleResume.gender)
		};
	}

	async send(values) {
		this.setState({submitted: true});
		let share = {
			recipientEmail: values.recipientEmail,
			sharedTo: this.state.recipientUserId,
			suggestionType: values.suggestionType,
			text: values.text,
			picture: values.picture,
			resumes: [this.state.currentResume.id],
			nameShares: []
		};

		if(this.state.ideaResumeId){
			share.resumes.push(this.state.ideaResumeId);
		} else if(values.ideaName){
			share.nameShares.push({
				name: values.ideaName,
				gender: this.state.oppositeGender.id
			})
		}
		this.props.actions.shareResume(share, this.props.removeModal);
	}

	handleChange(model) {
		if(model.suggestionType !== this.state.share.suggestionType || model.ideaName !== this.state.share.ideaName){
			model.text = this.getDefaultMessage(model.suggestionType, model.ideaName);
		}
		this.setState({share: model});
	}

	getDefaultMessage(suggestionType, ideaName){
		let message = '';
		let resumeName = `${this.state.currentResume.firstName} ${this.state.currentResume.lastName}`;
		switch (suggestionType){
			case 1:
				message = `Hi, please get back to me regarding this suggestion - ${resumeName}`;
				break;
			case 2:
				message = `Hi, what do you think of ${resumeName} as an idea for ${ideaName || `[Choose one]`}`;
				break;
			case 3:
				message = `Hi, Do you have any ideas for ${resumeName}?`
		}
		return message;
	}

	itemSelected(fieldName, id){
		this.setState({[fieldName]: id});
	}

	render() {
		let {share} = this.state;
		let thisResume = this.state.currentResume;

		if(this.state.submitted && this.props.isSharingResume) {
			return (
				<div className='loader'></div>
			)
		}  else if (this.state.submitted && this.props.resumeSentToUser) {
			return (
				<div className="mui-container-fluid">
					<div className="mui-row">
						<i className="material-icons modal-close-icon"
						   onClick={this.props.removeModal.bind(this)}>close</i>
						<div>
							{this.props.sentToUserSuccessfully ?
								(<div className="modal-response">
									<div className="envelope">
										<div className="envelope-outline">
											<div className="triangle"></div>
										</div>
										<div className="dot dot1"></div>
										<div className="dot dot2"></div>
										<div className="dot dot3"></div>
										<div className="dot dot4"></div>
										<div className="dot dot5"></div>
										<div className="dot dot6"></div>
									</div>
									<p>Resume Sent</p>
								</div>)
								:
								(<div className="modal-response">
									<div className="envelope2">
										<div className="envelope-outline">
											<div className="triangle"></div>
										</div>
										<div className="dot dot1"></div>
										<div className="dot dot2"></div>
										<div className="dot dot3"></div>
										<div className="dot dot4"></div>
										<div className="dot dot5"></div>
										<div className="dot dot6"></div>
									</div>
									<p>Error sending resume, please try again</p>
								</div>)
							}
						</div>
					</div>
				</div>
			)
		}
		return (
			<div className="mui-container-fluid medium-modal">
				<div className="mui-row">
                    <div className="mui-col-md-12">
						<div className="modal-header mui-row">
							<p>Share <span>{thisResume.firstName} {thisResume.lastName}</span> Resume</p>
							<i className="material-icons modal-close-icon" onClick={this.props.removeModal.bind(this)}>close</i>
						</div>

						<div className="modal-content-container">
							<Form onSubmit={this.send.bind(this)}
								  schema={shareSchema}
								  value={this.state.share}
								  onChange={this.handleChange.bind(this)}
								  id="form"
							>
								<div>
									<legend>Send to <Form.Message className='has-error' for='recipientEmail'/></legend>
									<Form.Field
										name='recipientEmail'
										placeholder={'Enter an email address to share the resume to'}
										type={UserEmailAutosuggest}
										fieldName="recipientUserId"
										itemSelected={this.itemSelected.bind(this)}
										className="form-input-margin-bottom"
									/>
								</div>
								<div className="form-share">
									<legend>Type of share <Form.Message className='has-error' for='suggestionType'/></legend>
									<Popup
										trigger={
											<p className="form-help"><FontAwesome name='info-circle' /> What is the difference between the types of shares?</p>
                                        }
										content={`Choosing a share type helps the recipient keep track of which resumes are suggested for them or for others.
										Choosing 'Suggest' suggests this resume to the recipient.
										Choosing 'Idea' allows you to send 2 resumes together as an idea`}
									/>
									{/*<img src="/images/share-resume.png"/>*/}
									<Form.Field
										type={SelectList}
										data={[
											{ value: 3, label: `Share`},
											{ value: 1, label: `Suggest`},
											{ value: 2, label: `Idea`}
										]}
										mapFromValue={ item => item.value }
										valueField='value'
										textField='label'
										name='suggestionType'
										className="form-input-margin-bottom form-inline"/>

									{share.suggestionType !== 2 ? null :
										<div className="autosuggest-list-container">
											<legend>Idea for<Form.Message className='has-error' for='ideaName'/></legend>
											<Form.Field
												name='ideaName'
												placeholder={'Enter the name or resume you would like to create an idea for'}
												gender={this.state.oppositeGender.id}
												type={ResumeAutosuggest}
												itemSelected={this.itemSelected.bind(this)}
												fieldName="ideaResumeId"
												className="form-input-margin-bottom"
											/>
										</div>
									}
								</div>
								<div>
									<legend>Include picture? <Form.Message className="has-error" for="picture"/></legend>
									<Form.Field
										type="boolean"
										className="mui-radio inline-radio"
										name="picture"/>
								</div>
								<div>
									<legend>Message <Form.Message className='has-error' for='text'/></legend>
									<Form.Field
										placeholder="Enter a message here"
										name='text'
										className="form-input-margin-bottom"
										type={resizableTextArea} />
								</div>

								{this.props.isSharingResume ?
									<div className="btn-flat btn-accentPrimary btn-fullWidth">
										<div className="loader-small"></div>
									</div>
									:
									<Form.Button
										type="submit"
										className="btn-flat btn-accentPrimary btn-fullWidth">
										Share Resume
									</Form.Button>
								}

								{/*<a onClick={this.props.shareToGroup}>Share to group instead</a>*/}
							</Form>
						</div>
					</div>
				</div>
            </div>
		);
	}
}

function mapStateToProps(state) {
	return {
		singleResume: state.singleResume.resume,
		isSharingResume: state.singleResume.isSharingResume,
		resumeSentToUser: state.singleResume.resumeSentToUser,
		sentToUserSuccessfully: state.singleResume.sentToUserSuccessfully,
		genderConstants: state.constants.gender
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(shareActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(shareToUserModal);


