import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { Scrollbars } from 'react-custom-scrollbars';

const rowHeight = 71;

function throttle(fn, threshold, scope) {
    threshold || (threshold = 250);
    let last,
        deferTimer;
    return function () {
        let context = scope || this;

        let now = +new Date,
            args = arguments;
        if (last && now < last + threshold) {
            // hold on to it
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                fn.apply(context, args);
            }, threshold);
        } else {
            last = now;
            fn.apply(context, args);
        }
    };
}

class Infinite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            height: this.props.containerHeight,
            scrollTop: 0,
            scrolledToActiveItem: false
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentWillReceiveProps(nextProps){
        //list has been filtered/refreshed and now there are less items than before
        if(nextProps.itemList.length < this.props.itemList.length){
            this.setState({ scrollTop: 0 });
        }
    }

    componentDidUpdate(){
        if(this.props.selectedIndex && !this.state.scrolledToActiveItem && this.props.itemList.length > this.props.selectedIndex){
            this.scrollbars.scrollTop(this.props.selectedIndex * rowHeight);
            this.setState({ scrolledToActiveItem: true} );
        }
    }

    scrollDown(){
        this.scrollbars.scrollTop(this.state.scrollTop + rowHeight);
    }

    scrollUp(){
        this.scrollbars.scrollTop(this.state.scrollTop - rowHeight);
    }


    handleScroll({ top, scrollTop }){
        if(this.props.itemList.length === 0){
            return;
        }
        if (top > .95) {
            throttle(this.props.loadMore.bind(this), 250)();

        }
        this.setState({scrollTop})
    }

    render () {
        let itemsLoaded =  this.props.itemList.reduce((total, r)=> r ? total+1 : total, 0);
        const totalHeight = rowHeight * itemsLoaded;

        const { height, scrollTop } = this.state;
        const scrollBottom = scrollTop + height;

        const startIndex = Math.max(0, Math.floor(scrollTop / rowHeight) - 20);
        let endIndex = Math.min(itemsLoaded, Math.ceil(scrollBottom / rowHeight) + 20);

        const items = [];

        let index = startIndex;
        while (index < endIndex) {
            if(this.props.itemList[index]){
                items.push(this.props.renderRow(this.props.itemList[index]));
            } else if(this.props.itemList.length > endIndex){
                //if this item has not loaded yet - get the next one
                endIndex++;
            }
            index++
        }

        return (
            <div>
                <Scrollbars
                    ref={scrollbars => this.scrollbars = scrollbars}
                    className={this.props.className}
                    style={{height: this.props.containerHeight}}
                    onScrollFrame={this.handleScroll}
                >
                    <div style={{paddingTop: startIndex * rowHeight, height: totalHeight}}>
                        {items.length || this.props.loadingMore ? items :
                            <div className="message-circle-empty message-circle-empty-dark">
                                <FontAwesome name='file-text'
                                className="message-circle-empty-icon message-circle-empty-icon-small"/>
                                <p>{this.props.noItemsText}<br/> Try clearing the filters</p>
                            </div>
                        }
                    </div>
                </Scrollbars>
                {this.props.loadingMore ?
                    <div className="progress-material">
                        <div className="progress-indeterminate"></div>
                    </div>
                    : null
                }
            </div>
        )
    }
}

Infinite.propTypes = {
    containerHeight: PropTypes.number.isRequired,
    loadingMore: PropTypes.bool,
    className: PropTypes.string,
    itemList: PropTypes.array.isRequired,
    renderRow: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    selectedIndex: PropTypes.number,
    noItemsText: PropTypes.string.isRequired
};

export default Infinite;