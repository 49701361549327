import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as groupMemberActions from '../../../actions/groupMemberActions';
import * as modalActions from '../../../actions/modalActions';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import GroupMember from './GroupMember';

class GroupMainMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileSectionOpen: ''
        };
    }

    acceptGroupMemberRequest(memberId) {
        this.props.actions.acceptGroupMemberRequest(memberId, this.props.group.id);
    }

    declineRequestToJoinGroup(tempMemberRequestId) {
        this.props.addAlert((
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete member request!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title="Are you sure?"
                onConfirm={()=>this.alertDeclineRequestToJoinGroup(tempMemberRequestId)}
                onCancel={this.props.clearAlert}
            >
                You will not be able to recover this request.
            </SweetAlert>
        ));
    }

    alertDeclineRequestToJoinGroup(tempMemberRequestId) {
        this.props.actions.declineGroupMemberRequest(tempMemberRequestId);
        this.props.clearAlert();
    }

    removeMember(memberId) {
        this.props.addAlert((
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete member!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title="Are you sure?"
                onConfirm={()=>this.alertDeleteMember(memberId)}
                onCancel={this.props.clearAlert}
            >
                You will not be able to recover this member without reinviting.
            </SweetAlert>
        ));
    }

    alertDeleteMember(memberId) {
        this.props.actions.removeMemberFromGroup(memberId);
        this.props.clearAlert();
    }

    inviteMemberToGroup() {
        this.props.modalActions.setModal('GROUPS_INVITE_MEMBER', {
            myAccessLevel: this.props.group.memberAccessLevel,
            groupApproved: this.props.group.approved,
            closeOnOutsideClick: true
        });
    }

    onMobileMenuClick(activeMenu) {
        this.setState({mobileSectionOpen: activeMenu});
    }

    onMobileMenuHeaderClick(menu, event) {
        if(event){//stop it from reopening the menu
            event.stopPropagation();
        }
        this.setState({mobileSectionOpen: this.state.mobileSectionOpen === menu ? '' : menu})
    }

    memberInviteList(member) {
        const formattedDateInvited = moment(member.dateInvited).format('MMMM Do, YYYY');

        return (
            <div className="mui-row list-items" key={member.id}>
                <div className="list-item">
                    <div className="list-item-info">
                        <h5>{member.recipientEmail}</h5>
                        <p><span>Invited as:</span> {member.groupAccessLevel.text}</p>
                        <p><span>Invited by:</span> {member.invitedBy.firstName} {member.invitedBy.lastName}</p>
                        <p><span>Invited on:</span> {formattedDateInvited}</p>
                    </div>
                    <button className="btn-flat btn-secondary btn-fullWidth"
                            onClick={() => this.props.actions.withdrawInviteMemberToGroup(member.id)}>
                        Withdraw Invite
                    </button>
                    <button className="btn-flat btn-secondary btn-fullWidth"
                            onClick={() => this.props.actions.resendInviteMemberToGroup(member.id)}>
                        Resend Invite
                    </button>
                    {member.inviteResent ? <p className="sent"><FontAwesome name='check'/> Sent</p>: null}
                </div>
            </div>
        );
    }

    memberRequestList(member) {
        const formattedDateRequested = moment(member.dateRequested).format('MMMM Do, YYYY');

        return (
        <div className="mui-row list-items" key={member.id}>
            <div className="list-item">
                <div className="list-item-info">
                    <h5>{member.users.firstName} {member.users.lastName}</h5>
                    <p><span>Requested on:</span> {formattedDateRequested}</p>

                    <div className="groups-resume-actions">
                        <p className="list-item-info-button" onClick={()=>this.acceptGroupMemberRequest(member.id)}>Accept</p>
                        <p className="list-item-info-button" onClick={()=>this.declineRequestToJoinGroup(member.id)}>Decline</p>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    render() {
        let { group }= this.props;

        let columnRequestedMembers;
        if (!group.approved) {
            columnRequestedMembers = (
                <div className="message-sidebar-empty">
                    <FontAwesome name='user' className="message-sidebar-empty-icon message-sidebar-empty-icon-inSection" />
                    <p>Group is pending approval</p>
                </div>
            )
        } else if (group.privacy !== 1) {
            columnRequestedMembers = (
                <div className="message-sidebar-empty">
                    <FontAwesome name='user' className="message-sidebar-empty-icon message-sidebar-empty-icon-inSection" />
                    <p>{group.privacyLevel} groups cannot have requested members. Invite member to group?</p>
                </div>
            );
        } else if(group.memberRequests.length > 0) {
            columnRequestedMembers = group.memberRequests.map(member => this.memberRequestList(member));
        } else {
            columnRequestedMembers = (
                <div className="message-sidebar-empty">
                    <FontAwesome name='user' className="message-sidebar-empty-icon message-sidebar-empty-icon-inSection" />
                    <p>No people requested to join</p>
                </div>
            );
        }

        if( group.memberAccessLevel <= 2) {
            return (
                <div className="mui-row list-items-container">
                    <div className="mui-col-md-12">
                        {group.membersErrorMessage ?
                            <div className="notifications-bar">
                                <p>{group.membersErrorMessage}</p>
                            </div>
                            :
                            ''
                        }
                        <div ref="activeMembers" className={this.state.mobileSectionOpen === 'activeMembers' ? "column member-menu-active" : "column"} onClick={()=>this.onMobileMenuClick('activeMembers')}>
                            <div className="column-heading" onClick={()=>this.onMobileMenuHeaderClick('activeMembers')}>
                                <h3>Active Members <span className="circle">{group.members.length}</span></h3>
                                <FontAwesome name='caret-down' className="mobile-members-open" />
                            </div>
                            <div className="column-content">
                            {group.members.length > 0 && group.members.map(member =>
                                <GroupMember
                                    key={member.id}
                                    member={member}
                                    updateMemberAccessLevel={this.props.actions.updateGroupMemberAccessLevel}
                                    myAccessLevel={group.memberAccessLevel}
                                    groupApproved={group.approved}
                                    numberOfOwners={group.ownersCount}
                                    removeMember={this.removeMember.bind(this)}
                                />)
                            }
                            </div>
                        </div>

                        <div ref="invitedMembers" className={this.state.mobileSectionOpen === 'invitedMembers' ? "column member-menu-active" : "column"} onClick={()=>this.onMobileMenuClick('invitedMembers')}>
                            <div className="column-heading" onClick={()=>this.onMobileMenuHeaderClick('invitedMembers')}>
                                <h3>Invited Members <span className="circle">{group.memberInvites.length}</span></h3>
                                <FontAwesome name='caret-down' className="mobile-members-open" />
                            </div>
                            <div className="column-content">
                            {group.memberInvites.length > 0 ?
                                group.memberInvites.map(member => this.memberInviteList(member)) :
                                <div className="message-sidebar-empty">
                                    <FontAwesome name='file-o' className="message-sidebar-empty-icon message-sidebar-empty-icon-inSection" />
                                    {group.approved ?
                                        <p>No members have been invited</p>
                                        : <p>There must be 2 owners on a group before it can be approved</p>
                                    }

                                    <p className="btn-flat btn-sidebar" onClick={()=>this.inviteMemberToGroup()}>
                                        Invite {group.approved ? 'member' : 'owner'} <FontAwesome name='arrow-right' />
                                    </p>
                                </div>
                            }
                            </div>
                        </div>
                        <div ref="requestedMembers" className={this.state.mobileSectionOpen === 'requestedMembers' ? "column member-menu-active" : "column"} onClick={()=>this.onMobileMenuClick('requestedMembers')}>
                            <div className="column-heading" onClick={()=>this.onMobileMenuHeaderClick('requestedMembers')}>
                                <h3>Requested Members <span className="circle">{group.memberRequests.length}</span></h3>
                                <FontAwesome name='caret-down' className="mobile-members-open" />
                            </div>
                            <div className="column-content">
                                {columnRequestedMembers}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if(group.memberAccessLevel === 3){
            return (
                <div ref="invitedMembers" className="column member-menu-active">
                    <div className="column-heading">
                        <h3>Invited Members <span className="circle">{group.memberInvites.length}</span></h3>
                        <FontAwesome name='caret-down' className="mobile-members-open" />
                    </div>
                    <div className="column-content">
                        {group.members.length > 0 ?
                            group.members.map(member => <GroupMember key={member.id} member={member} />) :
                            'No members have been added'}
                    </div>
                </div>
            );
        }
        return (
            <div className="container">
                <div className="column-content-message">
                    <p>This tab is only accessible by admins of this group</p>
                </div>
            </div>
        )

    }
}

GroupMainMembers.propTypes = {
    group: PropTypes.object,
    addAlert: PropTypes.func.isRequired,
    clearAlert: PropTypes.func.isRequired,
    actions: PropTypes.shape({
        withdrawInviteMemberToGroup: PropTypes.func.isRequired,
        resendInviteMemberToGroup: PropTypes.func.isRequired,
        acceptGroupMemberRequest: PropTypes.func.isRequired,
        declineGroupMemberRequest: PropTypes.func.isRequired,
        updateGroupMemberAccessLevel: PropTypes.func.isRequired,
        removeMemberFromGroup: PropTypes.func.isRequired
    })
};

function mapStateToProps(state) {
    return {
        group: state.singleGroup.group,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(groupMemberActions, dispatch),
        modalActions: bindActionCreators(modalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupMainMembers);