import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import Popup from '../../common/Popup';
import TextArea from 'react-textarea-autosize';

class GroupNote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noteText: props.note ? props.note.noteText: '',
            editingNote: false
        };
        this.editNote = this.editNote.bind(this);
        this.saveNote = this.saveNote.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.resumeId !== this.props.resumeId){
            this.setState({
                noteText: nextProps.note ? nextProps.note.noteText: '',
                editingNote: false
            });
        } else if(nextProps.note.saved && this.state.saving){
            this.setState({ saving: false, editingNote: false })
        }
    }

    editNote(){
        this.setState({ editingNote: true });
    }

    noteChanged(e){
        let noteText = e.target.value;
        this.setState({ noteText })
    }

    saveNote(){
        this.props.saveNote({...this.props.note, noteText: this.state.noteText});
        this.setState({ editingNote: true, saving: true });
    }

    cancelEdit(){
        this.setState({ noteText: this.props.note.noteText, editingNote: false })
    }


    render(){
        let { noteText, editingNote } = this.state;
        let { note } = this.props;
        return (
            <div>
                <h3 className="headerText-with-icon">
                    Group Note
                    <Popup
                        trigger={
                            <FontAwesome name='lock' className="headerText-with-icon-icon"/>
                        }
                        content='Notes are only seen by members of this group'
                    />
                </h3>
                <div>
                    {editingNote ? null :
                        <FontAwesome name='pencil' onClick={this.editNote}/>
                    }
                    <TextArea
                        disabled={!editingNote}
                        value={noteText || ''}
                        onChange={this.noteChanged.bind(this)}
                        name="noteText"
                    />
                    {editingNote ?
                        note.isSaving ?
                            <p className="text-autosaving">saving...</p>
                            :
                            <div className="note-actions">
                                <FontAwesome name='save' onClick={this.saveNote}/>
                                <FontAwesome name='times' onClick={this.cancelEdit}/>
                            </div>
                        : null
                    }

                </div>
            </div>
        )

    }
}

GroupNote.propTypes = {
    note: PropTypes.shape({
        noteText: PropTypes.string.isRequired,
        isSaving: PropTypes.bool
    }).isRequired,
    saveNote: PropTypes.func.isRequired,
};

export default GroupNote;
