import * as types from '../actionTypes/conversationActionTypes';
import { apiAuthRequest } from './helpers';
import { toastr } from 'react-redux-toastr';

export function loadConversations(search) {
	return async dispatch => {
		dispatch({
			type: types.LOAD_CONVERSATIONS_START,
			search
		});
		let result = await apiAuthRequest('get', '/inbox/getByUser', search);
		if(result.success) {
            dispatch({
                type: types.LOAD_CONVERSATIONS_SUCCESS,
                conversations: result.data
            });
        } else {
			console.error(result.data);
			toastr.error('Error loading conversations');
		}
	}
}

export function loadUnreadConversations() {
    return async dispatch => {
		let result = await apiAuthRequest('get', '/inbox/getUnread');
		if(result.success) {
            dispatch({
                type: types.LOAD_UNREAD_CONVERSATIONS_SUCCESS,
                unreadConversations: result.data
            });
        } else {
            console.error(result.data);
		}
    }
}








