import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ResumeLeftPanel from './resumeLeftPanel';
import ResumeMiddlePanel from './resumesMiddlePanel';
import ResumeRightPanel from './resumesRightPanel';
import * as modalActions from '../../actions/modalActions';
import * as singleResumeActions from '../../actions/singleResumeActions';
import * as resumePageActions from '../../actions/resumePageActions';
import { Helmet } from "react-helmet";
import isMobile from 'ismobilejs';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
// import keydown from 'react-keydown';

const emptySearch = {
    pageNumber: 1,
    filterBy: '',
    unreadOnly: false,
    pinnedOnly: false,
    searchText: '',
    gender: null,
    minAge: '',
    maxAge: '',
    minHeight: '',
    maxHeight: '',
    maritalStatus: [],
    maxChildren: '',
    types: [], //{typeId, minPower, maxPower}
    languages: [],
    religious: [],
    ethnicity: [],
    location: '',
    note: '',
    userTags:[],
    groups: [],
    typesStrictMode: false
};

class ResumeDisplay extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
        showWelcome: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedResume: props.match.params.id ? parseInt(props.match.params.id) : null,
            selectedConversation: '',
            showSidebar: !isMobile.any,
            mobileTab: props.match.params.id ? 'single' : 'list',
            search: {
                ...emptySearch,
                ...props.search,
                pageNumber: props.pagination.page
            }
        };
        this.findResumeInList = this.findResumeInList.bind(this);
    }

    reload() {
        this.props.resumeActions.loadResumes(this.state.search);
    }

    componentDidMount() {
        let props = this.props;
        if (props.singleResume && props.singleResume.id && !props.match.params.id) { // there is a resume in state already
            this.context.router.history.push('/resumes/' + props.singleResume.id);
            return;
        } else if (props.match.params.id && (!props.singleResume || props.match.params.id !== props.singleResume.id || this.props.location.hash === '#updated')) {
            this.setState({selectedResume: parseInt(props.match.params.id)}, () => {
                this.props.singleResumeActions.loadSingleResume(props.match.params.id);
            });
        }
        this.reload();
        this.props.resumeActions.getGroupsImPartOf();
        this.context.showWelcome('Resumes');
    }

    componentWillReceiveProps(props) {
        if(!props.match.params.id){
            this.setState({selectedResume: null});
            return;
        }
        if(props.match.params.id !== this.props.match.params.id) {
            this.setState({selectedResume: parseInt(props.match.params.id)}, () => {
                this.props.singleResumeActions.loadSingleResume(props.match.params.id);
            });
        }
        this.findResumeInList(props);
    }

    findResumeInList(props){
        if(props.match.params.id){
            let selectedResumeInList = props.resumes.find(r=> r && r.id === parseInt(props.match.params.id));
            if(!selectedResumeInList && _.isEqual({...this.state.search, pageNumber: 1}, emptySearch)){//if resume is not found and there is no filter applied
                // keep loading resumes until selected is in the list
                // this.loadMoreResumes(props);
            } else {
                let selectedIndex = props.resumes.findIndex(r=>r && r.id === parseInt(props.match.params.id));
                this.setState({selectedIndex});
            }
        }
    }
    
    selectResume(id) {
        this.context.router.history.push(`/resumes/${id}`);
        this.setState({mobileTab: 'single'});
    }

    selectNextResume(){
        let currentResumeIndex = this.props.resumes.findIndex(r=>r.id === this.state.selectedResume);
        if(currentResumeIndex >= this.props.resumes.length - 10){
            this.loadMoreResumes();
        }
        if(currentResumeIndex < this.props.resumes.length) {
            let nextResume = this.props.resumes[currentResumeIndex + 1];
            let nextResumeId = nextResume ? nextResume.id : this.state.selectedResume;
            this.context.router.history.push(`/resumes/${nextResumeId}`);
        }
        if(this.list){
            this.list.selectNext()
        }
    }

    selectPreviousResume(){
        let currentResumeIndex = this.props.resumes.findIndex(r=>r.id === this.state.selectedResume);
        if(currentResumeIndex > 0) {
            let previousResumeId = this.props.resumes[currentResumeIndex -1].id;
            this.context.router.history.push(`/resumes/${previousResumeId}`);
        }
        if(this.list){
            this.list.selectPrevious()
        }
    }

    // @keydown( 'up', 'down' )
    // myMethod( event ) {
    //     if(event.key === "ArrowDown"){
    //         event.preventDefault();
    //         this.selectNextResume();
    //     } else if(event.key === "ArrowUp"){
    //         event.preventDefault();
    //         this.selectPreviousResume();
    //     }
    // }

    loadResumes(search) {
        this.setState({search, selectedIndex: null}, () => {
            this.props.resumeActions.loadResumes(search);
        });
    }

    loadMoreResumes(props) {
        let currentProps = props || this.props;
        if(!currentProps.pagination.pageCount){
            return;
        }
        if(this.state.search.pageNumber > currentProps.pagination.pageCount + 1) {
            return;
        }
        if(this.state.search.pageNumber >= (currentProps.pagination.page + 2)){
            //don't load too many pages ahead
            return;
        }
        let search = { ...this.state.search };
        search.pageNumber = this.state.search.pageNumber + 1;
        this.setState({search}, () => {
            this.props.resumeActions.loadResumes(search);
        });
    }

    unflagResumeAlert(resumeId) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title=" Are you sure you want to unflag this resume?"
                    onConfirm={this.unflagResume.bind(this, resumeId)}
                    onCancel={()=>this.setState({alert: null})}
                >
                </SweetAlert>
            )
        });
    }

    unflagResume(id) {
        this.setState({alert: null}, ()=>{
            this.props.singleResumeActions.unflagResume(id);
        });
    }

    render() {
        let resumeContent;
        if(this.props.singleResume && this.props.singleResume.message){
            resumeContent = <div id="content-main" className="header-padding column-layout-two">
                                <div className="content-padding message-empty">
                                    <i className ="material-icons">not_interested</i>
                                    <p>You do not have access to this resume.</p>
                                </div>
                            </div>;
        } else if (this.props.singleResume && this.props.singleResume.id && this.state.selectedResume) {
            resumeContent = <div className="container">
                                <ResumeMiddlePanel 
                                    resume={this.props.singleResume}
                                    isLoading={this.props.resumeIsLoading}
                                    pinResume={this.props.singleResumeActions.pinResume.bind(this)}
                                    unpinResume={this.props.singleResumeActions.unpinResume.bind(this)}
                                    flagResume={this.props.singleResumeActions.flagResume.bind(this)}
                                    unflagResumeAlert={this.unflagResumeAlert.bind(this)}
                                    selectNextResume={this.selectNextResume.bind(this)}
                                    selectPreviousResume={this.selectPreviousResume.bind(this)}
                                    totalResumeCount={this.props.pagination.rowCount}
                                    currentResumeIndex={this.state.selectedIndex}
                                    displayMobile={this.state.mobileTab === 'single'}
                                    switchMobileTab={()=>this.setState({mobileTab: 'list'})}
                                    showSidebar={this.state.showSidebar}
                                    setModal={this.props.modalActions.setModal}
                                />
                                <ResumeRightPanel 
                                    resume={this.props.singleResume}
                                    selectedConversation={this.state.selectedConversation}
                                    selectConversation={(id)=>this.setState({selectedConversation: id})}
                                    unSelectConversation={(id)=>this.setState({selectedConversation: ''})}
                                    loadSingleResume={this.selectResume.bind(this)}
                                    displayMobile={this.state.mobileTab === 'single'}
                                    showSidebar={this.state.showSidebar}
                                    toggleSidebar={()=>this.setState({showSidebar: !this.state.showSidebar})}
                                    setModal={this.props.modalActions.setModal}
                                />
                            </div>
        } else if(this.props.resumeIsLoading){
            resumeContent = <div className="loader"></div>
        } else {
            resumeContent =
                <div id="content-main" className="header-padding column-layout-two">
                    <div className="content-padding message-empty">
                        <i className="material-icons">description</i>
                        <p>No Resume Selected</p>
                        <p>Select a resume from the left panel to view its content.</p>
                    </div>
                </div>;
        }
            return (
            <div className="main-container resumePage mui-container-fluid"
                 // onKeyDown={ this.myMethod }
            >
                <Helmet>
                    <title>Resumes | ZivugTech</title>
                </Helmet>
                {this.state.alert}
                <ResumeLeftPanel
                    ref={list => this.list = list}
                    resumes={this.props.resumes}
                    isLoading={this.props.isLoading}
                    selectedResume={this.state.selectedResume}
                    selectResume={this.selectResume.bind(this)}
                    selectNextResume={this.selectNextResume.bind(this)}
                    selectPreviousResume={this.selectPreviousResume.bind(this)}
                    currentResumeIndex={this.state.selectedIndex}
                    constants={this.props.constants}
                    search={this.state.search}
                    emptySearch={emptySearch}
                    loadResumes={this.loadResumes.bind(this)}
                    loadMoreResumes={this.loadMoreResumes.bind(this)}
                    pagination={this.props.pagination}
                    userTags={this.props.userTags}
                    displayMobile={this.state.mobileTab === 'list'}
                    switchMobileTab={()=>this.setState({mobileTab: 'single'})}
                />
                <div className={`${this.state.mobileTab === 'single' ? '' : 'mobile-hide'}`}>
                    {resumeContent}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        singleResumeActions: bindActionCreators(singleResumeActions, dispatch),
        resumeActions: bindActionCreators(resumePageActions, dispatch),
        modalActions: bindActionCreators(modalActions, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        resumes: state.resumes.resumes,
        search: state.resumes.search,
        isLoading: state.resumes.isLoading,
        pagination: state.resumes.pagination,
        singleResume: state.singleResume.resume,
        resumeIsLoading: state.singleResume.isLoading,
        constants: state.constants,
        userTags: state.userTags
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResumeDisplay)



