import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { findDOMNode } from 'react-dom';
import scrollIntoView from 'scroll-into-view';
import * as modalActions from '../../actions/modalActions';
import * as singleResumeActions from '../../actions/singleResumeActions';
import * as conversationActions from '../../actions/singleConversationActions';
import * as userActions from '../../actions/userActions';
import SingleConversation from './SingleConversation';
import InboxRightPanel from './inboxRightPanel';
import { Scrollbars } from 'react-custom-scrollbars';
import Popup from '../common/Popup';
import SweetAlert from 'react-bootstrap-sweetalert';
import isMobile from 'ismobilejs';
import FontAwesome from 'react-fontawesome';

class InboxMainPanel extends React.Component {
    static contextTypes = {
        objectHeights: PropTypes.object.isRequired
    };

	constructor(props) {
		super(props);
		this.state = {
			showSidebar: !isMobile.any,
			height: 0,
			replyFocused: false,
			alert: null
		};
	}

	loadSingleResume(id) {
		if(!this.props.resume || id !== this.props.resume.id){
			this.props.resumeActions.loadSingleSidebarResume(id);
		}
	}
	showSidebar() {
		this.setState({showSidebar: true});
	}

	sendMessage(message) {
		this.unfocusReply();
		this.props.conversationActions.sendMessage(message);
	}
	markConversationUnread() {
		this.props.conversationActions.markUnread(this.props.conversation.id);
	}
    markConversationRead() {
        this.props.conversationActions.marksAsRead(this.props.conversation.id);
    }
	archiveConversation() {
		this.props.conversationActions.archiveConversation(this.props.conversation);
	}
	unarchiveConversation() {
		this.props.conversationActions.unarchiveConversation(this.props.conversation);
	}
	reply(){
		let conversationRef = findDOMNode(this.refs.conversation);
		if(conversationRef){
			scrollIntoView(conversationRef.children[2]);
			this.setState({replyFocused: true});
		}
	}
	
	unfocusReply(){
		this.setState({replyFocused: false});
	}

    reportUserModal() {
		let user = this.props.conversation.participants[0].user;
        this.props.modalActions.setModal('FLAG', {
            flaggedId: user.id,
            flaggedName: `${user.firstName} ${user.lastName}`,
            flaggedType: 'User',
            flagAction: this.props.userActions.flagUser.bind(this),
            closeOnOutsideClick: true
        });
    }

    removeReportUserAlert(userId) {
        this.setState({
            alert: (
				<SweetAlert
					warning
					showCancel
					confirmBtnText="Yes"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="default"
					title=" Are you sure you want to unflag this user?"
					onConfirm={this.removeReportUser.bind(this, userId)}
					onCancel={()=>this.setState({alert: null})}
				>
				</SweetAlert>
            )
        });
    }

    removeReportUser(userId) {
        this.setState({alert: null}, ()=>{
            this.props.userActions.unflagUser(userId);
        });
    }

	render() {
		let renderThis;
		let { conversation } = this.props;
		if(this.props.isLoading) {
			renderThis =
			(
				<div id="content-main" className="header-padding column-layout-two">
					<div className="loader"></div>
				</div>
			);
		} else if(!this.props.isLoading && conversation && conversation.id && this.props.selectedConversation) {
			renderThis =
				<div className="container">
					<div id="content-main" className={`header-padding ${this.state.showSidebar ? '' : 'sidebar-hidden'}`}>
						<div className="container overflow-auto">
							<div className="panel-actions panel-actions-fixed">
								{this.props.displayMobile ?
									<div onClick={this.props.switchMobileTab} className="panel-action all-resumes">
										<FontAwesome name='arrow-left' /> <p>Back to list</p>
									</div>
									: null }
								<Popup
									trigger={
										<div onClick={this.reply.bind(this)} className="panel-action panel-action-button">
											<FontAwesome name='reply' />
											<p>Reply</p>
										</div>
									}
									content="Click here to reply to a message"
								/>
								{conversation.archived ?
									<Popup
										trigger={
											<div onClick={this.unarchiveConversation.bind(this)} className="panel-action panel-action-active">
												<FontAwesome name='archive' />
												<p>Unarchive</p>
											</div>
										}
										content="Unarchive"
									/>
									 :
									<Popup
										trigger={
											<div onClick={this.archiveConversation.bind(this)} className="panel-action">
												<FontAwesome name='archive' />
												<p>Archive</p>
											</div>
										}
										content="Click here to archive a message. To view archived messages choose the archived filter"
									/>
								}
								{!conversation.archived ?
									conversation.read ?
										<Popup
											trigger={
												<div onClick={this.markConversationUnread.bind(this)} className="panel-action">
													<FontAwesome name='envelope' />
													<p>Mark as unread</p>
												</div>
											}
											content="Mark as unread"
										/>
										:
										<Popup
											trigger={
												<div onClick={this.markConversationRead.bind(this)} className="panel-action">
													<FontAwesome name='eye' />
													<p>Mark as read</p>
												</div>
											}
											content="Click here to remove the notification symbol. "
										/>
									:
									null
								}
                                {conversation.participantUser && !conversation.system ?
                                    conversation.participantUser.flagged ?
										<div onClick={this.removeReportUserAlert.bind(this, conversation.participantUser.id)} className="panel-action">
											<Popup
												trigger={
													<FontAwesome name='flag' className="panel-action-flagged-active" />
												}
												content={`"${conversation.participantName}" has been reported`}
											/>
										</div>
										:
										<div onClick={this.reportUserModal.bind(this)} className="panel-action">
											<Popup
												trigger={
													<FontAwesome name='flag' />
												}
												content={`Flag "${conversation.participantName}" for inappropriate usage of Inbox`}
											/>
										</div>
									: null
								}
							</div>
							<div className="content-padding-fixed-top">
								<Scrollbars style={{height: this.context.objectHeights.mainPanelCalculatedHeight }}>
									<div className="sidebar-tab active">
										<div className="sidebar-resume-shares">
											<SingleConversation
												ref="conversation"
												replyFocused={this.state.replyFocused}
												unfocusReply={this.unfocusReply.bind(this)}
												conversation={conversation}
												sendMessage={this.sendMessage.bind(this)}
												showSidebar={this.showSidebar.bind(this)}
												sidebarOpen={this.state.showSidebar}
												isInbox={true}
											/>
										</div>
									</div>
								</Scrollbars>
							</div>
						</div>
					</div>
					<InboxRightPanel
						conversation={conversation}
						loadSingleResume={this.loadSingleResume.bind(this)}
						isLoading={this.props.resumeLoading}
						showSidebar={this.state.showSidebar}
						toggleSidebar={()=>this.setState({showSidebar: !this.state.showSidebar})}
						resume={this.props.resume}
					/>
				</div>
		} else if (conversation && conversation.message){
			renderThis = (
				<div id="content-main" className="header-padding column-layout-two">
					<div className="content-padding message-empty">
						<i className ="material-icons">not_interested</i>
						<p>You do not have access to this conversation</p>
					</div>
				</div>
			);
		} else {
			renderThis = (
				<div id="content-main" className="header-padding column-layout-two">
					<div className="content-padding message-empty">
						<i className ="material-icons">mail_outline</i>
						<p>No Message Selected</p>
						<p>Select a message from the left panel to view its content.</p>
					</div>
				</div>
			);
		}
		return (
			<div className={`container ${this.props.displayMobile ? '' : 'mobile-hide'}`}>
                {this.state.alert}
				{renderThis}
			</div>
		)
	}
}

function mapStateToProps(state) {
    return {
        conversation: state.singleConversation.conversation,
        isLoading: state.singleConversation.isLoading,
		resume: state.sidebarResume.resume,
		resumeLoading: state.sidebarResume.isLoading
    }
}

function mapDispatchToProps(dispatch) {
	return {
		resumeActions: bindActionCreators(singleResumeActions, dispatch),
		conversationActions: bindActionCreators(conversationActions, dispatch),
		userActions: bindActionCreators(userActions, dispatch),
		modalActions: bindActionCreators(modalActions, dispatch)
	};
}



export default connect(mapStateToProps, mapDispatchToProps)(InboxMainPanel);