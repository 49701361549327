import * as types from '../actionTypes/sidebarResumeActionTypes';

export default function sidebarResumeReducer(state = {}, action) {
	switch(action.type) {
		case types.LOAD_SINGLE_SIDEBAR_RESUME_START:
			return {
				resume: state.resume,
				isLoading: true
			};
		case types.LOAD_SINGLE_SIDEBAR_RESUME_SUCCESS:
			return {
				resume: action.resume,
				isLoading: false
			};
		case types.PIN_SIDEBAR_RESUME:
			return {
				resume: {...state.resume, pinned: true}
			};
		case types.UNPIN_SIDEBAR_RESUME:
			return {
				resume: {...state.resume, pinned: false}
			};
		case types.FLAG_SIDEBAR_RESUME:
			return {
				resume: {...state.resume, flagged: true}
			};
		case types.UNFLAG_SIDEBAR_RESUME:
			return {
				resume: {...state.resume, flagged: false}
			};
		case types.ENGAGE_SIDEBAR_RESUME:
			return {
				resume: {...state.resume, engagedReported: true}
			};
		default:
			return state;
	}
}