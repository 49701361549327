import React from 'react';
import Lightbox from 'react-images';

class PrivateLightbox extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const resumeImages = this.props.images.map((image) => {
            return {
                src: `/api/images/${this.props.section}/${this.props.id}/${image.fileLocation}`
            }
        });
        return <Lightbox
            ref="lightbox"
            currentImage={this.props.currentImage}
            images={resumeImages}
            isOpen={this.props.isOpen}
            onClose={this.props.onClose}
            onClickPrev={this.props.onClickPrev}
            onClickNext={this.props.onClickNext}
            backdropClosesModal={true}
        />
    }
}

export default PrivateLightbox;