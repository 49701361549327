import React from 'react';
import * as userActions from '../../../actions/userActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dropzone from 'react-dropzone';
import FontAwesome from 'react-fontawesome';

class verifyUserModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            verificationImage: null,
            preview: '',
            submitting: false,
            imageRequired: false
        };
        this.submit = this.submit.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.removeImage = this.removeImage.bind(this);
    }

    submit() {
        if(!this.state.verificationImage){
            this.setState({imageRequired: true});
            return;
        }
        this.setState({submitting: true}, () => {
            this.props.actions.verifyUser(this.state.verificationImage, this.props.removeModal);
        })
    }

    onDrop (acceptedFiles) {
        if(acceptedFiles.length) {
            if (acceptedFiles[0].size > 2500000) {
                return this.setState({imageTooLarge: true})
            }else {
                this.setState({
                    preview: acceptedFiles[0].preview,
                    verificationImage: acceptedFiles[0],
                    imageTooLarge: false,
                    imageRejected: false
                });
            }
        }
    }

    removeImage () {
        this.setState({preview: '', verificationImage: null});
    }

    render() {
        if (this.state.submitting && this.props.verificationImage && this.props.verificationImage.id) {
            return (
                <div className="mui-container medium-modal">
                    <div className="mui-row">
                        <i className="material-icons modal-close-icon"
                           onClick={this.props.removeModal.bind(this)}>close</i>
                        <div className="modal-response">
                            <div className="envelope">
                                <div className="envelope-outline">
                                    <div className="triangle"></div>
                                </div>
                                <div className="dot dot1"></div>
                                <div className="dot dot2"></div>
                                <div className="dot dot3"></div>
                                <div className="dot dot4"></div>
                                <div className="dot dot5"></div>
                                <div className="dot dot6"></div>
                            </div>
                            <p>Verification awaiting approval.<br/>Please allow up to 72 hours for processing</p>
                        </div>
                    </div>
                </div>
            )
        } else if(this.state.submitting) {
            return (
                <div className="mui-container medium-modal">
                    <div className="mui-row">
                        <div className="modal-response">
                            <div className='loader'></div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="mui-container medium-modal">
                <div className="mui-row">
                    <div className="mui-col-md-12">
                        <div className="mui-row modal-header">
                            <p>Verify</p>
                            <i className="material-icons modal-close-icon"
                               onClick={this.props.removeModal.bind(this)}>close</i>
                        </div>
                        <div className="modal-content-container verify-modal">
                            <div id="form">
                                <legend>Please upload a drivers license or other valid form of ID</legend>
                                {this.state.imageRequired ? <span className="has-error">Please choose an image</span>: null}
                                {!this.state.preview ?
                                    <Dropzone
                                        onDropRejected={()=>this.setState({imageRejected: true})}
                                        onDropAccepted={this.onDrop}
                                        multiple={false}
                                        accept='image/*' className="dragDrop" activeClassName="dragDropActive">
                                        <div>
                                            <FontAwesome name='upload' className='group-color-light mui--text-center' />
                                            <h4 className="mui--text-center">Drop a .jpg or .png file here, or <span className="dragDropFileBtn">select file to upload</span></h4>
                                        </div>
                                        {this.state.imageTooLarge ? <span>That image is too big. Try another one!</span>: null}
                                        {this.state.imageRejected ? <span>Whoops... that image won't work. Try again!</span>: null}
                                    </Dropzone>
                                    :
                                    <div className="picPreview">
                                        <p><FontAwesome name='trash' className='group-color-light mui--text-center' onClick={this.removeImage.bind(this)} /></p>
                                        <img src={this.state.preview} className="dragDropPreview" onClick={this.removeImage.bind(this)} />
                                    </div>
                                }
                                <div className="text-center">
                                    <button className="btn-flat btn-accentPrimary btn-fullWidth" onClick={this.submit}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        verificationImage: state.user.user.verificationImage
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(verifyUserModal);


