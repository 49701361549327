import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import * as userActions from '../../../actions/userActions';

class ConfirmUser extends React.Component {
	componentDidMount() {
		this.props.actions.confirmUser(this.props.match.params);//token, userId
	}

	resendConfirmationEmail(){
		this.props.actions.resendConfirmationEmail(this.props.match.params.userId);
	}

	render() {
		if(this.props.user.confirmationResent){
			return (
				<div className="page-account page-login">
					<div className="page-account-container-large">
						<Link to={'/login'}>
							<img src="/images/logo.png" alt="" className="page-account-logo"/>
						</Link>

						<div id="form" className="page-account-content mui--z1">
							<h3 className="text-align-center">{this.props.user.message}</h3>
							{this.props.user.confirmedAlready ?
								<button className="btn-flat btn-primaryAccent btn-loader text-align-center">
									<Link to={`/login`}>Login</Link>
								</button>
							:
								<p>You can close this window now ;)</p>
							}
						</div>
					</div>
				</div>
			)
		}
		if(this.props.user.errorConfirmingUser){
			return (
				<div className="page-account page-login">
					<div className="page-account-container-large">
						<img src="/images/logo.png" alt="" className="page-account-logo"/>
						<div id="form" className="page-account-content mui--z1">
							<h3 className="text-align-center">{this.props.user.confirmationErrorMessage}</h3>
							{this.props.user.canResendConfirmation ?
							<button
								className="btn-flat btn-primaryAccent btn-loader text-align-center"
								onClick={this.resendConfirmationEmail.bind(this)}>
								Click here to resend confirmation email
							</button>
								:
							<p>Please contact support</p>
							}
						</div>
					</div>
				</div>
			)
		}
		return (
			<div className="page-account page-login">
				<Helmet>
					<title>Confirm User | ZivugTech</title>
				</Helmet>
				<div className="loader"></div>
			</div>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		user: state.user
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(userActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmUser)
