import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { apiAuthRequest } from '../../actions/helpers';
import Dropzone from 'react-dropzone';
import FontAwesome from 'react-fontawesome';
import Subheader from '../common/SubHeader';
import { bindActionCreators } from 'redux';
import * as modalActions from '../../actions/modalActions';
import * as groupEditActions from '../../actions/groupEditActions';
import yup from 'yup';
import Form from 'react-formal';
import SelectList from 'react-widgets/lib/SelectList';
import MultiSelect from 'react-widgets/lib/Multiselect';
import 'react-widgets/lib/less/react-widgets.less';
import ResizableTextArea from '../common/resizableTextArea.js';
import _ from 'lodash';
import { Helmet } from "react-helmet";

yup.addMethod(yup.mixed, 'min', function (min, msg) {
    return this.test({
        name: 'min',
        exclusive: true,
        message: msg,
        test: value => !value || value.length >= min
    })
});

const emptyGroup = {
    name: '',
    desc: '',
    privacy: false,
    types: [],
    specialties: [],
    groupName: '',
    groupEmail: '',
    groupPhone: '',
    groupAddressStreet: '',
    groupAddressCity: '',
    groupAddressState: '',
    groupAddressZip: '',
    groupAddressCountry: '',
    groupWebsite: '',
    logoLocation: '',
    logo: {},
    groupOwnerEmail: ''
};

let attributeObject = yup.object().shape({
    id: yup.number(),
    text: yup.string()
});
const groupSchema = yup.object().shape({
    id: yup.mixed(),
    name: yup.string().required('Required')
        .test('nameExists', 'Group name already exists.', function (value){
            let params = {name: value, groupId: this.parent.id || null};
            return apiAuthRequest('get', '/groups/checkGroupNameExists', params)
                .then(response => !response.data)
    }),
    desc: yup.string().required('Required'),
    privacy: yup.mixed().oneOf([1, 2, 3],'Required'),
    types: yup.array().of( attributeObject ),
    specialties: yup.array().of( attributeObject ),
    groupName: yup.mixed(),
    groupEmail: yup.string().email('Invalid email').default(''),
    groupPhone: yup.mixed().min(10,'Invalid phone number'),
    groupAddressStreet: yup.mixed(),
    groupAddressCity: yup.mixed(),
    groupAddressState: yup.mixed(),
    groupAddressZip: yup.mixed(),
    groupAddressCountry: yup.mixed(),
    groupWebsite: yup.mixed(),
    logoLocation: yup.mixed(),
    myEmail: yup.string(),
    groupOwnerEmail: yup.mixed().when('id', {
        is: id=>id,
        then: yup.mixed(),
        otherwise: yup.string().email('Invalid email').required('Required')
            .test('emailsMatch', "You can't be both owners ;)", function (email) {
            return email !== this.parent.myEmail
        })
    })
});

class CreateEditGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            group: emptyGroup,
            errors: {},
            preview: '',
            organization: false
        };

        this.onChange = this.onChange.bind(this);
        this.saveGroup = this.saveGroup.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.imagePreviews = []; //keep track of image previews generated so that we can destroy in componentWillUnmount
    }

    componentDidMount() {
        let props = this.props;
        let group = this.state.group;
        if(props.match.params.id) {
            let groupId = props.match.params.id;
            props.actions.loadGroupToEdit(groupId);
        } else {
            group = {...group, myEmail: props.user.email};
        }
        this.setState({group});
    }

    componentWillUnmount() {
        this.imagePreviews.forEach(p=>{
            window.URL.revokeObjectURL(p);
        })
    }

    componentWillReceiveProps(props) {
        let group = emptyGroup;
        if(props.match.params.id) {
            group = props.group;

            //convert null to empty string
            Object.keys(group).forEach(prop => {
                let propValue = group[prop];
                if(propValue === null) {
                    group[prop] = "";
                }
            });
            if(group.groupName){
                this.setState({organization: 1});
            }
            group.logo = {};
        } else {
            group = {...group, myEmail: props.user.email};
        }
        this.setState({group});
    }

    onChange(group) {
        return this.setState({group});
    }

    picPreview() {
        if(!this.state.preview) {
            return (
                <Dropzone
                    onDropRejected={()=>this.setState({imageRejected: true})}
                    onDropAccepted={this.onDrop}
                    multiple={false}
                    accept='image/*' className="dragDrop" activeClassName="dragDropActive">
                    <div>
                        <FontAwesome name='upload' className='group-color-light mui--text-center' />
                        <h4 className="mui--text-center">Drop a .jpg or .png file here, or <span className="dragDropFileBtn">select file to upload</span></h4>
                    </div>
                    {this.state.imageTooLarge ? <span>That image is too big. Try another one!</span>: null}
                    {this.state.imageRejected ? <span>Whoops... that image won't work. Try again!</span>: null}
                </Dropzone>
            );
        }

        return (
            <div className="picPreview">
                <p><FontAwesome name='trash' className='group-color-light mui--text-center' onClick={this.removeLogo.bind(this)} /></p>
                <img src={this.state.preview} className="dragDropPreview" onClick={this.removeLogo.bind(this)} />
            </div>
        );
    }

    onDrop (acceptedFiles) {
        if(acceptedFiles.length) {
            let group = { ...this.state.group };
            if (acceptedFiles[0].size > 2500000) {
                return this.setState({imageTooLarge: true})
            } else {
                group.logo = acceptedFiles[0];
                this.setState({
                    preview: acceptedFiles[0].preview,
                    group: group,
                    imageTooLarge: false,
                    imageRejected: false
                });
                this.imagePreviews.push(acceptedFiles[0].preview)
            }
        }
    }

    removeLogo() {
        let group = { ...this.state.group };
        group.logo = {};
        group.logoLocation = '';
        this.setState({preview: '', group: group});
    }

    saveGroup(values) {
        let group = { ...this.state.group};
        if(!this.state.organization){
            group.groupName = '';
        }
        if(this.props.match.params.id) {
            this.props.actions.editGroup(group);
        } else {
            this.props.actions.createNewGroup(group);
        }
    }

    verifyUser(){
        this.props.modalActions.setModal('VERIFY_USER', {
            closeOnOutsideClick: true
        });
    }

    render() {
        let {constants} = this.props;
        let content = null;
        if (this.props.isLoading) {
            content = (
                <div className="mui-panel">
                    <div className="loader"></div>
                </div>
            );
        } else if (this.props.group.errorMessage) {
            content = (
                <div className="mui-panel">
                    <p className="group-message">You do not have permission to edit this group.</p>
                </div>
            );
        } else {
            content = (
                <Form schema={groupSchema}
                      value={this.state.group}
                      errors={this.state.errors}
                      onError={errors=>this.setState({errors})}
                      onChange={(group)=>this.setState({group})}
                      onSubmit={this.saveGroup.bind(this)}>
                    <div className="group-modal-form edit-group-form">
                        <div className="mui-panel">
                            <div className="form-heading">
                                <h3 className="organization-info">Please fill out group information</h3>
                            </div>

                            <div className="mui-row">
                                <div className="mui-col-md-12">
                                    <legend>Name *<Form.Message className="has-error" for="name"/></legend>
                                    <Form.Field
                                        name="name"
                                        className="form-input-margin-bottom"
                                        placeholder="Please enter a group name"
                                    />
                                </div>
                            </div>

                            <div className="mui-row">
                                <div className="form-inline-half">
                                    <legend>Privacy Setting (Public groups are temporarily disabled) *<Form.Message className="has-error" for="privacy"/></legend>
                                    <Form.Field
                                        name="privacy"
                                        type={SelectList}
                                        className="inline-radio"
                                        data={[
                                            // {value: 1, label: 'Public'}, 
                                            // {value: 2, label: 'Protected'}, 
                                            {value: 3, label: 'Private'},
                                        ]}
                                        mapFromValue={ item => item.value }
                                        valueField='value'
                                        textField='label'
                                    />
                                </div>
                                <div className="form-inline-half">
                                    <legend>Is the group for an organization?</legend>
                                    <div className="group-radios">
                                        <label><input type="radio"
                                                   className="inline-radio"
                                                   name="organization"
                                                   value={false}
                                                   checked={!this.state.organization}
                                                   onChange={()=>this.setState({organization: false})} />No</label>
                                        <label><input type="radio"
                                                   className="inline-radio"
                                                   name="organization"
                                                   value={true}
                                                   checked={this.state.organization}
                                                   onChange={()=>this.setState({organization: true})} />Yes</label>
                                    </div>
                                </div>
                            </div>

                            {this.state.organization &&
                            <div className="mui-row">
                                <div className="mui-col-md-12">
                                    <legend>Organization Name</legend>
                                    <Form.Field
                                        type='text'
                                        name="groupName"
                                        placeholder="Please enter your groups organization name"
                                    />
                                </div>
                            </div>
                            }

                            <div className="mui-row">
                                <div className="mui-col-md-12">
                                    <legend>Description * <Form.Message className='has-error' for="desc"/></legend>
                                    <Form.Field
                                        name="desc"
                                        type={ResizableTextArea}
                                        placeholder="Please enter a group description"
                                    />
                                </div>
                            </div>

                            <div className="mui-row">
                                <div className="mui-col-md-12">
                                    <legend>Types</legend>
                                    <Form.Field
                                        className="react-tags"
                                        type={MultiSelect}
                                        data={constants.types}
                                        valueField='id'
                                        textField='text'
                                        placeholder="Please enter types"
                                        name="types"/>
                                </div>
                            </div>
                            <div className="mui-row">
                                <div className="mui-col-md-12">
                                    <legend>Specialties</legend>
                                    <Form.Field
                                        className="react-tags"
                                        placeholder="Please enter specialties"
                                        type={MultiSelect}
                                        data={constants.specialties}
                                        valueField='id'
                                        textField='text'
                                        name="specialties"/>
                                    {/*<p className="text-link-under-input text-align-right">Add Specialty?</p>*/}
                                </div>
                            </div>
                            <div className="mui-row">
                                <div className="form-inline-half mui-col-md-6">
                                    <legend>Email<Form.Message className="has-error" for="groupEmail"/></legend>
                                    <Form.Field
                                        name="groupEmail"
                                        placeholder="Please enter a group email"
                                    />
                                </div>
                                <div className="form-inline-half mui-col-md-6">
                                    <legend>Phone<Form.Message className="has-error" for="groupPhone"/></legend>
                                    <Form.Field
                                        type='text'
                                        name="groupPhone"
                                        placeholder="Please enter a group phone number"
                                    />
                                </div>
                            </div>

                            <div className="mui-row">
                                <div className="form-inline-half mui-col-md-6">
                                    <legend>Street Address</legend>
                                    <Form.Field
                                        type='text'
                                        name="groupAddressStreet"
                                        placeholder="Please enter an address"
                                    />
                                </div>
                                <div className="form-inline-half mui-col-md-6">
                                    <legend>City</legend>
                                    <Form.Field
                                        type='text'
                                        name="groupAddressCity"
                                        placeholder="Please enter a city"
                                    />
                                </div>
                            </div>

                            <div className="mui-row">
                                <div className="form-inline-third mui-col-md-6">
                                    <legend>State</legend>
                                    <Form.Field
                                        type='text'
                                        name="groupAddressState"
                                        placeholder="Please enter a state"
                                    />
                                </div>
                                <div className="form-inline-third mui-col-md-6">
                                    <legend>Zip</legend>
                                    <Form.Field
                                        type='text'
                                        name="groupAddressZip"
                                        placeholder="Please enter a zip"
                                    />
                                </div>
                                <div className="form-inline-third mui-col-md-6">
                                    <legend>Country</legend>
                                    <Form.Field
                                        type='text'
                                        name="groupAddressCountry"
                                        placeholder="Please enter a country"
                                    />
                                </div>
                            </div>

                            <div className="mui-row">
                                <div className="mui-col-md-6">
                                    <legend>Website</legend>
                                    <Form.Field
                                        type='text'
                                        name="groupWebsite"
                                        placeholder="Please enter a website"
                                    />
                                </div>
                            </div>

                            <div className="mui-row">
                                <div className="mui-col-md-12">
                                    <legend>Logo</legend>
                                    {this.state.group.logoLocation ?
                                        <div className="picPreview">
                                            <p><FontAwesome name='trash' className='group-color-light mui--text-center' onClick={this.removeLogo.bind(this)} /></p>
                                            <img src={`../../api/images/group/${this.state.group.logoLocation}`} className="dragDropPreview" height="125" width="125" onClick={this.removeLogo.bind(this)} />
                                        </div>
                                        : this.picPreview()}
                                </div>
                            </div>
                        </div>

                        {this.props.match.params.id ? '' :
                            <div className="mui-panel">
                                <div className="form-heading">
                                    <h3 className="organization-info">Group Owners</h3>
                                    <p className="form-subheading">Each group needs two owners to be approved. You are the first owner.</p>
                                </div>

                                <div className="mui-row">
                                    <div className="mui-col-md-1">
                                        <legend className="circle"><span>1</span></legend>
                                    </div>
                                    <div className="form-inline-special mui-col-md-5">
                                        <legend>Name</legend>
                                        <input type="text" value={this.props.user.firstName + ' ' + this.props.user.lastName || ''} disabled />
                                    </div>

                                    <div className="form-inline-special mui-col-md-6">
                                        <legend>Email</legend>
                                        <Form.Field name="myEmail" placeholder={this.props.user.email} disabled/>
                                    </div>
                                </div>

                                <div className="mui-row">
                                    <div className="mui-col-md-1">
                                        <legend className="circle"><span>2</span></legend>
                                    </div>

                                    <div className="mui-col-md-11">
                                        <legend>Invite the second owner by entering an email address *<Form.Message className="has-error" for="groupOwnerEmail"/></legend>
                                        <Form.Field
                                            name="groupOwnerEmail"
                                            placeholder="Please enter a group owner email"
                                            className="group-owner-email"
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="footer-singleForm">
                            {this.state.group.savingGroup ?
                                <button type='submit' className="btn-flat btn-transparent">
                                    <div className="loader-small"></div>
                                </button>
                                :
                                <Form.Button type='submit' className="btn-flat btn-accentPrimary">
                                    {this.props.match.params.id ? 'Save Changes' : 'Create Group'}
                                </Form.Button>
                            }
                            {_.isEmpty(this.state.errors) ? null :
                                <p className="validation-error-near-button validation-error-general validation-error">
                                    <FontAwesome name='exclamation-circle' />
                                    Please fix errors
                                </p>
                            }
                        </div>
                    </div>
                </Form>
            );
        }

        let title = this.props.match.params.id ? 'Edit Group' : 'Create Group';

        return (
            <div className="groupPage">
                <Helmet>
                    <title>{title} | ZivugTech</title>
                </Helmet>
                <Subheader title={title} />
                <div className="mui-container">
                    <div className="content header-padding header-padding-subheader" id="form">
                        <div className="verify-panel">
                            <div className="panelContainer">
                                {this.props.user.approved ?
                                    content
                                    :
                                    <div className="mui-panel">
                                        <h3>Looks like you're not verified yet.</h3>
                                        {this.props.user.verificationImage && this.props.user.verificationImage.id ?
                                            <div>
                                                <p>Verification pending</p>
                                                <p className="helper-text">This may take up to 72 hours, please try back later.</p>
                                            </div>
                                            :
                                            <div>
                                                <p>Please verify your identity before creating a group.</p>
                                                <button onClick={()=>this.verifyUser()} className="btn-flat btn-primaryAccent">
                                                    Click here to get verified
                                                </button>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

CreateEditGroup.propTypes = {
    constants: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    group: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    actions: PropTypes.shape({
        loadGroupToEdit: PropTypes.func.isRequired,
        editGroup: PropTypes.func.isRequired,
        createNewGroup: PropTypes.func.isRequired
    })
};

function mapStateToProps(state) {
    return {
        constants: state.constants,
        isLoading: state.editGroup.isLoadingGroup,
        group: state.editGroup,
        user: state.user.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(groupEditActions,  dispatch),
        modalActions: bindActionCreators(modalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditGroup);