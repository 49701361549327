import * as types from '../actionTypes/notificationActionTypes.js';

export default function notificationsReducer(state={notifications: []}, action) {
    switch(action.type) {
        case types.LOAD_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: action.notifications
            };
        case types.NEW_NOTIFICATION_RECEIVED:
            return {
                ...state,
                notifications: [...state.notifications, action.data]
            };
        case types.NOTIFICATION_READ_SUCCESS:
            return {
                ...state,
                notifications: state.notifications.filter(n=> n.id !== action.notificationId)
            };
        case types.CLEAR_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: []
            };
        default:
            return state;
    }
}