import React from 'react';
import PropTypes from 'prop-types';
import Card from '../ideaCards/Card';

const styles = {
    display: 'inline-block',
    transform: 'rotate(-7deg)',
    WebkitTransform: 'rotate(-7deg)'
};

const CardDragPreview = (props) => {
    let width = `${props.card.clientWidth - 10 }px`;
    let height = `${props.card.clientHeight - 10 }px`;

    return (
        <div style={{
            ...styles,
            width,
            height
        }}>
            <Card item={props.card.item}/>
        </div>
    );
};

CardDragPreview.propTypes = {
    card: PropTypes.object
};

export default CardDragPreview;
