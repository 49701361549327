import * as types from '../actionTypes/groupActionTypes';
import history from '../history'
import { apiAuthRequest } from './helpers';
import { toastr } from 'react-redux-toastr';

export function loadGroupToEdit(id) {
    return async dispatch => {
        dispatch({ type: types.GET_GROUP_EDIT_START });
        let result = await apiAuthRequest('get', '/groups/editGroup', {id});
        if (result.success) {
            dispatch({
                type: types.GET_GROUP_EDIT_SUCCESS,
                group: result.data
            });
        } else {
            dispatch({ type: types.GET_GROUP_EDIT_FAIL });
            toastr.error(result.data);
        }
    }
}

export function createNewGroup(group) {
    return async dispatch => {
        dispatch({ type: types.CREATE_GROUP_START });
        if (group.logo.name) {
            let fd = new FormData();
            fd.append('image', group.logo, group.logo.name);
            let imageUploadResult = await apiAuthRequest('post', '/uploads/uploadGroupImage', fd);
            if (imageUploadResult.success) {
                delete group.logo;
                group.logoLocation = imageUploadResult.data.fileLocation;
            } else {
                toastr.error(imageUploadResult.data);
                return;
            }
        }
        let result = await apiAuthRequest('post', '/groups/createGroup', group);
        if (result.success) {
            dispatch({
                type: types.CREATE_GROUP_SUCCESS,
                analytics: {
                    event: 'create_group',
                    params: {
                        groupId: result.data.groupId
                    }
                }
            });
            history.push('/groups/' + result.data.groupId)
        } else {
            dispatch({ type: types.CREATE_GROUP_FAIL });
            toastr.error(result.data);
        }

    }
}

export function editGroup(group) {
    return async dispatch => {
        dispatch({ type: types.EDIT_GROUP_START });
        if (group.logo.name) {
            let fd = new FormData();
            fd.append('image', group.logo, group.logo.name);
            let imageUploadResult = await apiAuthRequest('post', '/uploads/uploadGroupImage', fd);
            if (imageUploadResult.success) {
                delete group.logo;
                group.logoLocation = imageUploadResult.data.fileLocation;
            } else {
                toastr.error(imageUploadResult.data);
                return;
            }
        }

        let result = await apiAuthRequest('post', '/groups/editGroup', group);
        if (result.success) {
            dispatch({ type: types.EDIT_GROUP_SUCCESS });
            history.push('/groups/' + group.id + '#updated')
        } else {
            dispatch({ type: types.EDIT_GROUP_FAIL });
            toastr.error(result.data);
        }
    }
}