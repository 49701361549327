import React, { Component } from "react";
import PropTypes from "prop-types";
import { DropTarget } from "react-dnd";
import { findDOMNode } from "react-dom";
import FontAwesome from "react-fontawesome";
import DraggableCard from "./DraggableCard";
import { Scrollbars } from "react-custom-scrollbars";

function getPlaceholderIndex(y, scrollY) {
  let placeholderIndex;

  const cardHeight = 126; // height of a single card(excluding marginBottom/paddingBottom)
  const cardMargin = 16; // height of a marginBottom+paddingBottom

  const offsetHeight = 180; // height offset from the top of the page

  // we start counting from the top of dragTarget
  const yPos = y - offsetHeight + scrollY;

  if (yPos < cardHeight / 2) {
    placeholderIndex = -1; // place at the start
  } else {
    placeholderIndex = Math.floor(
      (yPos - cardHeight / 2) / (cardHeight + cardMargin),
    );
  }

  return placeholderIndex;
}

const specs = {
  drop(props, monitor, component) {
    document.getElementById(monitor.getItem().id).style.display = "block";
    const { placeholderIndex } = component.state;
    const cardId = monitor.getItem().id;
    const lastX = monitor.getItem().x;
    const lastY = monitor.getItem().y;
    const nextX = props.x;
    let nextY = lastX === nextX ? placeholderIndex : placeholderIndex + 1;

    // if dragging to top
    if (lastX === nextX && lastY - nextY > 1) {
      nextY = nextY + 1;
      if (lastY - nextY === 1) {
        props.moveCard(cardId, lastX, lastY, nextX, nextY);
        return;
      }
    }

    if (
      (lastX === nextX && lastY === nextY) ||
      (lastX === nextX && nextY + 1 === lastY) ||
      nextY === -1
    ) {
      return;
    }

    props.moveCard(cardId, lastX, lastY, nextX, nextY);
  },
  hover(props, monitor, component) {
    // defines where placeholder is rendered
    const placeholderIndex = getPlaceholderIndex(
      monitor.getClientOffset().y,
      findDOMNode(component).scrollTop,
    );

    // IMPORTANT!
    // HACK! Since there is an open bug in react-dnd, making it impossible
    // to get the current client offset through the collect function as the
    // user moves the mouse, we do this awful hack and set the state (!!)
    // on the component from here outside the component.
    // https://github.com/gaearon/react-dnd/issues/179
    component.setState({ placeholderIndex });

    // when drag begins, we hide the card and only display cardDragPreview
    const item = monitor.getItem();
    document.getElementById(item.id).style.display = "none";
  },
};

// @DropTarget('card', specs, (connectDragSource, monitor) => ({
//     connectDropTarget: connectDragSource.dropTarget(),
//     isOver: monitor.isOver(),
//     canDrop: monitor.canDrop(),
//     item: monitor.getItem()
// }))
class CardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholderIndex: undefined,
      height: 0,
      width: 0,
      columnHeight: 0,
      activeScrollbar: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentWillReceiveProps() {
    this.handleResize();
  }

  handleResize = () => {
    const columnHeight = document.getElementsByClassName("ideas-column")
      ? document.getElementsByClassName("ideas-column")[0].clientHeight - 90
      : 400;
    this.setState({ columnHeight });
    this.forceUpdate();

    const { scrollbars } = this.refs;
    const { scrollHeight } = scrollbars.getValues();
    scrollHeight > columnHeight
      ? this.setState({ activeScrollbar: true })
      : this.setState({ activeScrollbar: false });
  };

  render() {
    const { connectDropTarget, x, cards, isOver, canDrop, name } = this.props;
    const { placeholderIndex } = this.state;

    let toPlaceFirst;
    let toPlaceLast;
    let cardList = [];
    cards.forEach((item, i) => {
      toPlaceFirst = false;
      if (isOver && canDrop && i === 0 && placeholderIndex === -1) {
        toPlaceFirst = true;
        cardList.push(
          <div key="placeholder" className="ideas-card placeholder" />,
        );
      }
      if (item !== undefined) {
        cardList.push(
          <DraggableCard
            x={x}
            y={i}
            item={item}
            key={item.id}
            openIdea={() =>
              this.props.openModal("IDEA", {
                idea: item,
              })
            }
          />,
        );
      }

      if (isOver && canDrop) {
        toPlaceLast = false;
        if (!toPlaceFirst && placeholderIndex > i) {
          toPlaceLast = true;
        } else if (!toPlaceFirst && !toPlaceLast && placeholderIndex === i) {
          cardList.push(
            <div key="placeholder" className="ideas-card placeholder" />,
          );
        }
      }
    });

    // if placeholder index is greater than array.length, display placeholder as last
    if (toPlaceLast) {
      cardList.push(
        <div key="placeholder" className="ideas-card placeholder" />,
      );
    }

    // if there is no items in ideaCards currently, display a placeholder anyway
    if (isOver && canDrop && cards.length === 0) {
      cardList.push(
        <div key="placeholder" className="ideas-card placeholder" />,
      );
    } else if (cards.length === 0) {
      cardList.push(
        <div key="empty" className="message-circle-empty">
          <FontAwesome
            name="lightbulb-o"
            className="message-circle-empty-icon"
          />
          <p>No ideas</p>
        </div>,
      );
    }

    return connectDropTarget(
      <div className="ideas-column-container">
        <div className="ideas-column">
          <div className="ideas-column-title">
            {name}
            <span>{cards.length}</span>
          </div>
          <Scrollbars
            style={{ height: this.state.columnHeight }}
            ref="scrollbars"
          >
            <div
              className={
                this.state.activeScrollbar
                  ? "idea-items idea-items-scrollbar"
                  : "idea-items"
              }
            >
              {cardList}
            </div>
          </Scrollbars>
          <p className="btn-idea" onClick={this.props.createNew}>
            Add Idea...
          </p>
        </div>
      </div>,
    );
  }
}

CardList.propTypes = {
  connectDropTarget: PropTypes.func,
  moveCard: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  cards: PropTypes.array.isRequired,
  x: PropTypes.number.isRequired,
  isOver: PropTypes.bool,
  item: PropTypes.object,
  canDrop: PropTypes.bool,
};
CardList = DropTarget("card", specs, (connectDragSource, monitor) => ({
  connectDropTarget: connectDragSource.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
  item: monitor.getItem(),
}))(CardList);
export default CardList;
