import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as constsActions from "../../../actions/constsActions";
import Textarea from "react-textarea-autosize";
import { findDOMNode } from "react-dom";
import scrollIntoView from "scroll-into-view";

class CreateResumeNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      personalDescription:
        "Im a friendly, easygoing and warm person. I try to be a good listener and to be considerate of other people. Some of my favorite activities are playing sports, reading, watching interesting movies and spending time outdoors. I appreciate my quiet time at home too. Kindness is a very important quality to me and I believe in being flexible and having an open mind.",
      requiredField: true,
    };
  }

  handleClickOutside = (event) => {
    console.log(event.target);
    if (this.state.editMode) {
      this.setState({ editMode: false });
    }
  };

  scrollTo(ref) {
    scrollIntoView(findDOMNode(this.refs.personalDescription));
  }

  saveField(e) {
    if (this.state.requiredField && e.target.value == "") {
      console.log("required");
    }
    this.setState({ personalDescription: e.target.value });
  }

  typeTableRow(type) {
    return (
      <tr key={type.id}>
        <td className="resume-table-title">{type.text}:</td>
      </tr>
    );
  }

  render() {
    return (
      <div
        className={
          this.state.editMode
            ? "createResumePage editModePage"
            : "createResumePage"
        }
      >
        {/*<GroupsSubHeaderTitle title={title} />*/}
        <div className="mui-container-fluid">
          <div className="content header-padding header-padding-subheader">
            <div className="mui-col-md-9">
              <div className="panelContainer mui-panel">
                <div className="resume create-resume">
                  <div className="resume-header">
                    <img
                      src="/images/xl-placeholder.png"
                      alt=""
                      className="resume-header-image"
                      height="125"
                      width="125"
                    />

                    <div className="resume-header-info">
                      <h2 className="resume-name" ref="name">
                        Elazar Alper <span>(Elazer Chaim)</span>
                      </h2>
                      <div className="resume-header-details">
                        <p>
                          <span className="resume-header-detail-title">
                            Age
                          </span>
                          <span>28</span>
                        </p>
                        <p>
                          <span className="resume-header-detail-title">
                            Height
                          </span>
                          <span>5'8"</span>
                        </p>
                        <p>
                          <span className="resume-header-detail-title">
                            Marital Status
                          </span>
                          <span>Single</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="resume-content">
                    <div className="resume-column-left">
                      <div className="resume-section">
                        <div className="personal-info">
                          <h3>Personal Info</h3>
                          <table className="resume-table">
                            <tbody>
                              <tr>
                                <td>Gender:</td>
                                <td className="right-column">Male</td>
                              </tr>
                              <tr>
                                <td>Ethnicity:</td>
                                <td className="right-column">Ashkenazi</td>
                              </tr>
                              <tr>
                                <td>Languages:</td>
                                <td>English</td>
                              </tr>
                              <tr>
                                <td>Religious Background:</td>
                                <td className="right-column">FFB</td>
                              </tr>
                              <tr>
                                <td>Cohen:</td>
                                <td className="right-column">No</td>
                              </tr>
                              <tr>
                                <td>Address:</td>
                                <td className="right-column">
                                  149 121st Boulevard, Queens NY
                                </td>
                              </tr>
                              <tr>
                                <td>Hometown:</td>
                                <td className="right-column">Queens</td>
                              </tr>
                              <tr>
                                <td>Willing to Relocate:</td>
                                <td className="right-column">No</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="edit">
                          <p className="btn-flat btn-view">
                            Edit <FontAwesome name="pencil" />
                          </p>
                        </div>
                      </div>

                      <div
                        className={
                          this.state.editMode
                            ? "resume-section editMode"
                            : "resume-section"
                        }
                      >
                        <h3 ref="personalDescription">Personal Description</h3>
                        {this.state.editMode ? (
                          <Textarea
                            name="personalDescription"
                            ref="desc"
                            type="text"
                            onChange={this.saveField.bind(this)}
                            value={this.state.personalDescription}
                            className="resize"
                          />
                        ) : (
                          <p>{this.state.personalDescription}</p>
                        )}

                        <div
                          className="edit"
                          onClick={() => this.setState({ editMode: true })}
                        >
                          <p className="btn-flat btn-edit">
                            Edit <FontAwesome name="pencil" />
                          </p>
                        </div>
                        <div className="editor">
                          <p
                            onClick={() =>
                              this.state.editMode
                                ? this.setState({ editMode: false })
                                : ""
                            }
                          >
                            <FontAwesome name="check" />
                          </p>
                        </div>
                      </div>

                      <div className="resume-section">
                        <h3 ref="personalDescription">Looking For</h3>
                        <p>
                          I'm looking for a life partner and see a healthy
                          relationship as being best friends who look up to each
                          other, respect each other, grow together, and make
                          each other happy.
                        </p>
                        <div className="edit">
                          <p className="btn-flat btn-view">
                            Edit <FontAwesome name="pencil" />
                          </p>
                        </div>
                      </div>

                      <div className="resume-section">
                        <h3>Family</h3>
                        <ul>
                          <li>
                            <p>Michael Alper</p>
                            <p>Father</p>
                          </li>
                          <li>
                            <p>Janette Alper</p>
                            <p>Mother</p>
                          </li>
                          <li>
                            <p>Natan Alper</p>
                            <p>Brother</p>
                          </li>
                          <li>
                            <p>Chaim Leib Alper</p>
                            <p>Brother</p>
                          </li>
                          <li>
                            <p>Moshe Alper</p>
                            <p>Grandfather</p>
                            <p>
                              Long Note Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit. Alias amet consequatur corporis
                              delectus eaque enim in iure labore modi nobis non
                              officia quam quas quis ratione repellat, sed,
                              soluta vel?
                            </p>
                          </li>
                        </ul>
                        <div
                          className="edit"
                          onClick={() => this.setState({ editMode: true })}
                        >
                          <p className="btn-flat btn-edit">
                            Edit <FontAwesome name="pencil" />
                          </p>
                        </div>
                        <div className="editor">
                          <p
                            onClick={() =>
                              this.state.editMode
                                ? this.setState({ editMode: false })
                                : ""
                            }
                          >
                            <FontAwesome name="check" />
                          </p>
                        </div>
                      </div>

                      <div className="resume-section">
                        <h3>Occupation</h3>
                        <p>Special Education High School Science Teacher</p>
                        <p>Date Years</p>
                        <p>Description / Details</p>
                        <div
                          className="edit"
                          onClick={() => this.setState({ editMode: true })}
                        >
                          <p className="btn-flat btn-edit">
                            Edit <FontAwesome name="pencil" />
                          </p>
                        </div>
                        <div className="editor">
                          <p
                            onClick={() =>
                              this.state.editMode
                                ? this.setState({ editMode: false })
                                : ""
                            }
                          >
                            <FontAwesome name="check" />
                          </p>
                          <p>
                            <FontAwesome name="plus" />
                          </p>
                        </div>
                      </div>

                      <div className="resume-section">
                        <h3 ref="test">Shul</h3>
                        <p className="shul-name">Khal Yisrael</p>
                        <p className="rabbi-name">Rabbi Weiss</p>
                        <p className="rabbi-phone">732-356-7654</p>
                        <p className="shul-details">Near Columbus Ave.</p>
                        <div className="edit">
                          <p className="btn-flat btn-view">
                            Edit <FontAwesome name="pencil" />
                          </p>
                        </div>
                      </div>

                      <div className="resume-section">
                        <h3 ref="test">Camps</h3>
                        <p>Name</p>
                        <p>Years</p>
                        <div className="edit">
                          <p className="btn-flat btn-view">
                            Edit <FontAwesome name="pencil" />
                          </p>
                        </div>
                      </div>

                      <div className="resume-section">
                        <h3 ref="test">
                          Other / Organization / Volunteer Work
                        </h3>
                        <p>Name</p>
                        <p>Years</p>
                        <p>Description</p>
                        <div className="edit">
                          <p className="btn-flat btn-view">
                            Edit <FontAwesome name="pencil" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="resume-column-right">
                      <div className="resume-section">
                        <h3>My Contact Info</h3>
                        <p>718-705-2656</p>
                        <p>elazar.alper@gmail.com</p>
                        <p>Advice to contact me</p>
                        <div
                          className="edit"
                          onClick={() => this.setState({ editMode: true })}
                        >
                          <p className="btn-flat btn-edit">
                            Edit <FontAwesome name="pencil" />
                          </p>
                        </div>
                        <div className="editor">
                          <p
                            onClick={() =>
                              this.state.editMode
                                ? this.setState({ editMode: false })
                                : ""
                            }
                          >
                            <FontAwesome name="check" />
                          </p>
                        </div>
                      </div>
                      <div className="resume-section">
                        <h3>
                          Resume Contact Info (checkbox option for same as above
                          - greyed)
                        </h3>
                        <p>Other Person</p>
                        <p>Mother</p>
                        <p>654-098-0987</p>
                        <p>test@gmail.com</p>
                        <div
                          className="edit"
                          onClick={() => this.setState({ editMode: true })}
                        >
                          <p className="btn-flat btn-edit">
                            Edit <FontAwesome name="pencil" />
                          </p>
                        </div>
                        <div className="editor">
                          <p
                            onClick={() =>
                              this.state.editMode
                                ? this.setState({ editMode: false })
                                : ""
                            }
                          >
                            <FontAwesome name="check" />
                          </p>
                        </div>
                      </div>

                      <div className="resume-section">
                        <h3>
                          Shadchan Contact Info (checkbox option for same as
                          Resume Contact Info - greyed)
                        </h3>
                        <p></p>
                        <p></p>
                        <p></p>
                        <div
                          className="edit"
                          onClick={() => this.setState({ editMode: true })}
                        >
                          <p className="btn-flat btn-edit">
                            Edit <FontAwesome name="pencil" />
                          </p>
                        </div>
                        <div className="editor">
                          <p
                            onClick={() =>
                              this.state.editMode
                                ? this.setState({ editMode: false })
                                : ""
                            }
                          >
                            <FontAwesome name="check" />
                          </p>
                        </div>
                      </div>

                      <div className="resume-section">
                        <h3>References</h3>
                        <ul>
                          <li>
                            <p>Rabbi Marc Penner</p>
                            <p className="text-diff">Rabbi</p>
                            <p>646-660-5858</p>
                            <p>test@gmail.com</p>
                            <p>Please only call after 10pm</p>
                          </li>
                          <li>
                            <p>Gili Reichman</p>
                            <p className="text-diff">Friend</p>
                            <p>347-563-2264</p>
                            <p>test@gmail.com</p>
                            <p>Please only call after 10pm</p>
                          </li>
                        </ul>
                        <div
                          className="edit"
                          onClick={() => this.setState({ editMode: true })}
                        >
                          <p className="btn-flat btn-edit">
                            Edit <FontAwesome name="pencil" />
                          </p>
                        </div>
                        <div className="editor">
                          <p
                            onClick={() =>
                              this.state.editMode
                                ? this.setState({ editMode: false })
                                : ""
                            }
                          >
                            <FontAwesome name="check" />
                          </p>
                        </div>
                      </div>

                      <div className="resume-section">
                        <h3>Education</h3>
                        <ul>
                          <li>
                            <p>Elementary: Har Torah</p>
                            <p className="years">Date Years</p>
                          </li>
                          <li>
                            <p>High school: Rambam Mesivta</p>
                            <p className="years">Date Years</p>
                          </li>
                          <li>
                            <p>Yesodai Hatorah</p>
                            <p className="years">Date Years</p>
                          </li>
                          <li>
                            <p>Yeshiva University</p>
                            <p className="years">Date Years</p>
                          </li>
                          <li>
                            <p>Brooklyn College</p>
                            <p>Masters in Special Education</p>
                          </li>
                        </ul>

                        <div
                          className="edit"
                          onClick={() => this.setState({ editMode: true })}
                        >
                          <p className="btn-flat btn-edit">
                            Edit <FontAwesome name="pencil" />
                          </p>
                        </div>
                        <div className="editor">
                          <p
                            onClick={() =>
                              this.state.editMode
                                ? this.setState({ editMode: false })
                                : ""
                            }
                          >
                            <FontAwesome name="check" />
                          </p>
                          <p>
                            <FontAwesome name="plus" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="resume-sidebar-container mui-col-md-2">
              <div className="resume-sidebar">
                <p>
                  This is your tip: Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit. A alias at atque consectetur hic impedit
                  inventore, itaque, iure obcaecati quibusdam quo quod rerum ut.
                  Dolor fuga magni natus nemo totam.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Ducimus eaque eligendi eos error et exercitationem iure
                  laborum modi molestiae mollitia natus nihil nulla odit optio
                  porro quis reiciendis, sed similique?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    constsActions: bindActionCreators(constsActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    constants: state.constants,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateResumeNew);
