import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as modalActions from '../../../actions/modalActions';
import * as singleResumeActions from '../../../actions/singleResumeActions';
import * as conversationActions from '../../../actions/singleConversationActions';
import FontAwesome from 'react-fontawesome';
import SingleConversation from '../../inbox/SingleConversation';
import { Link } from 'react-router-dom';


class SidebarConversations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            conversationSelected: (this.props.selectedConversation)
        };
        this.timezone = moment.tz.guess();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.currentConversation){
            if(nextProps.currentConversation.id === nextProps.selectedConversation
                && nextProps.currentConversation.resumes.find(r=>r.resumeId === nextProps.resume.id)) {
                this.setState({conversationSelected: true});
            } else {
                this.setState({conversationSelected: false});
            }
        }
    }

    shareToUserModal() {
        this.props.modalActions.setModal('SHARE', {
            shareTo: 'user',
            closeOnOutsideClick: true
        });
    }

    selectConversation(id) {
        this.setState({conversationSelected: true}, () => {
            this.props.conversationActions.getSidebarConversationById(id, true);
            this.props.selectConversation(id);
        });
    }

    sendMessage(message) {
        this.props.conversationActions.sendMessage(message, true);
    }

    conversationRow(conversation) {
        let date = moment.tz(conversation.lastActive, this.timezone).fromNow();
        return (
            <li key={conversation.id} onClick={this.selectConversation.bind(this, conversation.id)}>
                <div className="sidebar-list-item-info">
                    <h5 className="sidebar-list-item-title">{conversation.participantName}</h5>
                    <div className="sidebar-list-item-extra">
                        {conversation.suggestionType}
                    </div>
                    <p className="sidebar-list-item-info-details">
                        <span>{date}</span>
                    </p>
                    <p className="sidebar-list-item-btn-view">View <FontAwesome name='arrow-right' /></p>
                </div>
            </li>
        )
    }
    
    render(){
        let {resume} = this.props;

        let content = "";
        if(!this.state.conversationSelected){
            content =
            <div >
                {!resume.conversations.length ?
                    <div className="message-sidebar-empty">
                        {resume.published ?
                            <div>
                                <FontAwesome name='comment' className="message-sidebar-empty-icon" />
                                <p>You have not yet shared
                                    <span>{resume.firstName} {resume.lastName}'s</span>
                                    resume
                                </p>
                                <p className="btn-flat btn-sidebar" onClick={this.shareToUserModal.bind(this)}>
                                    Share this resume now <FontAwesome name='arrow-right' />
                                </p>
                            </div>
                        :
                            <p>This resume cannot be shared until it has been published</p>
                        }
                    </div>
                    :
                    <ul className="sidebar-content-list">
                        {resume.conversations.map((c) => this.conversationRow(c))}
                    </ul>
                }
            </div>
        } else if(this.props.isLoading || !this.props.currentConversation) {
            content = <div className="loader"></div>
        } else {
            content =
                <div className="container">
                    <div className="sidebar-buttons">
                        <p className="btn-sidebar-create" onClick={this.props.unSelectConversation}>
                            <FontAwesome name='arrow-left' /> Go Back
                        </p>
                        <Link key="Inbox" className="btn-sidebar-create" to={`/inbox/${this.props.currentConversation.id}`}>
                            View in inbox <FontAwesome name='arrow-right' />
                        </Link>
                    </div>

                    <SingleConversation
                        conversation={this.props.currentConversation}
                        sendMessage={this.sendMessage.bind(this)}
                        loadSingleResume={this.props.loadSingleResume.bind(this)}
                        isInbox={false}
                    />
                </div>
        }

        return (
            <div className="sidebar-conversations">
                {content}
            </div>
        )

    }
}


function mapDispatchToProps(dispatch) {
    return {
        singleResumeActions: bindActionCreators(singleResumeActions, dispatch),
        conversationActions: bindActionCreators(conversationActions, dispatch),
        modalActions: bindActionCreators(modalActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        currentConversation: state.sidebarConversation.conversation,
        isLoading: state.sidebarConversation.isLoading
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarConversations)
