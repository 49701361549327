import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Scrollbars } from 'react-custom-scrollbars';
import Popup from '../../common/Popup';
import FontAwesome from 'react-fontawesome';
import { Helmet } from "react-helmet";

import * as modalActions from '../../../actions/modalActions';

import GroupMainHeader from './GroupMainHeader';
import GroupMainAbout from './GroupMainAbout';
import GroupMainResumes from './GroupMainResumes';
import GroupMainMembers from './GroupMainMembers';

class GroupMain extends Component {
    static contextTypes = {
        objectHeights: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'about'
        }
    }

    changeActiveTab(tabId) {
       if(this.refs.groupInfoContainer){
            this.setState({activeTab: tabId});
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.props.group.id !== nextProps.group.id) {
            this.refs.groupMainInfo.scrollIntoView();
            this.setState({activeTab: 'about'});
        }
    }

    verifyUser(){
        this.props.modalActions.setModal('VERIFY_USER', {
            closeOnOutsideClick: true
        });
    }

    render() {
        let { group } = this.props;
        let content;

        if(group.memberAccessLevel < 5) {
            content = (
                <div className="container">
                    <div className="panel-actions panel-action-fullwidth panel-actions-bar">
                        <div className={ this.state.activeTab === 'about' ? "panel-action active" : "panel-action"}
                             onClick={()=>this.changeActiveTab('about')}>
                            <p>About</p>
                        </div>
                        <div className={ this.state.activeTab === 'resumes' ? "panel-action active" : "panel-action"}
                             onClick={()=>this.changeActiveTab('resumes')}>
                            <p>Resumes</p>
                        </div>
                        <div className={ this.state.activeTab === 'members' ? "panel-action active" : "panel-action"}
                             onClick={()=>this.changeActiveTab('members')}>
                            <p>Members</p>
                        </div>
                    </div>
                    <div className="section clearfix">
                        <div className={ this.state.activeTab === 'about' ? "sidebar-tab active" : "sidebar-tab"}>
                            <GroupMainAbout
                                group={group}
                            />
                        </div>
                        <div className={ this.state.activeTab === 'resumes' ? "sidebar-tab active" : "sidebar-tab"}>
                            <GroupMainResumes
                                addAlert={this.props.addAlert}
                                clearAlert={this.props.clearAlert}
                            />
                        </div>
                        <div className={ this.state.activeTab === 'members' ? "sidebar-tab active" : "sidebar-tab"}>
                            <GroupMainMembers
                                addAlert={this.props.addAlert}
                                clearAlert={this.props.clearAlert}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            content = (
                <div className="section clearfix">
                    <GroupMainAbout
                        group={group}
                    />
                </div>
            );
        }

        let accessLevel;
        switch(group.memberAccessLevel) {
            case 1:
                accessLevel = 'Owner';
                break;
            case 2:
                accessLevel = 'Admin';
                break;
            case 3:
                accessLevel = 'Moderator';
                break;
            case 4:
                accessLevel = 'Member';
                break;
            default:
                accessLevel = 'General';
        }
        let groupImage = group.logoLocation ? `../../api/images/group/${group.logoLocation}` : "../images/small-placeholder.png";

        let title = `${this.props.group.name} - Group | ZivugTech`;
        return(
            <div id="content-main" className={`header-padding column-layout-two ${this.props.displayMobile ? '' : 'mobile-hide'}`} ref="groupInfoContainer">
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <GroupMainHeader
                    addAlert={this.props.addAlert}
                    clearAlert={this.props.clearAlert}
                    displayMobile={this.props.displayMobile}
                    switchMobileTab={this.props.switchMobileTab}
                />

                <div className="content-padding-fixed-top">
                    {group.invite ?
                        this.props.user.approved || group.invite.groupAccessLevel.id > 1 ?
                            <div className="notifications-bar-container" onClick={()=>this.props.acceptInvite(group.invite.id)}>
                                <p>You have been invited to this group <span className="btn-notification">Accept Invite</span></p>
                            </div>
                            :
                            <div className="notifications-bar-container owner-bar">
                                <p>You have been invited as an owner.</p>
                                <p>Looks like you're not verified yet.</p>
                                {this.props.user.verificationImage && this.props.user.verificationImage.id ?
                                    <div>
                                        <p>Verification pending</p>
                                        <p className="helper-text">This may take up to 72 hours, please try back later.</p>
                                    </div>
                                    :
                                    <div>
                                        <p>Please verify your identity before accepting this invitation.</p>
                                        <button onClick={this.verifyUser} className="btn-flat btn-notification">
                                            Click here to get verified
                                        </button>
                                    </div>
                                }
                            </div>
                        : null
                    }

                    <Scrollbars style={{height: this.context.objectHeights.mainPanelCalculatedHeight }}>
                        <div className="content-padding" ref="groupMainInfo">
                            <div className="resume-header resume-section ">
                                <img src={groupImage} alt="" className="resume-header-image" height="125" width="125"/>

                                <div className="resume-header-info">
                                    <h2 className="resume-name resume-name-container">
                                        {group.name}
                                    </h2>
                                    <div className="resume-header-details">
                                        <p className="group-status">
                                            <FontAwesome name={group.privacy === 1 ? 'globe' : 'lock'} className='group-color-light'/>
                                            {` ${group.privacyLevel} Group`}
                                        </p>
                                        {group.memberAccessLevel > 4 ? '' :
                                            <p className="group-status"><FontAwesome name='user' className='group-color-light' /> {accessLevel}</p>
                                        }
                                        {group.approved ? '' :
                                            <Popup
                                                trigger={
                                                    <p className="group-status-pending"><FontAwesome name='hourglass-end' className='group-color-light' /> Pending</p>
                                                }
                                                content='Invite another owner to create group'
                                            />

                                        }
                                    </div>
                                </div>
                            </div>
                            {content}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

GroupMain.propTypes = {
    group: PropTypes.object,
    hasVerifiedResume: PropTypes.number,
    user: PropTypes.object,
    addAlert: PropTypes.func.isRequired,
    clearAlert: PropTypes.func.isRequired,
    displayMobile: PropTypes.bool,
    switchMobileTab: PropTypes.func.isRequired,
    acceptInvite: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        group: state.singleGroup.group,
        hasVerifiedResume: state.user.user.hasVerifiedResume,
        user: state.user.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        modalActions: bindActionCreators(modalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupMain);