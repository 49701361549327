import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';


export default class locationInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value || '',
        };
    }

    onChange = (value) => {
        this.props.onChange(value);
        this.setState({ value });
    };


    render() {
        const { value } = this.state;
        const inputProps = {
            placeholder: this.props.placeholder,
            value,
            onBlur: this.props.onBlur,
            onChange: this.onChange,
            autoComplete: "disabled"
        };

        return (
            <PlacesAutocomplete
                classNames={{
                    autocompleteContainer: this.props.className
                }}
                inputProps={inputProps}
            />
        );
    }
}
