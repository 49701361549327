import 'url-search-params-polyfill';

export function cutString(st, limit) {

    if(st.length > limit) {
        return st.substring(0, limit-3) + '...';
    }

    return st;
}

export function mergeCurrentItemsWithNextPage(currentArray,page,pageNumber,perPageAmount){
    let offset = (pageNumber - 1) * perPageAmount;
    let offsetPage = [...Array(offset).fill(null), ...page];
    let a = offsetPage.length >= currentArray.length ? offsetPage : currentArray;
    let b = offsetPage.length >= currentArray.length ? currentArray : offsetPage;
    return a.map((item, i)=>{
        if(!item && b[i]){
            return b[i]
        }
        return item;
    });
}

export function getNextUrlFromParams(search){
    const params = new URLSearchParams(search);
    let nextPath = params.get('next');
    return nextPath && nextPath[0] === '/' ? nextPath.substring(1) : nextPath;
}