import * as types from '../actionTypes/modalTypes';

export default function modalReducer(state={
    modalType: null,
    modalProps: {}
}, action) {
    switch(action.type) {
        case types.SET_MODAL: {
            const { modalType, modalProps } = action.data;
            return {
                modalType,
                modalProps
            };
        }
        case types.UNSET_MODAL: {
            return {
                modalType: null,
                modalProps: {}
            };
        }
        default:
            return state;
    }
}