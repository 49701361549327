import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import Popup from '../../common/Popup';
import GroupNote from "./groupNote";
import _ from 'lodash';

class SidebarGroups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            additionalInfoOpen: false
        };
    }

    shareToGroupModal = ()=> {
        this.props.setModal('SHARE', {
            shareTo: 'group',
            closeOnOutsideClick: true
        });
    };


    render(){
        let { resume } = this.props;

        return (
            <div className="sidebar-content-section">
                <h3>Resume is part of the following groups:</h3>
                {!resume.groupResumes.length ?
                    <div className="message-sidebar-empty">
                        <FontAwesome name='lightbulb-o'
                                     className="message-sidebar-empty-icon message-sidebar-empty-icon-inSection" />
                        <p>
                            <span>{resume.firstName} {resume.lastName}</span>
                            is not added to any groups yet
                        </p>
                        {resume.published ?
                            <p className="btn-flat btn-sidebar"
                               onClick={this.shareToGroupModal.bind(this)}>
                                Add resume to group now <FontAwesome name='arrow-right'/>
                            </p>
                            :
                            <p>This resume cannot be added to a group until it has been published</p>
                        }
                    </div>
                    :
                    <ul className="sidebar-content-list sidebar-content-list-groups">
                        {resume.groupResumes.map(groupResume => {
                            return (
                                <li key={`groups-${groupResume.groups.id}`}>
                                    <div className="group-name">
                                        <Link key={groupResume.groups.id} to={`/groups/${groupResume.groups.id}`}>
                                            {groupResume.groups.name}&nbsp;
                                            {!groupResume.approved ?
                                                <Popup
                                                    trigger={
                                                        <span  className="action-pending">Pending</span>
                                                    }
                                                    content='Pending approval by group admin'
                                                /> : null }
                                        </Link>
                                    </div>

                                    {groupResume.groupTags && groupResume.groupTags.length ?
                                        <div className="info">
                                            <h3>Group Tags</h3>
                                            <div className="tags">
                                                {groupResume.groupTags.map((t,i)=>
                                                    <span key={t.id} className="group-tags">
                                                        {t.tagText}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        : null }
                                    {groupResume.maritalInfo && !_.isEmpty(groupResume.maritalInfo) ?
                                        <div className="get-info info">
                                            <h2>Marital Info:</h2>
                                            {groupResume.maritalInfo.multipleMarriages ? <p>Multiple Marriages</p> : null}
                                            {groupResume.maritalInfo.widowed ? <p>Widowed</p> : null}
                                            {groupResume.maritalInfo.yearWidowedOrDivorced ?
                                                <p>Year Widowed/Divorced: <span>{groupResume.maritalInfo.yearWidowedOrDivorced}</span></p>
                                                : null
                                            }
                                            {groupResume.maritalInfo.getArrangerName &&
                                                <div>
                                                    <p>Get arranged by:</p>
                                                    <p><span>{groupResume.maritalInfo.getArrangerName}</span></p>
                                                    <p><span>{groupResume.maritalInfo.getArrangerAddress}</span></p>
                                                    <p><span>{groupResume.maritalInfo.getArrangerCity}, {groupResume.maritalInfo.getArrangerState}
                                                        {groupResume.maritalInfo.getArrangerZip}</span></p>
                                                    <p><span>{groupResume.maritalInfo.getArrangerPhone}</span></p>
                                                </div>
                                            }
                                        </div>
                                        : null}

                                    {groupResume.additionalInfo && !_.isEmpty(groupResume.additionalInfo) ?
                                        <div className="additional-info info">
                                            <h2>Additional Info:
                                                <FontAwesome
                                                    className="headerText-with-icon-icon"
                                                    name={this.state.additionalInfoOpen ? 'angle-up': 'angle-down'}
                                                    onClick={()=> this.setState({additionalInfoOpen: !this.state.additionalInfoOpen})}/>
                                            </h2>

                                            {this.state.additionalInfoOpen ?
                                                <div dangerouslySetInnerHTML={{__html: groupResume.additionalInfo.noteText}}></div>
                                            : null
                                            }
                                        </div>
                                        : null
                                    }

                                    {groupResume.note && groupResume.note.id ?
                                        <div className="note">
                                            <GroupNote note={groupResume.note} saveNote={this.props.saveGroupNote} resumeId={resume.id}/>
                                        </div>
                                        : null
                                    }

                                </li>
                            )
                        })
                        }
                    </ul>
                }
            </div>
        )

    }
}


export default SidebarGroups;
