import * as types from '../actionTypes/resumeActionTypes';

export default function singleResumeReducer(state = {}, action) {
	switch(action.type) {
		case types.LOAD_EDIT_RESUME_START:
			return {
				resume: state.resume,
				isLoading: true
			};
		case types.LOAD_EDIT_RESUME_SUCCESS:
			return {
				resume: action.resume,
				isLoading: false
			};
		case types.LOAD_EDIT_RESUME_FAIL:
			return {
				errorLoadingResume: true,
				isLoading: false
			};
		case types.CLEAR_EDIT_RESUME:
			return {
				resume: undefined,
				isLoading: false
			};
		case types.SAVE_RESUME_START:
			return {
				resume: action.resume,
				isLoading: true
			};
		case types.SAVE_RESUME_SUCCESS:
			return {
				resume: action.resume,
				isLoading: false
			};
		case types.SAVE_MY_RESUME_SUCCESS:
			return {
				resume: action.resume,
				isLoading: false
			};
		case types.CREATE_RESUME_START:
			return {
				resume: action.resume,
				isLoading: true
			};
		default:
			return state;
	}
}