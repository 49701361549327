import * as types from '../actionTypes/groupActionTypes';
import { apiAuthRequest } from './helpers';
import { toastr } from 'react-redux-toastr';


function approveResumeToGroupSuccess(resume) {
    return {
        type: types.APROVE_GROUPRESUME_SUCCESS,
        resume,
        analytics: {
            event: 'approve_resume_to_group',
            params: {
                resumeId: resume.resumeId,
                groupId: resume.groupId
            }
        }
    }
}

function addMyResumeToGroupPending(addedResume) {
    return {
        type: types.ADD_GROUPMYRESUME_PENDING,
        addedResume,
        analytics: {
            event: 'add_my_resume_to_group',
            params: {
                status: 'pending',
                groupId: addedResume.groupId
            }
        }
    }
}

function addMyResumeToGroupSuccess(addedResume) {
    return {
        type: types.ADD_GROUPMYRESUME_SUCCESS,
        addedResume,
        analytics: {
            event: 'add_my_resume_to_group',
            params: {
                status: 'approved',
                groupId: addedResume.groupId
            }
        }
    }
}

function shareResumeToGroupSuccess(addedResume) {
    return {
        type: types.SHARE_RESUME_TO_GROUP,
        addedResume,
        analytics: {
            event: 'share_resume_to_group',
            params: {
                resumeId: addedResume.resumeId,
                groupId: addedResume.groupId
            }
        }
    }
}

export function loadGroupResumes(id) {
    return async dispatch => {
        let result = await apiAuthRequest('get', '/groupResumes/getGroupResumes', {id});
        if(result.success){
            dispatch({
                type: types.GET_GROUP_RESUMES_SUCCESS,
                resumes: result.data
            });
        } else {
            console.error(result.data);
            toastr.error('Error loading resumes');
        }
    }
}

export function addMyResumeToGroup(groupId) {
    return async dispatch => {
        let result = await apiAuthRequest('post', '/groupResumes/addMyResumeToGroup', {groupId});
        if(result.success) {
            if (result.data.approved) {
                dispatch(addMyResumeToGroupSuccess(result.data));
            } else {
                dispatch(addMyResumeToGroupPending(result.data));
            }
        } else {
            console.error(result.data);
            toastr.error('Error adding resume to group');
        }
    }
}

export function shareResumeToGroup(resumeInfo, closeModal) {//groupId, resumeId, message
    return async dispatch => {
        let result = await apiAuthRequest('post', '/groupResumes/addResumeToGroup', resumeInfo);
        if (result.success) {
            dispatch(shareResumeToGroupSuccess(result.data));
        } else {
            console.error(result.data);
            toastr.error('Error sharing resume to group');
        }
        setTimeout(closeModal, 3000);
    }
}

export function approveResumeToGroup(groupResumeId) {
    return async dispatch => {
        let result = await apiAuthRequest('post', '/groupResumes/approveResumeToGroup', {id: groupResumeId});
        if(result.success){
            dispatch(approveResumeToGroupSuccess(result.data));
        } else {
            console.error(result.data);
            toastr.error('Error approving resume');
        }

    }
}

export function removeResumeFromGroup(groupResumeId) {
    return async dispatch => {
        let result = await apiAuthRequest('post', '/groupResumes/removeResume', {id: groupResumeId});
        if(result.success){
            dispatch({
                type: types.REMOVE_GROUPRESUME_SUCCESS,
                id: groupResumeId,
                groupId: result.data.groupId
            });
        } else {
            console.error(result.data);
            toastr.error('Error removing resume from group');
        }
    }
}