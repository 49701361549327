import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as groupResumeActions from '../../../actions/groupResumeActions';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import FontAwesome from 'react-fontawesome';
import PrivateImage from '../../common/privateImage';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

class GroupMainResumes extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {};

        this.actionsFormatter = this.actionsFormatter.bind(this);
        this.resumeActions = this.resumeActions.bind(this);
    }

    componentDidMount(){
        if(!this.props.group.resumes){
            this.props.actions.loadGroupResumes(this.props.group.id);
        }
    }

    removeResume(resumeId) {
        this.props.addAlert((
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, remove resume!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title="Are you sure?"
                onConfirm={()=>this.alertDeleteResume(resumeId)}
                onCancel={this.props.clearAlert}
            >
                Removing the resume removes it from the group.
            </SweetAlert>
        ));
    }

    alertDeleteResume(resumeId) {
        this.props.actions.removeResumeFromGroup(resumeId);
        this.props.clearAlert();
    }

    imageFormatter = (cell, row) => {
        if(row){
            return <PrivateImage
                    fileName={row.images.length ? row.images[0].fileLocation : ""}
                    id={row.resumeId}
                    section='resumeSmall'
                    className='groupImage'
                />
        }
    };

    actionsFormatter = (cell, row) => {
        return (
            <div>
                <p onClick={() => this.context.router.history.push('/resumes/' + row.resumeId)} className="action-text">
                    <FontAwesome name='file-text-o' className='group-color-light' /> View Resume
                </p>
                {this.resumeActions(row)}
            </div>
        );
    };

    nameFormatter = (cell) => {
        return (
            <span className="bold-text">{cell}</span>
        );
    };

    checkboxFormatter = (cell) => {
        return (
            <div>
                {cell ?
                    <FontAwesome name='check' className='group-color-light' />
                : null }
            </div>
        );
    };

    renderSizePerPageDropDown = props => {
        return (
            <div className='btn-group'>
                {
                    [ 50, 100, 500 ].map((n, idx) => {
                        const isActive = (n === props.currSizePerPage) ? 'active' : null;
                        return (
                            <span key={ idx } type='button' className={ `btn-sizeperpage ${isActive}` } onClick={ () => props.changeSizePerPage(n) }>{ n }</span>
                        );
                    })
                }
            </div>
        );
    };

    resumeActions(resume){
        if (this.props.group.memberAccessLevel < 4) {
            if (resume.approved) {
                return (
                    <div className="groups-resume-actions">
                        <p className="action-text" onClick={()=>this.removeResume(resume.id)}>
                            <FontAwesome name='trash' className='group-color-light'/> Remove
                        </p>
                    </div>
                );
            } else {
                return (
                    <div className="groups-resume-actions">
                        <p className="action-text" onClick={()=>this.props.actions.approveResumeToGroup(resume.id)}>
                            <FontAwesome name='check' className='group-color-light'/> Approve
                        </p>
                        <p className="action-text" onClick={()=>this.removeResume(resume.id)}>
                            <FontAwesome name='times' className='group-color-light'/> Decline
                        </p>
                    </div>
                );
            }
        } else {
            if(resume.approved) {
                return (
                    <div className="groups-resume-actions">
                        <p className="action-text"><FontAwesome name='calendar-check-o' /> Added: {resume.dateAdded}</p>
                    </div>
                );
            } else {
                return (
                    <div className="groups-resume-actions">
                        <p className="action-text"><FontAwesome name='hourglass' /> Pending</p>
                    </div>
                );
            }
        }
    }

    render() {
        let { group } = this.props;
        if(!group.approved){
            return (
                <div className="column-content-message">
                    <p>Group must be approved before adding resumes</p>
                </div>
            );
        }

        if(!group.resumes){
            return (
                <div className="column-content-message">
                    <div className="loader"></div>
                </div>
            );
        }

        
        let groupResumes = group.resumes.map(gr => {
            const education = gr.resumes.education.shift();
            return {
                ...gr.resumes,
                education: education?.name,
                occupation: gr.resumes.occupation.name,
                id: gr.id,
                resumeId: gr.resumes.id,
                dateAdded: moment(gr.dateAdded).format('l'),
                approved: gr.approved
            };
        });

        return (
            <div className="container AutoSizerWrapper">
                <BootstrapTable
                    data={groupResumes}
                    fetchInfo={ {dataTotalSize: group.resumes.length} }
                    remote={ false }
                    pagination
                    keyBoardNav
                    search
                    exportCSV={ group.memberAccessLevel === 1 }
                    options={ { sizePerPageDropDown: this.renderSizePerPageDropDown } }
                    hover striped
                >
                    <TableHeaderColumn isKey dataField='resumeId' dataSort width='50'
                                       dataAlign='center'>ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='image' width='100' export={ false }
                                       dataFormat={ this.imageFormatter }
                                       dataAlign='center'>Image</TableHeaderColumn>
                    <TableHeaderColumn dataField='name' dataSort={ true }
                                       dataFormat={ this.nameFormatter }
                                       width='200'>Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='age' dataSort={ true }
                                       width='80'>Age</TableHeaderColumn>
                    <TableHeaderColumn dataField='height' dataSort={ true }
                                       width='80'>Height</TableHeaderColumn>
                    <TableHeaderColumn dataField='maritalStatus' dataSort={ true }
                                       width='100'>Status</TableHeaderColumn>
                    <TableHeaderColumn dataField='dateAdded' dataSort={true}
                                       width='100'>Date Added</TableHeaderColumn>
                    <TableHeaderColumn dataField='gender' dataSort={ true } hidden export={true} 
                                       width='80'>Gender</TableHeaderColumn>
                    <TableHeaderColumn dataField='shadchanName' dataSort={ true } hidden export={true} 
                                       width='80'>shadchanName</TableHeaderColumn>
                    <TableHeaderColumn dataField='shadchanEmail' dataSort={ true } hidden export={true} 
                                       width='80'>shadchanEmail</TableHeaderColumn>
                    <TableHeaderColumn dataField='shadchanPhone' dataSort={ true } hidden export={true} 
                                       width='80'>shadchanPhone</TableHeaderColumn>
                    <TableHeaderColumn dataField='city' dataSort={ true } hidden export={true} 
                                       width='80'>city</TableHeaderColumn>
                    <TableHeaderColumn dataField='hometown' dataSort={ true } hidden export={true} 
                                       width='80'>hometown</TableHeaderColumn>
                    <TableHeaderColumn dataField='email' dataSort={ true } hidden export={true} 
                                       width='80'>email</TableHeaderColumn>
                    <TableHeaderColumn dataField='mobilePhone' dataSort={ true } hidden export={true} 
                                       width='80'>mobilePhone</TableHeaderColumn>
                    <TableHeaderColumn dataField='homePhone' dataSort={ true } hidden export={true} 
                                       width='80'>homePhone</TableHeaderColumn>
                    <TableHeaderColumn dataField='contactEmail' dataSort={ true } hidden export={true} 
                                       width='80'>contactEmail</TableHeaderColumn>
                    <TableHeaderColumn dataField='contactPhone' dataSort={ true } hidden export={true} 
                                       width='80'>contactPhone</TableHeaderColumn>
                    <TableHeaderColumn dataField='occupation' dataSort={ true } hidden export={true} 
                                       width='80'>occupation</TableHeaderColumn>
                    <TableHeaderColumn dataField='education' dataSort={ true } hidden export={true} 
                                       width='80'>education</TableHeaderColumn>
                    <TableHeaderColumn dataField='approved' dataSort={true}  dataFormat={ this.checkboxFormatter }
                                       width='50'>Approved</TableHeaderColumn>
                    <TableHeaderColumn dataFormat={ this.actionsFormatter }
                                       width='170'>Actions</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

GroupMainResumes.propTypes = {
    group: PropTypes.object,
    hasVerifiedResume: PropTypes.number,
    addAlert: PropTypes.func.isRequired,
    clearAlert: PropTypes.func.isRequired,
    actions: PropTypes.shape({
        approveResumeToGroup: PropTypes.func.isRequired,
        removeResumeFromGroup: PropTypes.func.isRequired,
        loadGroupResumes: PropTypes.func.isRequired
    })
};

function mapStateToProps(state) {
    return {
        group: state.singleGroup.group,
        hasVerifiedResume: state.user.user.hasVerifiedResume
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(groupResumeActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupMainResumes);