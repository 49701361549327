import React from 'react';
import TextArea from 'react-textarea-autosize';

class ResizableTextArea extends React.Component {
    constructor(props) {
        super(props);
        this.onChangeText = this.onChangeText.bind(this);
    }

    onChangeText(e) {
        const target = e.target.value;
        this.props.onChange(target);
    }

    render() {
        return <TextArea
            placeholder={this.props.placeholder}
            className={this.props.className}
            name={this.props.name}
            value={this.props.value || ''}
            onBlur={this.props.onBlur}
            onChange={this.onChangeText}
        />
    }
}

export default ResizableTextArea;