import * as types from '../actionTypes/conversationActionTypes';
import * as fromResumeTypes from '../actionTypes/sidebarConversationActionTypes';
import { apiAuthRequest } from './helpers';
import { toastr } from 'react-redux-toastr';


export function getConversationById(id) {
	return async dispatch => {
		dispatch({
            type: types.LOAD_CONVERSATION_START
        });

		let result = await apiAuthRequest('get', '/inbox/getById', {id});
		if(result.success) {
            dispatch({
                type: types.LOAD_CONVERSATION_SUCCESS,
                conversation: result.data
            });
        } else {
			console.error(result.data);
			toastr.error('Error loading conversation');
		}
	}
}

export function getSidebarConversationById(id) {
	return async dispatch => {
		dispatch({
            type: fromResumeTypes.LOAD_SIDEBAR_CONVERSATION_START
        });
		let result = await apiAuthRequest('get', '/inbox/getById', {id});
        if(result.success) {
            dispatch({
                type: fromResumeTypes.LOAD_SIDEBAR_CONVERSATION_SUCCESS,
                conversation: result.data
            });
        } else {
            console.error(result.data);
            toastr.error('Error loading conversation');
        }
	}
}

function sendMessageStart(conversationId, fromResume) {
	return {
		type: fromResume ?
			fromResumeTypes.SEND_MESSAGE_ON_SIDEBAR_START :
			types.SEND_MESSAGE_START,
		conversationId
	}
}
function messageSentSuccess(message, fromResume) {
	return {
		type: fromResume ?
			fromResumeTypes.SEND_MESSAGE_ON_SIDEBAR_SUCCESS :
			types.SEND_MESSAGE_SUCCESS,
		message
	}
}


export function sendMessage(message, fromResume) {
	return async dispatch => {
		dispatch(sendMessageStart(message.conversationId), fromResume);
		let result = await apiAuthRequest('post', '/inbox/reply', message);
		if(result.success){
			dispatch(messageSentSuccess(result.data, fromResume));
		}
	}
}
export function markUnread(conversationId) {
	return async dispatch => {
		let result = await apiAuthRequest('post', '/inbox/markUnread', {conversationId: conversationId});
		if(result.success){
			dispatch({
                type: types.MARK_CONVERSATION_UNREAD,
                conversationId
            });
		}
	}
}
export function marksAsRead(conversationId) {
    return async dispatch => {
        let result = await apiAuthRequest('post', '/inbox/markAsRead', {conversationId: conversationId});
        if(result.success){
            dispatch({
                type: types.MARK_CONVERSATION_READ,
                conversationId
            });
        }
    }
}
export function archiveConversation(conversation) {
	return async dispatch => {
        let result = await apiAuthRequest('post', '/inbox/archive', {conversationId: conversation.id});
        if(result.success){
			dispatch({
                type: types.ARCHIVE_CONVERSATION,
                conversation
            });
		}
	}
}
export function unarchiveConversation(conversation) {
	return async dispatch => {
        let result = await apiAuthRequest('post', '/inbox/unarchive', {conversationId: conversation.id});
        if(result.success){
			dispatch({
				type: types.UNARCHIVE_CONVERSATION,
				conversation
			});
		}
	}
}