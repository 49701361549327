import * as types from '../actionTypes/groupActionTypes';
import {mergeCurrentItemsWithNextPage} from '../components/common/utils';

export default function groupReducer(state={groups: [], pagination: { page: 1 }, isLoading: 0, search: {}}, action) {
    switch(action.type) {
        case types.LOAD_GROUPS_START:
            return {
                ...state,
                isLoading: state.isLoading || state.isLoading === 0 ? state.isLoading + 1 : 1,
                groups: action.search.pageNumber === 1 ? []: state.groups,
                search: action.search
            };
        case types.LOAD_GROUPS_SUCCESS:
            let pageInfo = action.groups.pagination;
            let newGroups = mergeCurrentItemsWithNextPage(state.groups, action.groups.groups, pageInfo.page, pageInfo.pageSize);

            return {
                ...state,
                groups: newGroups,
                pagination: { ...state.pagination, ...action.groups.pagination},
                isLoading: state.isLoading ? state.isLoading - 1 : 0,
            };
        case types.APROVE_GROUPRESUME_SUCCESS:
            return {
                ...state,
                groups: state.groups.map(group => {
                    if (group.id === action.resume.groupId) {
                        group.resumeCount++;
                    }
                    return group;
                })
            };
        case types.ADD_GROUPMYRESUME_SUCCESS:
            return {
                ...state,
                groups: state.groups.map(group => {
                    if (group.id === action.addedResume.groupId) {
                        group.resumeCount++;
                    }
                    return group;
                }),
            };
        case types.SHARE_RESUME_TO_GROUP:
            return {
                ...state,
                groups: action.addedResume.approved ?
                    state.groups.map(group => {
                        if (group.id === action.addedResume.groupId) {
                            group.resumeCount++;
                        }
                        return group;
                    })
                    : state.groups
            };
        case types.REMOVE_GROUPRESUME_SUCCESS:
            return {
                ...state,
                groups: state.groups.map(group => {
                    if (group.id === action.groupId) {
                        group.resumeCount--;
                    }
                    return group;
                })
            };
        case types.ADD_GROUPMEMBER_REQUEST_SUCCESS:
            return {
                ...state,
                groups: state.groups.map(group => {
                    if (group.id === action.member.groupId) {
                        group.membersCount++;
                    }
                    return group;
                })
            };
        case types.ACCEPT_GROUP_MEMBER_INVITE:
            return {
                ...state,
                groups: state.groups.map(group => {
                    if (group.id === action.groupId) {
                        group.membersCount++;
                        group.isMember = true;
                        delete group.invited;
                        if(!group.approved){
                            group.approved = true;
                        }
                    }
                    return group;
                })
            };
        case types.REMOVE_GROUPMEMBER_SUCCESS:
            return {
                ...state,
                groups: state.groups.map(group => {
                    if (group.id === action.groupId) {
                        group.membersCount--;
                    }
                    return group;
                })
            };
        case types.LEAVE_GROUPMEMBER_SUCCESS:
            return {
                ...state,
                groups: state.groups.map(group => {
                    if (group.id === action.groupId) {
                        group.isMember = false;
                        group.membersCount--;
                    }
                    return group;
                })
            };
        default:
            return state;
    }
}
