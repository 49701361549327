import React from 'react';
import { connect } from 'react-redux';
import { apiAuthRequest } from '../../../actions/helpers';
import { bindActionCreators } from 'redux';
import * as groupMemberActions from '../../../actions/groupMemberActions';
import yup from 'yup';
import Form from 'react-formal';
import DropdownList from 'react-widgets/lib/DropdownList';

const groupInviteSchema = yup.object().shape({
    groupId: yup.mixed(),
    memberEmail: yup.string().email('Invalid email').required('Required')
        .test('userExists', 'Already a member of this group.', function (value){
            let params = {memberEmail: value, groupId: this.parent.groupId};
            return apiAuthRequest('get', '/groupMembers/checkGroupMemberExists', params)
                .then(response => !response.data)
        }),
    groupAccessLevel: yup.mixed()
});

class inviteMemberToGroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            member: {
                groupId: this.props.group.id,
                nameEmail: '',
                groupAccessLevel: props.groupApproved ? 4 : 1
            }
        };
        this.inviteToGroup = this.inviteToGroup.bind(this);
    }

    inviteToGroup(values) {
        this.setState({submitted: true});
        this.props.actions.inviteMemberToGroup(values, this.props.removeModal);
    }

    render() {
        let accessList = [{id:4, text:'Member'}, {id:3, text:'Moderator'}, {id:2, text:'Admin'}, {id:1, text:'Owner'}];
        if(!this.props.groupApproved){
            accessList = accessList.filter(i=>i.id === 1);
        } else if(this.props.myAccessLevel > 1){
            accessList = accessList.filter(i=>i.id > 1);
        }

        return (
            <div className="medium-modal invite-to-group-modal">
                {!this.state.submitted ?
                    <div>
                        <div className="mui-row modal-header">
                            <p>Invite {accessList.length === 1 ? 'Owner' : 'Member'} to Group</p>
                            <i className="material-icons modal-close-icon"
                               onClick={this.props.removeModal.bind(this)}>close</i>
                        </div>
                        <div className="modal-content-container">
                            <Form schema={groupInviteSchema}
                                  value={this.state.member}
                                  onSubmit={this.inviteToGroup}
                                  onChange={member=>this.setState({member})}
                                  className="group-modal-form" id="form">
                                <legend>Email Address *
                                    <Form.Message className='has-error' for='memberEmail'/>
                                </legend>
                                <Form.Field
                                    name="memberEmail"
                                    placeholder="Please enter an email address"
                                    className="form-input-margin-bottom"/>
                                {accessList.length > 1 ?
                                    <Form.Field
                                        type={DropdownList}
                                        name="groupAccessLevel"
                                        className="form-select form-input-margin-bottom"
                                        data={accessList}
                                        mapFromValue={ item => item.id }
                                        valueField='id'
                                        textField='text'
                                    />
                                    :
                                    <div>
                                        {/*{`Group ${accessList[0].text}`}*/}
                                    </div>
                                }

                                <Form.Button type="submit" className="btn-flat btn-accentPrimary btn-fullWidth">
                                    Invite
                                </Form.Button>
                            </Form>
                        </div>
                    </div>
                    :
                    <div className="modal-response">
                        <div className="envelope">
                            <div className="envelope-outline">
                                <div className="triangle"></div>
                            </div>
                            <div className="dot dot1"></div>
                            <div className="dot dot2"></div>
                            <div className="dot dot3"></div>
                            <div className="dot dot4"></div>
                            <div className="dot dot5"></div>
                            <div className="dot dot6"></div>
                        </div>
                        <p>Invite Sent</p>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        group: state.singleGroup.group,
        constants: state.constants
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(groupMemberActions,  dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(inviteMemberToGroupModal);