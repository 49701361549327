import * as types from '../actionTypes/welcomeActionTypes';

export default function welcomeReducer(state={ welcomeLoaded: false, steps: []}, action) {
    switch(action.type) {
        case types.LOAD_WELCOME_SUCCESS:
            return {
                welcomeLoaded: true,
                steps: action.steps
            };
        case types.ADD_WELCOME_SUCCESS:
            return {
                ...state,
                steps: [...state.steps, action.step]
            };
        default:
            return state;
    }
}