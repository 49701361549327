import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';

class GroupMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accessMenuActive: false
        };
        this.updateMemberAccessState = this.updateMemberAccessState.bind(this);
    }

    updateMemberAccessState(memberId, event) {
        let option = event.target.value;
        this.props.updateMemberAccessLevel(memberId, option);
    }

    render() {
        let { member, myAccessLevel, groupApproved, numberOfOwners } = this.props;
        let memberItem = member.users;
        let memberAccess;
        const formattedDateApproved = moment(member.dateAdded).format('MMMM Do, YYYY');

        if(member.access.id === 1 && (!member.isMe || numberOfOwners <= 2) ){ //if this member is not the current user and is an owner
            memberAccess = <p>Group Owner</p>
        } else if(!member.isMe && member.access.id === 2 && myAccessLevel === 2) {
            memberAccess = <p>Group Admin</p>
        } else if( myAccessLevel <= 2 && groupApproved ) {
            let groupMemberAccess = [{id:4, text:'Member'}, {id:3, text:'Moderator'}, {id:2, text:'Admin'}];
            if(myAccessLevel === 1){
                groupMemberAccess.push({id:1, text:'Owner'})
            }

            memberAccess = (
                <div className="mui-col-md-2 list-item">
                    <div className="icon" onClick={() => this.setState({accessMenuActive: !this.state.accessMenuActive})}>
                        <FontAwesome name='gear' />
                        <p>Change Access Level ({member.access.text})
                            <FontAwesome name='angle-down' />
                        </p>
                    </div>
                    <div className={this.state.accessMenuActive ? 'dropdown-icon dropdown-icon-active' : 'dropdown-icon'}>
                        <select className="form-select-access" onChange={(event) => this.updateMemberAccessState(member.id, event)} value={member.access.id}>
                            {groupMemberAccess.map(option => <option key={option.id} value={option.id}>{option.text}</option>)}
                        </select>
                    </div>
                    {!member.isMe &&
                    <div className="groups-resume-actions">
                        <button className="btn-flat btn-secondary btn-fullWidth" onClick={()=>this.props.removeMember(member.id)}>Remove</button>
                    </div>
                    }
                </div>
            );
        }

        return(
            <div key={member.id} className="mui-row list-items">
                <div className="list-item">
                    <h5>{memberItem.firstName} {memberItem.lastName}
                        <span className="accent-text">{member.isMe ? ' (you)': ''}</span>
                    </h5>
                    <h6 className="list-item-secondary">({memberItem.email})</h6>
                    <p><span>Joined on:</span> {formattedDateApproved}</p>
                    {memberAccess}
                </div>
            </div>
        );
    }
}

GroupMember.propTypes = {
    member: PropTypes.object.isRequired,
    numberOfOwners: PropTypes.number,
    myAccessLevel: PropTypes.number,
    groupApproved: PropTypes.number,
    updateMemberAccessLevel: PropTypes.func,
    removeMember: PropTypes.func
};

export default GroupMember;