export const LOAD_CONVERSATIONS_START = 'LOAD_CONVERSATIONS_START';
export const LOAD_CONVERSATIONS_SUCCESS = 'LOAD_CONVERSATIONS_SUCCESS';
export const LOAD_CONVERSATION_SUCCESS = 'LOAD_CONVERSATION_SUCCESS';
export const LOAD_CONVERSATION_START = 'LOAD_CONVERSATION_START';
export const SEND_MESSAGE_START = 'SEND_MESSAGE_START';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const MARK_CONVERSATION_UNREAD = 'MARK_CONVERSATION_UNREAD';
export const MARK_CONVERSATION_READ = 'MARK_CONVERSATION_READ';
export const ARCHIVE_CONVERSATION = 'ARCHIVE_CONVERSATION';
export const UNARCHIVE_CONVERSATION = 'UNARCHIVE_CONVERSATION';
export const CLEAR_SINGLE_CONVERSATION = 'CLEAR_SINGLE_CONVERSATION';
export const UPDATE_INBOX_SEARCH = 'UPDATE_INBOX_SEARCH';
export const NEW_CONVERSATION_RECEIVED = 'NEW_CONVERSATION_RECEIVED';
export const NEW_MESSAGE_RECEIVED = 'NEW_MESSAGE_RECEIVED';
export const LOAD_UNREAD_CONVERSATIONS_SUCCESS = 'LOAD_UNREAD_CONVERSATIONS_SUCCESS';
