import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as ideasActions from "../../actions/ideasActions";
import * as modalActions from "../../actions/modalActions";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CardList from "./ideaCards/CardList";
import CustomDragLayer from "./dragAndDrop/CustomDragLayer";
import Subheader from "../common/SubHeader";
import Popup from "../common/Popup";
import { Helmet } from "react-helmet";

class IdeasPage extends Component {
  static contextTypes = {
    router: PropTypes.object,
    showWelcome: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.moveCard = this.moveCard.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadIdeas();
    this.props.actions.loadConstants();
    if (this.props.location.hash === "#new") {
      this.createNew();
      this.context.router.history.push({ ...this.props.location, hash: "" });
    } else {
      this.context.showWelcome("Ideas");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.location.hash !== "#new" &&
      nextProps.location.hash === "#new"
    ) {
      this.createNew();
      this.context.router.history.push({ ...this.props.location, hash: "" });
    }
  }

  searchIdeas(query) {
    this.props.actions.searchIdeas(query);
  }

  moveCard(cardId, lastX, lastY, nextX, nextY) {
    let nextColumn = this.props.ideas[nextX].cards;
    let nextAboveId = nextColumn[nextY - 1] ? nextColumn[nextY - 1].id : null;
    let nextBelowId = nextColumn[nextY] ? nextColumn[nextY].id : null;

    // moving within the same column
    if (lastX === nextX) {
      // card moved down
      if (nextY > lastY) {
        nextAboveId = nextColumn[nextY] ? nextColumn[nextY].id : null;
        nextBelowId = nextColumn[nextY + 1] ? nextColumn[nextY + 1].id : null;
      }

      // card moved up above itself
      if (lastY > nextY && nextBelowId === cardId) {
        nextBelowId = nextColumn[nextY - 1] ? nextColumn[nextY - 1].id : null;
      }
    }
    // if dropped below the last spot in the column
    if (nextAboveId === null && nextY > 1) {
      nextAboveId = nextColumn[nextColumn.length - 1].id;
    }
    this.props.actions.moveCard(
      cardId,
      nextBelowId,
      nextAboveId,
      lastX,
      lastY,
      nextX,
      nextY,
    );
  }

  createNew(stateId) {
    this.props.modalActions.setModal("IDEA", {
      idea: {
        ideaStateId: 1,
      },
      isNew: true,
      stateId,
    });
  }

  render() {
    const ideas = this.props.filteredIdeas.length
      ? this.props.filteredIdeas
      : this.props.ideas;
    if (!ideas.length) {
      return null;
    }
    return (
      <DndProvider backend={HTML5Backend}>
        <div>
          <Helmet>
            <title>Ideas | ZivugTech</title>
          </Helmet>
          <div className="main-container">
            <Subheader
              search={true}
              createNew={this.createNew.bind(this)}
              searchIdeas={this.searchIdeas.bind(this)}
            />
            <div
              id="content-main"
              className="header-padding sub-header-content column-layout-three ideas-page-container"
            >
              <div className="color-key">
                <p>Color Key</p>
                <Popup
                  trigger={<div className="key-square suggested-square"></div>}
                  content="Suggested"
                />
                <Popup
                  trigger={
                    <div className="key-square researching-square"></div>
                  }
                  content="Researching"
                />
                <Popup
                  trigger={<div className="key-square busy-square"></div>}
                  content="Busy"
                />
                <Popup
                  trigger={<div className="key-square said-yes-square"></div>}
                  content="Said Yes"
                />
                <Popup
                  trigger={<div className="key-square said-no-square"></div>}
                  content="Said No"
                />
                <Popup
                  trigger={<div className="key-square waiting-square"></div>}
                  content="Waiting"
                />
              </div>
              {/*<div className="add-idea-btn" onClick={this.createNew.bind(this, 1)}>*/}
              {/*<FontAwesome name='plus'/>*/}
              {/*<span> Add Idea</span>*/}
              {/*</div>*/}
              <div
                className="content ideas-page mui-row"
                style={{ overflow: "auto" }}
              >
                <CustomDragLayer snapToGrid={true} />
                <div className="scrollable-container">
                  {ideas.map((section, i) => (
                    <CardList
                      moveCard={this.moveCard}
                      x={i}
                      key={section.name}
                      name={section.name}
                      cards={section.cards}
                      createNew={this.createNew.bind(this, section.id)}
                      openModal={this.props.modalActions.setModal}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DndProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    filteredIdeas: state.ideas.filteredIdeas,
    ideas: state.ideas.ideas,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ideasActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IdeasPage);
