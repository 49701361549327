import * as types from '../actionTypes/groupActionTypes';

export default function singleGroupReducer(state={group: {}, isLoadingGroup: false}, action) {
    switch(action.type) {
        case types.GET_GROUP_START:
            return {
                ...state,
                isLoadingGroup: true
            };
        case types.GET_GROUP_FAIL:
            return {
                group: {},
                isLoadingGroup: false,
                loadGroupError: action.errorMessage
            };
        case types.GET_GROUP_SUCCESS:
            return {
                isLoadingGroup: false,
                group: action.group
            };
        case types.GET_GROUP_RESUMES_SUCCESS:
            return {
                group: { ...state.group, resumes: action.resumes}
            };
        case types.APROVE_GROUPRESUME_SUCCESS:
            return {
                group: {
                    ...state.group,
                    resumes: state.group.resumes.map(resume => {
                        if (resume.id === action.resume.id) {
                            resume.approved = 1;
                        }
                        return resume;
                    })
                }
            };
        case types.ADD_GROUPMYRESUME_SUCCESS:
            return {
                group: {
                    ...state.group,
                    resumes: state.group.resumes ? [action.addedResume, ...state.group.resumes] : null,
                    resumeAdded: {added: true, approved: true, id: action.addedResume.id}
                }
            };
        case types.ADD_GROUPMYRESUME_PENDING:
            return {
                group: {
                    ...state.group,
                    resumes: state.group.resumes ? [action.addedResume, ...state.group.resumes] : null,
                    resumeAdded: {added: true, approved: false, id: action.addedResume.id}
                }
            };
        case types.SHARE_RESUME_TO_GROUP:
            return {
                group: {
                    ...state.group,
                    resumes: state.group.resumes ? [action.addedResume, ...state.group.resumes] : null,
                }
            };
        case types.REMOVE_GROUPRESUME_SUCCESS:
            return {
                group: {
                    ...state.group,
                    resumes: state.group.resumes.filter(resume => resume.id !== action.id),
                    resumeAdded: state.group.resumeAdded && action.id === state.group.resumeAdded.id ?
                        undefined : state.group.resumeAdded
                }
            };
        case types.ADD_GROUPMEMBER_REQUEST_SUCCESS:
            return {
                group: {
                    ...state.group,
                    members: [...state.group.members, action.member],
                    memberRequests: state.group.memberRequests.filter(request => request.id !== action.requestId)
                }
            };
        case types.ADD_GROUPMEMBER_INVITE_SUCCESS:
            return {
                group: {
                    ...state.group,
                    memberInvites: [...state.group.memberInvites, action.invite]
                }
            };

        case types.RESEND_GROUPMEMBER_INVITE_SUCCESS:
            return {
                group: {
                    ...state.group,
                    memberInvites: state.group.memberInvites.map(member => {
                        if(member.id === action.inviteId){
                            member.inviteResent = true;
                        }
                        return member;
                    })
                }
            };
        case types.WITHDRAW_GROUPMEMBER_INVITE_SUCCESS:
            return {
                group: {
                    ...state.group,
                    memberInvites: state.group.memberInvites.filter(member => member.id !== action.inviteId)
                }
            };
        case types.REMOVE_GROUPMEMBER_REQUEST:
            return {
                group: {
                    ...state.group,
                    memberRequests: state.group.memberRequests.filter(member => member.id !== action.requestId)
                }
            };
        case types.REMOVE_GROUPMEMBER_SUCCESS:
            return {
                group: {
                    ...state.group,
                    members: state.group.members.filter(member => member.id !== action.id)
                }
            };
        case types.LEAVE_GROUPMEMBER_SUCCESS:
            let removedMember = state.group.members.find(member => member.id === action.id);
            return {
                group: {
                    ...state.group,
                    members: state.group.members.filter(member => member.id !== action.id),
                    membersCount: state.group.membersCount - 1,
                    ownersCount: removedMember.access.id === 1 ? state.group.ownersCount - 1 : state.group.ownersCount,
                    memberAccessLevel: 5
                }
            };
        case types.UPDATE_GROUPMEMBER_SUCCESS:
            let updatedOwnersCount = state.group.ownersCount;
            let updatedMemberAccessLevel = state.group.memberAccessLevel;
            let updatedMembers = state.group.members.map(member => {
                if(member.id === action.updatedMember.id) {
                    if(member.access.id === 1 && action.updatedMember.access.id !== 1){
                        //if changed access of owner
                        updatedOwnersCount--;
                    }
                    if(member.access.id > 1 && action.updatedMember.access.id === 1){
                        //if changed access of user to owner
                        updatedOwnersCount++;
                    }
                    if(member.isMe){
                        updatedMemberAccessLevel = action.updatedMember.access.id;
                    }
                    member.access = {
                        id: action.updatedMember.access.id,
                        text: action.updatedMember.access.text
                    };
                    return member;
                }

                return member;
            });

            return {
                group: {
                    ...state.group,
                    memberAccessLevel: updatedMemberAccessLevel,
                    ownersCount: updatedOwnersCount,
                    members: updatedMembers
                }
            };
        case types.UPDATE_GROUPMEMBER_FAIL:
            return {
                group: {
                    ...state.group,
                    membersErrorMessage: action.errorMessage
                }
            };
        case types.CLEAR_GROUP_ERROR_MESSAGE:
            return {
                group: {
                    ...state.group,
                    membersErrorMessage: ""
                }
            };
        case types.ADD_GROUPMEMBER_REQUEST_REQUESTS_SUCCESS:
            return {
                group: {
                    ...state.group,
                    memberRequests: [...state.group.memberRequests, action.requestMember],
                    memberRequestPending: true
                }
            };
        case types.FLAG_GROUP:
            return {
                group: { ...state.group, flagged: true}
            };
        case types.UNFLAG_GROUP:
            return {
                group: { ...state.group, flagged: false}
            };
        default:
            return state;
    }
}
