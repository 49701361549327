import React from 'react';
import PropTypes from 'prop-types';
import SingleResume from './singleResume';
import Popup from '../common/Popup';
import { Scrollbars } from 'react-custom-scrollbars';
import FontAwesome from 'react-fontawesome';
import { Helmet } from "react-helmet";
import _ from 'lodash';

export default class ResumeMiddlePanel extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
        objectHeights: PropTypes.object.isRequired
    };

    constructor(props) {
		super(props);
		this.state = {};
	}

    shouldComponentUpdate(nextProps, nextContext){
    	//prevent component from updating when props that are not used have changed
        return !_.isEqual(this.props.resume, nextProps.resume) || this.props.showSidebar !== nextProps.showSidebar || !_.isEqual(this.context.objectHeights, nextContext.objectHeights);
    }

	componentDidUpdate(){
        this.scrollbars.scrollTop(0);
	}

    showIdeaModal = () => {
		let newIdea = {
			ideaStateId: 1
		};
		let resume = this.props.resume;
		if(resume.gender === "Female"){
			newIdea.herResumeId = resume.id;
			newIdea.herName = `${resume.firstName} ${resume.lastName}`;
		} else {
			newIdea.hisResumeId = resume.id;
			newIdea.hisName = `${resume.firstName} ${resume.lastName}`;
		}

        this.props.setModal('IDEA', {
            idea: newIdea,
            newFromResume: true
        });
	};

	shareToUserModal = () => {
        this.props.setModal('SHARE', {
            shareTo: 'user',
            closeOnOutsideClick: true
        });
	};

	shareToGroupModal = () =>  {
        this.props.setModal('SHARE', {
            shareTo: 'group',
            closeOnOutsideClick: true
        });
	};

	flagResumeModal = (resumeId, resumeName) => {
        this.props.setModal('FLAG', {
            flaggedId: resumeId,
            flaggedName: resumeName,
            flaggedType: 'Resume',
            flagAction: this.flagAction.bind(this),
            closeOnOutsideClick: true
        });
	};

	engageResumeModal = (resumeId, resumeName, resumeGender) => {
        this.props.setModal('ENGAGE', {
            resumeId,
            resumeName,
            resumeGender,
            closeOnOutsideClick: true
        });
    };
	
	flagAction(flag, closeModal){
		this.props.flagResume(flag, closeModal);
	}

	render() {
		let {resume} = this.props;
		let resumeName = `${resume.firstName} ${resume.lastName}`;
        let title = `${resumeName} - Resume | ZivugTech`;
		return (
			<div id="content-main" className={`header-padding ${this.props.showSidebar ? '' : 'sidebar-hidden'}`}>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
				<div className="panel-actions panel-actions-fixed resumes-panel-actions-fixed">
					{this.props.displayMobile ?
						<div onClick={this.props.switchMobileTab} className="panel-action all-resumes">
							<FontAwesome name='arrow-left' /><p>All Resumes</p>
						</div>
					: null }
					{resume.published && !resume.engaged ?
						<div className="panel-action-inline">
							<Popup
								trigger={
									<div onClick={()=>this.shareToUserModal()} className="panel-action panel-action-button">
										<FontAwesome name='share' /> <p>Share Resume</p>
									</div>
								}
								content="Share a resume via email"
							/>
							<Popup
								trigger={
									<div onClick={()=>this.shareToGroupModal()} className="panel-action">
										<FontAwesome name='user-plus' /> <p>Add Resume To Group</p>
									</div>
								}
								content="Click here to add this resume to a group"
							/>
						</div>
						: null
					}
					<div className="panel-action">
                        {resume.pinned ?
							<Popup
								trigger={
									<FontAwesome name='star' className="panel-action-pinned-active" onClick={() => this.props.unpinResume(resume.id)}/>
                                }
								content={`Unpin "${resume.firstName} ${resume.lastName}"`}
							/> :
							<Popup
								trigger={
									<FontAwesome name='star-o' onClick={() => this.props.pinResume(resume.id)} />
								}
								content={`Pin "${resume.firstName} ${resume.lastName}"`}
							/>

                        }
					</div>

                    {resume.published && !resume.engaged ?
						resume.reportedEngaged ?
							<div className="panel-action">
								<Popup
									trigger={
										<FontAwesome name='diamond' className="panel-action-engaged-active" />
									}
									content={`"${resume.firstName} ${resume.lastName}" reported as engaged`}
								/>
							</div> :
							<div onClick={()=>this.engageResumeModal(resume.id, resumeName, resume.gender)} className="panel-action">
								<Popup
									trigger={
										<FontAwesome name='diamond' />
									}
									content={`Engage a resume when there's good news to keep ZivugTech up-to-date!`}
								/>
							</div>
                        : null
                    }
					{resume.published && !resume.engaged ?
						<div className="panel-action" onClick={()=>this.showIdeaModal()}>
							<Popup
								trigger={
									<FontAwesome name='lightbulb-o' />
								}
								content={`Create an Idea to add it to your Ideas board`}
							/>
						</div> : null
					}
                    {!resume.isMine ?
						resume.flagged ?
							<div onClick={() => this.props.unflagResumeAlert(resume.id)} className="panel-action">
								<Popup
									trigger={
										<FontAwesome name='flag' className="panel-action-flagged-active" />
									}
									content={`"${resume.firstName} ${resume.lastName}" has been reported`}
								/>
							</div>
							:
							<div onClick={() => this.flagResumeModal(resume.id, resumeName)} className="panel-action">
								<Popup
									trigger={
										<FontAwesome name='flag' />
									}
									content={`If you have an issue with a resume you can report it and send a message explaining why.`}
								/>
							</div>
						: null
                    }

                    {resume.published && !resume.engaged && (resume.canEdit || resume.isMine || resume.canGroupEdit)?
						<div onClick={() => this.context.router.history.push(resume.isMine ? '/myResume' : '/resumes/edit/' + resume.id)} className="panel-action">
							<Popup
								trigger={
									<div><FontAwesome name='pencil' /></div>
								}
								content={resume.isMine ? 'This is your resume - Edit?' : (resume.canEdit ? "You added this resume - Edit?" : "You have admin group rights - Edit?")}
							/>
						</div>
                        : null
                    }

					{!resume.published && !resume.engaged ?
							resume.isMine ?
								<div onClick={() => this.context.router.history.push('/resumes/edit/' + resume.id)} className="panel-action">
									<FontAwesome name='pencil' /><p>Edit and publish to share</p>
								</div>
                                :
								<div className="panel-action">
									<p>This resume has not been published</p>
								</div>
						: null
					}
					<div className="page-count">
                        {this.props.currentResumeIndex === 0 ? null :
							<div onClick={this.props.selectPreviousResume} className="panel-action">
								<FontAwesome name='arrow-left'/>
							</div>
                        }
                        {this.props.currentResumeIndex + 1 ===  this.props.totalResumeCount ? null :
							<div onClick={this.props.selectNextResume} className="panel-action">
								<FontAwesome name='arrow-right'/>
							</div>
                        }
						<div className="panel-action count">
							<p>{(this.props.currentResumeIndex && this.props.currentResumeIndex > 0) || this.props.currentResumeIndex === 0 ? <span>{this.props.currentResumeIndex + 1} / {this.props.totalResumeCount}</span> : null } </p>
						</div>
					</div>



				</div>
				<div className="content-padding-fixed-top" >
					<Scrollbars style={{height: this.context.objectHeights.mainPanelCalculatedHeight }} ref={(el) => (this.scrollbars = el)}>
						{ resume && !this.props.isLoading? <SingleResume resume={resume} /> : null }
						{this.props.isLoading ? <div className="loader"></div>: null}
					</Scrollbars>
				</div>
			</div>
		);
	}
}