import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import * as userActions from '../../../actions/userActions';
import { Link } from 'react-router-dom';
import yup from 'yup';
import Form from 'react-formal';
import SelectList from 'react-widgets/lib/SelectList';
import LocationInput from '../../common/locationInput';
import { Helmet } from "react-helmet";
import FontAwesome from 'react-fontawesome';


const registerSchema = yup.object().shape({
	firstName: yup.string().required('Required'),
	lastName: yup.string().required('Required'),
	gender: yup.mixed().required('Required'),
	phone: yup.string().required('Required').min(8,'Invalid phone number'),
	location: yup.string(),
	hearAbout: yup.string(),
	signupReason: yup.string(),
	email: yup.string().email('Invalid email').required('Required')
		.test('userExists', 'User with this email already exists.', function (email){
			return axios.get('/api/users/checkUserExists', {params: {email}})
				.then(response => !response.data)
		}),
	password: yup.string().required('Required').min(6, 'Password is too short'),
	confirmPassword: yup.mixed().test('match', 'Passwords do not match', function (confirmPassword) {
		return confirmPassword === this.parent.password
	})
});


class RegisterPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			newUser: {
				firstName: '',
				lastName: '',
				gender: null,
				email: '',
				password: '',
				phone: '',
				location: '',
				confirmPassword:''
			},
			submitted: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.submit = this.submit.bind(this);
	}
	
	componentWillUnmount(){
		this.props.actions.clearRegisterPage();
	}

	handleChange(model) {
		this.setState({newUser: model, submitted: false});
	}

	submit(values) {
		this.setState({submitted: true});
        delete values.confirmPassword;
        this.props.actions.registerUser(values);
	}

	resendConfirmationEmail(){
		this.setState({resentConfirmationEmail: true});
		this.props.actions.resendConfirmationEmail(this.props.user.unconfirmedUserId);
	}

	render() {
		let {submitting, errorMessage, isRegistered, confirmationResent} = this.props.user;

		if(isRegistered) {
			return (
				<div className="page-account page-login">
					<div  className="page-account-container-large">
						<Link to={'/login'}>
							<img src="/images/logo.png" alt="" className="page-account-logo"/>
						</Link>

						<div id="form" className="page-account-content mui--z1">
							<h3>Welcome to ZivugTech</h3>
							<p>We sent an email to {this.state.newUser.email}</p>
							<p>Please click the 'confirm' link in that email to continue.</p>
							<p className="margin-bottom">If you've already confirmed your email, <Link to={`login`}>click here</Link> to login</p>

							<span onClick={this.resendConfirmationEmail.bind(this)} className="btn btn-flat btn-cancel btn-confirmation">
                              Resend the email
							</span>

							{this.state.resentConfirmationEmail && confirmationResent &&
							<p className="form-message-bottom text-align-center">Confirmation resent. Please check your email</p>}
						</div>
					</div>
				</div>
			)
		}

		return (
            <div className="page-account page-login">
				<Helmet>
					<title>Register | ZivugTech</title>
				</Helmet>
				<div className="page-account-container-large">
					<Link to={'/login'}>
						<img src="/images/logo.png" alt="" className="page-account-logo"/>
					</Link>

					<Form onSubmit={this.submit}
							  id="form"
							  schema={registerSchema}
							  value={this.state.newUser}
							  onChange={this.handleChange}
							  className="page-account-content mui--z1">

							<h3>Register Account</h3>
							<div className="container">
								<div className="form-inline-half">
									<legend>First Name
										<Form.Message className='has-error' for='firstName'/>
									</legend>
									<Form.Field
										placeholder=""
										name='firstName'
										className="form-input-margin-bottom"/>
								</div>
								<div className="form-inline-half">
									<legend>Last Name
										<Form.Message className='has-error' for='lastName'/>
									</legend>
									<Form.Field
										placeholder=""
										name='lastName'
										className="form-input-margin-bottom"/>
								</div>
							</div>

							<div className="container">
								<div className="form-inline-half phone">
									<legend>Mobile Phone
										<Form.Message className='has-error' for='phone'/>
									</legend>
									<Form.Field
										name='phone'
										className="form-input-margin-bottom"/>
								</div>
								<div className="form-inline-half register-radios">
									<legend>Gender
										<Form.Message className='has-error' for='gender'/>
									</legend>
									<Form.Field
										type={SelectList}
										data={[{ id: 1, text: "Male"}, { id: 2, text: "Female"}]}
										mapFromValue={ gender => gender.id }
										valueField='id'
										textField='text'
										name='gender'
										className="form-input-margin-bottom form-inline inline-radio"/>
								</div>
							</div>
							<div className="container form-input-margin-bottom clear">
								<legend>Location
									<Form.Message className='has-error' for='location'/>
								</legend>
								<Form.Field
									name='location'
									placeholder="City, State"
									className="form-input-margin-bottom"
									type={LocationInput}
								/>
							</div>
							<div className="container">
								<legend>Email
									<Form.Message className='has-error' for='email'/>
								</legend>
								<Form.Field
									placeholder="Email"
									name='email'
									className="form-input-margin-bottom"/>

								<div className="form-inline-half">
									<legend>Password
										<Form.Message className='has-error' for='password'/>
									</legend>
									<Form.Field
										placeholder="Password"
										type='password'
										alsoValidates={this.state.newUser.confirmPassword ? ['confirmPassword']: []}
										name='password'
										className="form-input-margin-bottom"/>
								</div>
								<div className="form-inline-half">
									<legend>Confirm password
										<Form.Message className='has-error' for='confirmPassword'/>
									</legend>
									<Form.Field
										placeholder="Confirm Password"
										type='password'
										name='confirmPassword'
										className="form-input-margin-bottom"/>
								</div>
							</div>
							<div className="container">
								<div className="form-inline-half">
									<legend>How did you hear about us?</legend>
									<Form.Field
										placeholder=""
										name='hearAbout'
										className="form-input-margin-bottom"/>
								</div>
								<div className="form-inline-half">
									<legend>Why are you creating an account?</legend>
									<Form.Field name='signupReason' className="" type="select">
										<option value=''>Select one</option>
										<option value='myself'>To make a resume for myself</option>
										<option value='others'>To help set up others</option>
										<option value='both'>For myself and to help others</option>
									</Form.Field>

								</div>
							</div>

							{errorMessage && this.state.submitted && <span className='has-error'>{errorMessage}</span>}

							{(this.state.submitted && !errorMessage) || submitting ?
								<div className="btn-flat btn-primaryAccent text-align-center btn-loader">
									<div className="loader-small"></div>
								</div>
								:
								<Form.Button type="submit"
											 className="btn-flat btn-primaryAccent btn-loader text-align-center">
									Register Now. Free Forever.
								</Form.Button>
							}

							<p className="form-message-bottom-terms text-align-center">By clicking "Register" I
								agree to ZivugTech's <Link to={`terms`}>Terms and Conditions.</Link></p>

						</Form>
						<p className='page-account-text-link page-account-text-link-light text-align-center'>
							<Link to={`login`}>Already have an account? Login</Link>
						</p>

						<div className="powered-by">
							<Link to={'http://bitbean.com'} target="_blank">
								 <span>Built with <FontAwesome name='heart' /> by </span> bitbean.com
							</Link>
						</div>
	            </div>
	        </div>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(userActions, dispatch)
	};
}

function mapStateToProps(state) {
	return {
		constants: state.constants,
		user: state.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage)


