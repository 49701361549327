import React from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';
import FontAwesome from 'react-fontawesome';
import { apiAuthRequest } from '../../../actions/helpers';
import PrivateImage from '../../common/privateImage';


function renderSuggestion(value) {
    let resume = value.item;
    return (
        <div key={resume.id} className="autosuggest-list-item">
            <PrivateImage
                fileName={resume.images.length ? resume.images[0].fileLocation : ''}
                id={resume.id}
                section='resumeSmall'
                className='autosuggest-list-item-image'
            />
            <div className="autosuggest-list-item-info autosuggest-column-large">
                <h5 className="autosuggest-list-item-title">{resume.firstName} {resume.lastName}</h5>
                <p className="autosuggest-list-item-info-details">
                    <span>{resume.age}</span>
                    <span>{resume.height}</span>
                    <span>{resume.maritalStatus}</span>
                </p>
                <p className="autosuggest-list-item-info-types">
                    {resume.types ? resume.types.map((t, index) => this.resumeTypeRow(t, resume.types.length, index)) : null}
                </p>
                {resume.addedToGroup ?
                    <div className="resume-status">
                        <p className="has-resume"><FontAwesome name="check" />Added</p>
                    </div> : null
                }
                {resume.pending ?
                    <div className="resume-status">
                        <p className="has-resume"><FontAwesome name="circle" />Pending</p>
                    </div> : null
                }
            </div>
        </div>
    )
}

function resumeTypeRow(type, length, index){
    let typeText;
    if(index < length - 1) {
        typeText = type.type.text + ", ";
    } else {
        typeText = type.type.text;
    }
    return (
        <span key={type.id}>{typeText}</span>
    )
}


export default class ResumeDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value || '',
            suggestions: [],
            isLoading: false
        };
        this.name = this.props.name;
    }

    loadSuggestions(value) {
        this.setState({
            isLoading: true
        });

        let params = {query: value, groupId: this.props.groupId};
        let inside = this;
        apiAuthRequest('get', '/resumes/autocomplete', params).then(function (response) {
            if (response.success) {
                inside.setState({
                    isLoading: false,
                    suggestions: response.data
                })
            }
        });
    }

    componentDidMount(){
        this.loadSuggestions('');
    }

    onChange(value){
        this.props.onSelect(value);
        this.setState({ value: value.name });
    };

    render() {
        const { suggestions, isLoading, placeholder } = this.state;

        return (
            <div ref='autocomplete' className="container autosuggest-dropdown">
                <DropdownList
                    placeholder={placeholder}
                    busy={isLoading}
                    data={suggestions}
                    textField={item => item && item.id ? `${item.firstName} ${item.lastName}` : ''}
                    valueField='id'
                    onSearch={this.loadSuggestions.bind(this)}
                    onChange={this.onChange.bind(this)}
                    itemComponent={renderSuggestion}
                    filter={t=>t}
                />
            </div>
        );
    }
}
