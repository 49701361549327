import * as types from '../actionTypes/groupActionTypes';
import { apiAuthRequest } from './helpers';
import { toastr } from 'react-redux-toastr';


export function loadSingleGroup(id) {
    return async dispatch => {
        dispatch({ type: types.GET_GROUP_START });
        let result = await apiAuthRequest('get', '/groups/getGroupById', {id});
        if(result.success){
            dispatch({
                type: types.GET_GROUP_SUCCESS,
                group: result.data
            });
        } else {
            dispatch({
                type: types.GET_GROUP_FAIL,
                errorMessage: result.data
            });
            toastr.error('Error loading group');
        }
    }
}

export function loadGroups(params) {
    return async dispatch => {
        dispatch({ type: types.LOAD_GROUPS_START, search: params });

        let result = await apiAuthRequest('get', '/groups/getAllGroups', params);
        if(result.success){
            dispatch({
                type: types.LOAD_GROUPS_SUCCESS,
                groups: result.data
            })
        } else {
            dispatch({
                type: types.LOAD_GROUPS_FAIL,
                errorMessage: result.data
            });
            toastr.error('Error loading groups');
        }
    }
}

export function flagGroup(flag, closeModal) {
    return async dispatch => {
        let result = await apiAuthRequest('post', '/groups/flagGroup', {
                flaggedGroupId: flag.flaggedId,
                reason: flag.message
            }
        );
        if (result.success) {
            dispatch({
                type: types.FLAG_GROUP,
                groupId: flag.flaggedId
            });
        } else {
            toastr.error('Error flagging group');
        }
        setTimeout(closeModal, 3000);

    }
}

export function unflagGroup(groupId) {
    return async dispatch => {
        let result = await apiAuthRequest('post', '/groups/unflagGroup', {flaggedGroupId: groupId});
        if(result.success){
            dispatch({
                type: types.UNFLAG_GROUP,
                groupId
            });
        } else {
            toastr.error('Error unflagging group');
        }
    }
}