import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../../actions/userActions';
import * as modalActions from '../../../actions/modalActions';
import Subheader from '../../common/SubHeader';
import axios from 'axios';
import FontAwesome from 'react-fontawesome';
import yup from 'yup';
import Form from 'react-formal';
import SelectList from 'react-widgets/lib/SelectList';
import ReactTooltip from 'react-tooltip';
import { Helmet } from "react-helmet";
import PrivateImage from "../../common/privateImage";
import LocationInput from '../../common/locationInput';


const accountSettingsSchema = yup.object().shape({
    id: yup.mixed(),
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    gender: yup.mixed().required('Required'),
    phone: yup.string().required('Required').min(10,'Invalid phone number'),
    location: yup.string()
});

const securitySettingsSchema = yup.object().shape({
    oldPassword: yup.string().required('Required'),
    newPassword: yup.string().required('Required').min(6, 'Password is too short'),
    confirmNewPassword: yup.mixed().test('match', "Passwords don't match", function (confirmPassword) {
        return confirmPassword === this.parent.newPassword
    })
});

const emailSettingsSchema = yup.object().shape({
    password: yup.string().required('Required'),
    email: yup.string().email('Invalid email').required('Required')
        .test('emailInUse', 'Someone else has an account with this email.', function (email){
            return axios.get('/api/users/checkUserExists', {params: {email}})
                .then(response => !response.data)
        }),
});

class AccountSettings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            account: {
                id: props.user.id,
                firstName: props.user.firstName || "",
                lastName: props.user.lastName || "",
                phone: props.user.phone || "",
                gender: props.user.gender || "",
                location: props.user.location || ""
            },
            security: {},
            addEmail: {}
        };

        this.updateAccount = this.updateAccount.bind(this);
        this.updateSecurity = this.updateSecurity.bind(this);
        this.addEmail = this.addEmail.bind(this);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.settings.accountSettingsSaved || nextProps.settings.accountSettingsError){
            this.setState({savingAccountSettings: false});
        }
        if(nextProps.settings.securitySettingsSaved || nextProps.settings.securitySettingsError){
            let security = this.state.security;
            if(nextProps.settings.securitySettingsSaved){
                security={};
            }
            this.setState({savingSecuritySettings: false, security});
        }
        if(nextProps.settings.emailAddedSuccessfully || nextProps.settings.errorAddingEmail){
            let addEmail = this.state.addEmail;
            if(nextProps.settings.emailAddedSuccessfully){
                addEmail={};
            }
            this.setState({savingEmailSettings: false, addEmail});
        }
        this.updateUser(nextProps.user);
    }

    updateUser(user){
        let account = {
            id: user.id,
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            phone: user.phone || "",
            gender: user.gender || "",
            location: user.location || ""
        };
        this.setState({account});
    }

    updateAccount(values) {
        this.setState({savingAccountSettings: true, submitted: true}, ()=>{
            this.props.actions.updateUser(values);
        });
    }

    updateSecurity(values) {
        this.setState({savingSecuritySettings: true, submitted: true}, ()=>{
            this.props.actions.updatePassword(values);
        });
    }

    addEmail(values) {
        this.setState({savingEmailSettings: true, submitted: true}, ()=>{
            this.props.actions.addEmail(values);
        });
    }

    verifyUser(){
        this.props.modalActions.setModal('VERIFY_USER', {
            closeOnOutsideClick: true
        });
    }

    render() {
        let {
            accountSettingsSaved,
            accountSettingsError,
            securitySettingsSaved,
            securitySettingsError,
            emailAddedSuccessfully,
            errorAddingEmail,
            emailVerificationSent,
            emailVerificationFail,
            errorMessage
        } = this.props.settings;
        let submitted = this.state.submitted;
        return (
            <div>
                <Helmet>
                    <title>Account Settings</title>
                </Helmet>
                <Subheader title="Account Settings" />
                <div className="mui-container-fluid">
                    <div className="content header-padding header-padding-subheader" id="form">
                        <div className="mui-col-md-10">
                            <Form onSubmit={this.updateAccount}
                                  schema={accountSettingsSchema}
                                  value={this.state.account}
                                  onChange={(account)=>this.setState({account, submitted: false})}>
                                <div className="account-settings-panel mui-panel">
                                    <div className="form-heading primary-color">
                                        <h3 className="organization-info">Personal Info</h3>
                                    </div>
                                    <div className="mui-row">
                                        <div className="form-inline-half">
                                            <legend>First Name * <Form.Message className='has-error' for='firstName'/></legend>
                                            <Form.Field name='firstName'/>
                                        </div>

                                        <div className="form-inline-half">
                                            <legend>Last Name * <Form.Message className='has-error' for='lastName'/></legend>
                                            <Form.Field name='lastName'/>
                                        </div>
                                    </div>
                                    <div className="mui-row">
                                        <legend>Verify
                                            <span>
                                                <FontAwesome name='info-circle' data-multiline={true} data-tip={"Drivers license or other valid form of ID. <br/> Verification is necessary for creating groups"} />
                                            </span>
                                        </legend>

                                        {this.props.user.approved ?
                                            <span>Your account has been verified.</span>
                                            :
                                            <div>
                                                {this.props.user.verificationImage && this.props.user.verificationImage.id ?
                                                    <div className="mui-row">
                                                        <div className="mui-col-md-5">
                                                            <span>Verification pending</span>
                                                            <span className="helper-text helper-text-break">(Please allow around 72 hours for processing)</span>

                                                            <PrivateImage
                                                                fileName={this.props.user.verificationImage.imageLocation}
                                                                id={this.props.user.id}
                                                                section='verification'
                                                                className='verification-image'
                                                                height="100" width="100"
                                                            />
                                                            <button onClick={()=>this.verifyUser()} className="btn-flat btn-accentPrimary btn-confirmation">
                                                                Change Image
                                                            </button>
                                                        </div>
                                                        <div className="mui-col-md-2">

                                                        </div>
                                                    </div>
                                                    :
                                                    <div onClick={()=>this.verifyUser()} className="btn-flat btn-accentPrimary btn-confirmation">
                                                        Upload verification image
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="mui-row">
                                        <div className="form-inline-half mui-col-md-6">
                                            <legend>Phone Number * <Form.Message className='has-error' for='phone'/></legend>
                                            <Form.Field name='phone'/>
                                        </div>

                                        <div className="form-inline-half mui-col-md-6">
                                            <legend>Gender * <Form.Message className='has-error' for='gender'/></legend>
                                            <Form.Field
                                                type={SelectList}
                                                data={this.props.constants.gender}
                                                mapFromValue={ gender => gender.id }
                                                valueField='id'
                                                textField='text'
                                                name='gender'
                                                className="form-input-margin-bottom form-inline inline-radio"/>
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-md-12">
                                            <legend>Location <Form.Message className='has-error' for='location'/></legend>
                                            <Form.Field
                                                name='location'
                                                placeholder="City, State"
                                                className="form-input-margin-bottom"
                                                type={LocationInput}
                                            />
                                        </div>
                                    </div>
                                    <div className="footer-singleForm">
                                        {this.state.savingAccountSettings ?
                                            <div className="btn-flat btn-primaryAccent">
                                                <div className="loader-small"></div>
                                            </div>
                                            :
                                            <Form.Button type="submit" className="btn-flat btn-primaryAccent">
                                                Update Personal Info
                                            </Form.Button>
                                        }
                                        {submitted && accountSettingsSaved && <p className="settings-message">Settings saved successfully</p>}
                                        {submitted && accountSettingsError && <p className="settings-message">{errorMessage}</p>}
                                    </div>
                                </div>
                            </Form>

                            <Form onSubmit={this.updateSecurity}
                                  schema={securitySettingsSchema}
                                  value={this.state.security}
                                  onChange={(security)=>this.setState({security, submitted: false})}>
                                <div className="account-settings-panel mui-panel">
                                    <div className="form-heading primary-color">
                                        <h3 className="organization-info" ref="personalInfo">Sign-in & Security</h3>
                                    </div>
                                    <div className="mui-row">
                                        <div className="form-inline-third">
                                            <legend>Current Password * <Form.Message className="has-error" for="oldPassword"/></legend>
                                            <Form.Field name="oldPassword" type="password" />
                                        </div>
                                        <div className="form-inline-third">
                                            <legend>New Password * <Form.Message className="has-error" for="newPassword"/></legend>
                                            <Form.Field 
                                                name="newPassword"
                                                alsoValidates={this.state.security.confirmPassword ? ['confirmPassword']: []}
                                                type="password" />
                                        </div>

                                        <div className="form-inline-third">
                                            <legend>Confirm Password * <Form.Message className="has-error" for="confirmNewPassword"/></legend>
                                            <Form.Field name="confirmNewPassword" type="password" />
                                        </div>
                                    </div>

                                    <div className="footer-singleForm">
                                        {this.state.savingSecuritySettings ?
                                            <div className="btn-flat btn-primaryAccent">
                                                <div className="loader-small"></div>
                                            </div>
                                            :
                                            <Form.Button type="submit" className="btn-flat btn-primaryAccent">
                                                Save New Password
                                            </Form.Button>
                                        }
                                        {submitted && securitySettingsSaved && <p className="settings-message">Password Updated</p>}
                                        {submitted && securitySettingsError && <p className="settings-message">{errorMessage}</p>}
                                    </div>
                                </div>
                            </Form>

                            <div className="account-settings-panel mui-panel">
                                <div className="form-heading primary-color">
                                    <h3 className="organization-info">Emails</h3>
                                </div>
                                <div className="mui-row">
                                    <div>
                                        <legend>Primary Email</legend>
                                        <input disabled value={this.props.user.email} type="text"/>
                                    </div>
                                </div>
                                <div className="form mui-row">
                                    <legend>Alternative Emails</legend>

                                    {this.props.user.emails ? this.props.user.emails.map(e=>{
                                        return (
                                            <div key={e.id} className="account-settings-fields">
                                                <div className="form-inline-half">
                                                    <input disabled value={e.email} type="text"/>
                                                </div>
                                                <div className="form-inline-half">
                                                    <p className="account-settings-action btn-account-settings-action" onClick={this.props.actions.removeEmail.bind(this, e.id)}>
                                                        <FontAwesome name='trash' /> Remove
                                                    </p>
                                                    { !e.confirmed ?
                                                        <p className="account-settings-action btn-account-settings-action"
                                                           onClick={this.props.actions.resendEmailVerification.bind(this, e.id)}>
                                                            <FontAwesome name='share' /> Resend Email Verification
                                                        </p>
                                                        :
                                                        <p className="account-settings-action"><FontAwesome name='check'/> Confirmed</p>
                                                    }
                                                    {e.confirmationResent ?
                                                        <p className="account-settings-action"><FontAwesome name='check'/> Sent</p>
                                                    : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                                <Form onSubmit={this.addEmail}
                                      schema={emailSettingsSchema}
                                      value={this.state.addEmail}
                                      onChange={(addEmail)=>this.setState({addEmail, submitted: false})} >
                                    <div className="mui-row">
                                        <div className="form-inline-half">
                                            <legend>New Email *<Form.Message className='has-error' for='email'/></legend>
                                            <Form.Field name='email' autoComplete="off"/>
                                        </div>
                                        <div className="form-inline-half">
                                            <legend>Current Password *<Form.Message className='has-error' for='password'/></legend>
                                            <Form.Field name='password' type="password" autoComplete="new-password"/>
                                            <span className="form-input-extra-text">You will need to enter your current account password to add an email.</span>
                                        </div>
                                    </div>
                                    <div className="footer-singleForm">
                                        {this.state.savingEmailSettings ?
                                            <div className="btn-flat btn-primaryAccent">
                                                <div className="loader-small"></div>
                                            </div>
                                            :
                                            <Form.Button type="submit" className="btn-flat btn-primaryAccent">
                                                Add New Email
                                            </Form.Button>
                                        }
                                        {submitted && emailAddedSuccessfully && <p className="settings-message">Email added successfully</p>}
                                        {submitted && errorAddingEmail && <p className="settings-message">{errorMessage}</p>}
                                        {emailVerificationSent && <p className="settings-message">Email verification sent</p>}
                                        {emailVerificationFail && <p className="settings-message">{errorMessage}</p>}
                                    </div>
                                </Form>
                            </div>

                        </div>
                    </div>
                </div>
                <ReactTooltip effect="solid"/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        constants: state.constants,
        user: state.user.user,
        settings: state.settings
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions,  dispatch),
        modalActions: bindActionCreators(modalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings)