import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FontAwesome from 'react-fontawesome';
import Card from '../../ideas/ideaCards/Card';
import * as modalActions from '../../../actions/modalActions';
import * as ideasActions from '../../../actions/ideasActions';
import { Link } from 'react-router-dom';

class SidebarIdeas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.actions.loadConstants();
    }

    showIdeaModal(idea, newFromResume) {
        this.props.modalActions.setModal('IDEA', {
            idea: idea,
            newFromResume,
            closeOnOutsideClick: true
        });
    }

    createNewIdea(){
        let newIdea = { ideaStateId: 1 };
        let resume = this.props.resume;
        if(resume.gender === "Female"){
            newIdea.herResumeId = resume.id;
            newIdea.herName = `${resume.firstName} ${resume.lastName}`;
        } else {
            newIdea.hisResumeId = resume.id;
            newIdea.hisName = `${resume.firstName} ${resume.lastName}`;
        }
        this.showIdeaModal(newIdea, true);
    }


    render(){
        let {resume} = this.props;
        
        return (
            <div className="sidebar-ideas">
                {!resume.ideas.length ?
                    <div className="message-sidebar-empty">
                        <FontAwesome name='lightbulb-o' className="message-sidebar-empty-icon" />
                        <p>You have not created any ideas for
                            <span>{resume.firstName} {resume.lastName}</span>
                        </p>
                        {resume.engaged ? null :
                            <p className="btn-flat btn-sidebar" onClick={()=>this.createNewIdea()}>
                                Create an idea now
                            </p>
                        }
                    </div>
                    :
                    <div className="container">
                        {resume.engaged ? null :
                            <p className="btn-sidebar-create" onClick={()=>this.createNewIdea()}>
                                <FontAwesome name='lightbulb-o' className="btn-icon-left"/>
                                Create another idea
                            </p>
                        }
                        <ul className="sidebar-content-list">
                            {resume.ideas.map((idea)=> {
                                    return (
                                        <div key={idea.id} onClick={()=>this.showIdeaModal(idea, false)}>
                                            <Card item={idea}/>
                                        </div>
                                    )
                                }
                            )}
                        </ul>
                    </div>
                }
                {resume.engaged ? null :
                    <Link key="IdeasPage" to={`/ideas`} className="btn-text">
                        Go to Ideas page
                    </Link>
                }
            </div>
        )

    }
}


function mapDispatchToProps(dispatch) {
    return {
        actions : bindActionCreators(ideasActions, dispatch),
        modalActions: bindActionCreators(modalActions, dispatch)
    };
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarIdeas)
