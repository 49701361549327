import React, { Component } from "react";
import { Link } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import Subheader from "../../common/SubHeader";

class AddResume extends Component {
  render() {
    return (
      <div className="container">
        <Subheader title="Create / Add Resume" />
        <div className="sub-header-content mui-container">
          <div className="mui-row list-items-container">
            <div className="options-box-container mui-col-md-4">
              <div className="option-box">
                <FontAwesome name="file" />
                <div className="welcome-feature-name">
                  <p className="feature-title">
                    Create
                    <br /> Resume
                  </p>
                  <Link
                    to={`/resumes/create/resumeNew`}
                    name="createResume"
                    className="btn-flat btn-secondary btn-center btn-link"
                  >
                    Add
                  </Link>
                </div>
              </div>
            </div>
            <div className="options-box-container mui-col-md-4">
              <div className="option-box">
                <FontAwesome name="upload" />
                <div className="welcome-feature-name">
                  <p className="feature-title">
                    Bulk
                    <br /> Upload
                  </p>
                  <Link
                    to={``}
                    name="createResume"
                    className="btn-flat btn-secondary btn-center btn-link btn-disabled"
                  >
                    Coming Soon
                  </Link>
                </div>
              </div>
            </div>
            <div className="options-box-container mui-col-md-4">
              <div className="option-box">
                <FontAwesome name="file-text" />
                <div className="welcome-feature-name">
                  <p className="feature-title">Upload Resume from PDF</p>
                  <Link
                    to={`/resumes/create/uploadPdf`}
                    name="createResume"
                    className="btn-flat btn-secondary btn-center btn-link"
                  >
                    Upload
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddResume;
